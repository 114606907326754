export const content = {
  title:'Device settings',
  nodata:'No data',
  error:'No data',
  button:'Save',
  cancelButton:'Cancel',
  form:{
    period:{
      label:'Period (sec)',
      placeholder:'Type a period',
    },
    temperature_delta:{
      label:'Temperature Delta',
      placeholder:'Type a delta',
    },
    humidity_delta:{
      label:'Humidity Delta',
      placeholder:'Type a delta',
    },
    max_silenced_reports:{
      label:'Max silenced reports',
      placeholder:'Type a number',
    },
  },
}