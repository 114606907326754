import { grey } from "@material-ui/core/colors";
import { fade } from "@material-ui/core/styles";

const styles = (theme) => ({
  root:{
    padding:'32px',
  },
  container:{
    width:100,
    height:100,
    background:`radial-gradient(at center, ${fade(grey[200],0.5)}, ${fade(grey[700],0.5)})`,
    borderRadius:'50%',
    position:'relative'
  },
  left:{
    color:'white',
    position:'absolute',
    zIndex:3,
    top:29,
    left:-4,
    '&:hover':{
      cursor:'pointer'
    }
  },
  right:{
    color:'white',
    position:'absolute',
    zIndex:3,
    top:29,
    right:-4,
    '&:hover':{
      cursor:'pointer'
    }
  },
  up:{
    color:'white',
    position:'absolute',
    zIndex:3,
    top:-4,
    right:29,
    '&:hover':{
      cursor:'pointer'
    }
  },
  down:{
    color:'white',
    position:'absolute',
    zIndex:3,
    bottom:-4,
    right:29,
    '&:hover':{
      cursor:'pointer'
    }
  },
  icon:{
    fontSize:42
  }

})

export default styles