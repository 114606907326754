import React, {Component} from 'react'
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper';
import { withStyles } from '@material-ui/core';
import Hls from 'hls.js'
import { grey } from '@material-ui/core/colors';


class CameraVideoCard extends Component {
  render(){

    const {classes, url} = this.props

    return(
      <CardWrapper>
        <div className={classes.root}>
          <div className={classes.inner}>
            <video className={classes.video} ref={(player)=> {this.player = player}} autoPlay={true}></video>
          </div>
        </div>
      </CardWrapper>
    )
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {url} = this.props
    try {
      if(Hls.isSupported() && this.player) {
        const video = this.player
        const hls = new Hls();
        this.hlsPlayer = hls
        hls.loadSource(url ? url.url : null);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED,function() {
          video.controls = true;
          video.play();
        });
      }
    } catch (error) {
      console.log('Hubo un error!!!!')
    }
  }

  componentWillUnmount(){
    this.hlsPlayer.destroy()
  }

}

const styles = theme => ({
  root:{
    background:'transparent'
  },
  video:{
    width:'100%',
    background:grey[300],
    borderRadius:8,
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    [theme.breakpoints.down('sm')]: {
      minWidth:'300px',
    },
  }
})

export default withStyles(styles)(CameraVideoCard)