export const contentData = {
  spanish:{
    layout:{
      title:'Device details',
      navigation:[
        {name:'Zones',path:'/zones'},
        {name:'Details',path:'/zones/:id'},
        {name:'Device',path:'/zones/:id/details/:deviceid'}
      ]
    },
  }
}
