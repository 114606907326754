export const catalogs={
  spanish:{
    device_types:[{"id":1,"value":1,"label":"Temperature/Humidity"},{"id":2,"value":2,"label":"Open/Close"},{"id":3,"value":3,"label":"Presence"},{"id":4,"value":4,"label":"Controller"}, {"id":5,"value":5,"label":"Camera"}],
    user_statuses:[{"id":0,"value":0,"label":"Inactivo"},{"id":1,"value":1,"label":"Activo"}],
    user_types:[{"id":1,"value":1,"label":"Administrador"},{"id":2,"value":2,"label":"Asesor Roche"},{"id":3,"value":3,"label":"Asesor Mercurio"},{"id":4,"value":4,"label":"Asesor Pra HS"},],
    user_genders:[{"id":1,"value":1,"label":"Hombre"},{"id":2,"value":2,"label":"Mujer"},{"id":3,"value":3,"label":"Otro"},],
    patient_statuses:[{"id":1,"value":1,"label":"En proceso"},{"id":2,"value":2,"label":"Activo"},{"id":3,"value":3,"label":"Inactivo"},],
    approval_statuses:[{"id":1,"value":1,"label":"No empezado"},{"id":2,"value":2,"label":"Pendiente de aprobación"},{"id":3,"value":3,"label":"Aprovado"},{"id":4,"value":4,"label":"Rechazado"},{"id":5,"value":5,"label":"Cancelado"},],
    payment_types:[{"id":1,"value":1,"label":"Aseguradora"},{"id":2,"value":2,"label":"Bolsillo"},{"id":3,"value":3,"label":"Mixto"},],
    letter_statuses:[{"id":1,"value":1,"label":"No generada"},{"id":2,"value":2,"label":"Pendiente de envío"},{"id":3,"value":3,"label":"Enviada"},],
    hospital_values:[{"id":1,"value":1,"label":"Muy alto"},{"id":2,"value":2,"label":"Alto"},{"id":3,"value":3,"label":"Medio-Alto"},{"id":4,"value":4,"label":"Medio"},{"id":5,"value":5,"label":"Medio-Bajo"},{"id":6,"value":6,"label":"Bajo"},],
    policy_amounts:[{"id":1,"value":1,"label":"> 3M"},{"id":2,"value":2,"label":"2.5M - 3M"},{"id":3,"value":3,"label":"2M - 2.5M"},{"id":4,"value":4,"label":"1.5M - 2M"},{"id":5,"value":5,"label":"1M - 1.5M"},{"id":6,"value":6,"label":"500K - 1M"},{"id":7,"value":7,"label":"0 - 500K"},],
    policy_values:[{"id":1,"value":1,"label":"Muy alto"},{"id":2,"value":2,"label":"Alto"},{"id":3,"value":3,"label":"Medio-Alto"},{"id":4,"value":4,"label":"Medio"},{"id":5,"value":5,"label":"Medio-Bajo"},{"id":6,"value":6,"label":"Bajo"},],
    benefits:[{"id":1,"value":1,"label":"10+1"},{"id":2,"value":2,"label":"7+1"},{"id":3,"value":3,"label":"6+1"},{"id":4,"value":4,"label":"5+1"},{"id":5,"value":5,"label":"4+1"},{"id":6,"value":6,"label":"3+1"}],
    nses:[{"id":1,"value":1,"label":"Muy Alto"},{"id":2,"value":2,"label":"Alto"},{"id":3,"value":3,"label":"Medio-Alto"},{"id":4,"value":4,"label":"Medio"},{"id":5,"value":5,"label":"Medio-Bajo"},{"id":6,"value":6,"label":"Bajo"},],
    inactive_reasons:[{"id":1,"value":1,"label":"Sin contacto "},{"id":2,"value":2,"label":"No acreditado "},{"id":3,"value":3,"label":"SGMM cubre tratamiento "},{"id":4,"value":4,"label":"No acepta beneficio "},{"id":5,"value":5,"label":"No interesado "},{"id":6,"value":6,"label":"Cuestiones económicas "},{"id":7,"value":7,"label":"Distribuidor no autorizado "},{"id":8,"value":8,"label":"Off label "},{"id":9,"value":9,"label":"No desea continuar con beneficio "},{"id":10,"value":10,"label":"Fallecimiento "},],
  },
  english:{
  },
}
  