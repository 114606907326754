import { Grid } from '@material-ui/core';
import React from 'react';
import DisplayText from '../Texts/DisplayText';
import RoundedButton from './RoundedButton';

const ActionModalBar = ({btnLabel, loading, btnColor, error, onSubmit}) => {
    return ( 
        <Grid container>
            <Grid item xs={12}>
                {error ? <DisplayText variant='subtitle2' color='error' align='center'>{error}</DisplayText> : null}
            </Grid>
            <Grid item xs={12}>
                <Grid container justify='flex-end'>
                    <Grid item>
                        <RoundedButton loading={loading} color={btnColor} onClick={onSubmit} >{btnLabel}</RoundedButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
     );
}
 
export default ActionModalBar;