import React, {Component} from 'react'
import { 
  withStyles,
} from '@material-ui/core/styles'
import {   
  Dialog, 
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle,
  withWidth} from '@material-ui/core'
import {RoundedButton} from '../Buttons/GeneralButtons'
import {ErrorMessage, Title} from '../DisplayData/DisplayData'
import SimpleLoader from '../Loaders/SimpleLoader'
import {grey, red} from '@material-ui/core/colors'

const styles = (theme) => ({
  container:{
    padding:'0px 0px',
    boxSizing:'border-box',
    minWidth:'300px'
  },
  icon:{
    color:red[700],
    fontSize:54
  },
  title:{
    color:grey[900],
    fontWeight:'500'
  },
  message:{
    color:grey[700],
    fontWeight:'400'
  }
})

class SimpleModal extends Component {
  render(){

    const {classes, onClose, content, onClick, maxWidth, onDelete, width} = this.props

    const {open, issending, error} = this.props.data

    let messageContent = null
    if(issending){
      messageContent = <SimpleLoader />
    }else if(error){
      messageContent = <ErrorMessage message={error}/>
    }

    return(
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={maxWidth ? maxWidth : 'md'}
        fullScreen={width === 'sm' || width === 'xs'}
      >
        <DialogTitle style={{color:grey[700], fontSize:14}}>
        {content.title}
        </DialogTitle>
        <DialogContent>
          <div className={classes.container}>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={12}>
                {this.props.children}
              </Grid>
              <Grid item xs={12}>
                {messageContent}
              </Grid>
            </Grid>
          </div>
          
        </DialogContent>
        <DialogActions>
          <Grid container>
            {onDelete ? (
              <Grid item>
                <RoundedButton
                  size='small'
                  label={content.deleteButton}
                  color='red'
                  onClick={onDelete}
                />
              </Grid>
            ) : null}
            <Grid item xs/>
            <Grid item>
              <RoundedButton
                size='small'
                label={content.cancelButton}
                color='transparent'
                onClick={onClose}
              />
            </Grid>
            <Grid item>
              <RoundedButton
                size='small'
                label={content.button}
                color='secondary'
                onClick={onClick}
              />
            </Grid>
          </Grid>
          
          
        </DialogActions>
        


      </Dialog>
    )
  }
}

export default withStyles(styles)(withWidth()(SimpleModal))

