import React, {Component} from 'react'
import { Grid, withStyles, IconButton, Icon } from '@material-ui/core'
import moment from 'moment'



import {form_data} from './data'
import {content} from './content'
import {catalog_matrix} from './catalog_matrix'
import SimpleModal from '../../../../../../../components/Modals/SimpleModal';
import { request_camera_link, request_move_camera } from './requests';
import Hls from 'hls.js'
import { grey } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles';
//import ReactHLS from 'react-hls-player'; 

//import Hls from "hls.js";
//const Hls = window.Hls
const source = "https://b-604520a7.kinesisvideo.us-east-1.amazonaws.com/hls/v1/getHLSMasterPlaylist.m3u8?SessionToken=CiD6-s0VKI90P_Frz9hLWcoS1ft4TIRbEgX7hOSY_9OHfBIQ_o2FBjQaexsT4D52p1eVLhoZ-sqXnimzC-AEnk5ctD1T90GpCHBm5usTzCIgdjgQj57KVIFiXHCEueD-8l2kUMe49Q8wZgSlS--UGsg~"


class HLSPlayerModal extends Component {

  state = {
    camera_data:null,
    formData: {},
    isValid:false,
  }

  componentDidMount(){
    this.initModule()
  }

  render(){

    const {data, onClose, addSpace, camera, catalogs, origin_data, onDeleteProduct, classes} = this.props
    const {formData} = this.state

    let spaceContent = null

    if(addSpace){
      spaceContent = <Grid item xs={12} style={{height:'200px'}}/>
    }

    const style = {
      width:640,
      height:360,
      background:grey[300],
      borderRadius:8,
      boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    }
    
    return(
      <SimpleModal
        maxWidth={'md'}
        content={content}
        onSubmit={this.onSubmit}
        data={data}
        onClose={onClose}
        onClick={onClose}
        onDelete={this.onDelete}
      >
            <div className={classes.wrapper}>
              <div className={classes.inner}>
                <div style={{position:'absolute', top:16, left:16/*bottom:60, right:50*/}}>
                  <ControllerPad onCameraMotion={this.onCameraMotion}/>
                </div>
                <video className={classes.videowrapper} ref={(player)=> {this.player = player}} autoPlay={true}></video>
              </div>
            </div>
      </SimpleModal>
    )
  }

  onCameraMotion = async(direction) => {
    console.log(direction)
    const {camera} = this.props
    try {
      await request_move_camera(camera.camera_id, direction)
    } catch (error) {
      console.log(error)
    }
  }


  initModule = async() => {
    const {camera} = this.props
    try {
      let response = await request_camera_link(camera.camera_id)
      this.setState({camera_data:{...response}})
      if(Hls.isSupported() && this.player) {
        const video = this.player
        const hls = new Hls();
        this.hlsPlayer = hls
        hls.loadSource(response.url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED,function() {
          video.controls = true;
          video.play();
        });
      }
    } catch (error) {
      console.log('Hubo un error!!!!')
    }
  }

  componentWillUnmount(){
    this.hlsPlayer.destroy()
  }
}




const styles = theme => ({
  video:{
    width:400,
    [theme.breakpoints.down('sm')]: {
      width:300,
    },
  },
  videowrapper:{
    width:640,
    height:360,
    background:grey[300],
    borderRadius:8,
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    [theme.breakpoints.down('sm')]: {
      width:'100%',
      height:'auto'
    },
  },
  wrapper:{
    position:'relative'
  },
  inner:{
    position:'relative'
  },
  leftControl:{
    position:'absolute',
    zIndex:2,
    bottom:100, 
    right:100
  },
  rightControl:{
    position:'absolute',
    zIndex:2,
    bottom:100, 
    right:40
  },
  upControl:{
    position:'absolute',
    zIndex:2,
    bottom:130, 
    right:70
  },
  downControl:{
    position:'absolute',
    zIndex:2,
    bottom:70, 
    right:70
  },
  button:{
    color:'white',
    background:theme.palette.primary.main,
    '&:hover':{
      background:theme.palette.primary.dark,
    }
  }
})

export default withStyles(styles)(HLSPlayerModal)



class ControllerPadW extends Component {
  render(){

    const {classes, onCameraMotion} = this.props

    return(
      <div>
        <div className={classes.container}>
          <div onClick={() => onCameraMotion('left')} className={classes.left}><Icon className={classes.icon}>chevron_left</Icon></div>
          <div onClick={() => onCameraMotion('right')} className={classes.right}><Icon className={classes.icon}>chevron_right</Icon></div>
          <div onClick={() => onCameraMotion('up')} className={classes.up}><Icon className={classes.icon}>expand_less</Icon></div>
          <div onClick={() => onCameraMotion('down')} className={classes.down}><Icon className={classes.icon}>expand_more</Icon></div>
        </div>              
      </div>
    )
  }
}

const controller_styles = theme => ({
  container:{
    width:100,
    height:100,
    background:`radial-gradient(at center, ${fade(grey[200],0.5)}, ${fade(grey[700],0.5)})`,
    borderRadius:'50%',
    position:'relative'
  },
  left:{
    color:'white',
    position:'absolute',
    zIndex:3,
    top:29,
    left:-4,
    '&:hover':{
      cursor:'pointer'
    }
  },
  right:{
    color:'white',
    position:'absolute',
    zIndex:3,
    top:29,
    right:-4,
    '&:hover':{
      cursor:'pointer'
    }
  },
  up:{
    color:'white',
    position:'absolute',
    zIndex:3,
    top:-4,
    right:29,
    '&:hover':{
      cursor:'pointer'
    }
  },
  down:{
    color:'white',
    position:'absolute',
    zIndex:3,
    bottom:-4,
    right:29,
    '&:hover':{
      cursor:'pointer'
    }
  },
  icon:{
    fontSize:42
  }
})

const ControllerPad = withStyles(controller_styles)(ControllerPadW)