import React, {Component} from 'react'
import { withStyles } from '@material-ui/styles';
import sampleimage from '../../../../../../assets/lab/appartment.jpg'
import sensorimage from '../../../../../../assets/devices/sensor.png'
import { Card, Avatar, Typography, Grid, IconButton, Icon, withWidth, Badge } from '@material-ui/core';
import { grey, green } from '@material-ui/core/colors';

import Draggable from 'react-draggable';
import Caption from '../../../../../../components/Texts/Caption';
import ReactSVG from 'react-svg';

import manIcon from '../../../../../../assets/devices/man.svg'
import temperatureIcon from '../../../../../../assets/devices/thermometer.svg'
import doorIcon from '../../../../../../assets/devices/open-exit-door.svg'
import energyIcon from '../../../../../../assets/devices/light.svg'


const mapDimmensions = {
  'md':{
    width:500,
    height:375,
  },
  'lg':{
    width:600,
    height:450,
  },
  'xl':{
    width:900,
    height:675,
  }
}

class MapView extends Component {

  state = {
    activeDrags: 0,
    ui:{},
    deltaPosition: {
      x: 0, y: 0
    },
  };

  handleDrag = (e, ui, id) => {
    this.setState({ui:{...ui}})
  };

  onStart = () => {
    //console.log(this.state.activeDrags)
    this.setState({activeDrags: ++this.state.activeDrags});
  };

  onStop = (id) => {
    const {ui} = this.state
    const {width, zones, onChangeZones} = this.props

    let temp = [...zones]

    const dimmensions = mapDimmensions[width]

    temp[id].drawing = {
      x:ui.x/dimmensions.width,
      y:ui.y/dimmensions.height
    }
    //console.log(temp)
    onChangeZones(temp)
    //this.setState({zones:[...temp]})

  };

  render(){

    const {classes, editable, onChangeEditable, width, zones} = this.props
    const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
    const {deltaPosition, controlledPosition} = this.state;

    //console.log(this.state.deltaPosition)

    const dimmensions = mapDimmensions[width]
    //console.log(dimmensions)
    //console.log('Volver a renderear')

    //const xpos = zones[0].position.x*dimmensions.width;
    //const ypos = zones[0].position.y*dimmensions.height;

    let sensorsContent = zones.map((item,key)=>{
      
      const xpos = item.drawing.x*dimmensions.width;
      const ypos = item.drawing.y*dimmensions.height;
      if(item.visible){

        let imageContent = ''

        switch(item.device.model.name){
          case 'Temperature/Humidity':
            imageContent = temperatureIcon
            break
          case 'Presence/Motion':
            imageContent = manIcon
            break
          case 'Open/Close':
            imageContent = doorIcon
            break
          default:
            break
        }
        return(
          <Draggable 
            key={key.toString()}
            disabled={!editable}
            bounds="parent"
            defaultPosition={{x: 0, y: 0}}
            position={{x:xpos,y:ypos}}
            onDrag={(e, ui) => this.handleDrag(e, ui, key)}   
            onStop={() => this.onStop(key)}  
            >
              <div className={classes.box}>
                <div style={{position:'relative'}}>
                  <div className={classes.statusIcon} >
                    <Icon style={{fontSize:14}}>brightness_1</Icon>
                  </div>
                  <div className={classes.numberIcon}>
                    <Avatar className={classes.numberAvatar}>{item.device.id}</Avatar>
                  </div>
                  <Card className={classes.imageSensorContainer} elevation={5}>
                    <ReactSVG 
                      src={imageContent}
                      className={classes.imageSensor} 
                      beforeInjection={svg => {svg.setAttribute('fill', 'white')}}/>
                  </Card>
                </div>
                
              </div>
            </Draggable>
        )
      }
      return null
      


    })

    return(
      <div>
        <div className={classes.header}>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <Typography variant='h5' className={classes.title}>
                Departamento D4
              </Typography>
            </Grid>
            <Grid item>
              <IconButton size='small' color='primary' onClick={onChangeEditable}>
                <Icon>{editable ? 'lock_open':'lock'}</Icon>
              </IconButton>
            </Grid>
          </Grid>
        </div>
        <div className={classes.root}>
          <div style={{position:'absolute '}}>
            <img src={sampleimage} alt='Sample Image' className={classes.image}/>
          </div>
          
          {sensorsContent}
          
          
        </div>
      </div>
      
    )
  }
}

const styles = theme => ({
  title:{
    color:grey[700],
    fontWeight:'500'
  },
  header:{
    boxSizing:'border-box',
    paddingTop:18,
    height:75
  },
  draggable:{
    display: 'inline-block'
  },
  root:{
    position:'relative',

    width:'500px',
    height:'375px',
    borderRadius:'8px',
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    [theme.breakpoints.only('lg')]: {
      width:'600px',
      height:'450px',
    },
    [theme.breakpoints.only('xl')]: {
      width:'900px',
      height:'675px',
    },
  },
  elementContainer:{
    position:'absolute',
    '&:hover':{
      cursor:'pointer'
    },
    left:'10%',
    top:'10%'
  },
  image:{
    borderRadius:'8px',
    width:'100%'
  },
  imageSensor:{
    margin:'auto'
  },
  imageSensorContainer:{
    position:'relative',
    padding:4,
    borderRadius:"50%",
    background:theme.palette.primary.main,
    '&:hover':{
      cursor:'pointer'
    },
  },
  statusIcon:{
    position:'absolute', 
    zIndex:2,
    color:green[500],
    right:0,
    bottom:-4
  },
  numberIcon:{
    position:'absolute',
    zIndex:2,
    top:-6,
    left:-6,
  },
  numberAvatar:{
    fontSize:12,
    width:20,
    height:20,
    background:theme.palette.primary.main,
    border:'1px solid white'
  },
  box:{
    position: "absolute",
    
    width:50,
    display: 'inline-block'
  }
})

export default withStyles(styles)(withWidth()(MapView))