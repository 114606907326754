import React from 'react'
import { IoTCard } from '../../../../../../components/Layouts/IoTCard/IoTCard';
import { DataItemWrapper } from '../../../../../../components/DisplayData/DisplayData';
import { Grid, Icon, Switch } from '@material-ui/core';
import ContentText from '../../../../../../components/Texts/ContentText';
import { green, red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';
import SimpleLoader from '../../../../../../components/Loaders/SimpleLoader';

export const OutputsController = props => {

  const {data, onEdit, onChange, isloading} = props

  let r_eui, r_technology,r_name, r_identifier

  let led_status = [
    {name:'Output A', status:false},
    {name:'Output B', status:false},
    {name:'Output C', status:false},
    {name:'Output D', status:false},
  ]

  if(data){
    led_status[0].status = data.data.digital_output_1.value === 1
    led_status[1].status = data.data.digital_output_2.value === 1
    led_status[2].status = data.data.digital_output_3.value === 1
    led_status[3].status = data.data.digital_output_4.value === 1
  }

  let loaderContent = null
  if(isloading){
    loaderContent = <SimpleLoader />
  }

  return(
    <IoTCard title={{text:'Digital Outputs', align:'center'}}>
      <Grid container spacing={3}>
        {led_status.map((item,key)=>{
          return(
            <Grid item xs={12} md={6} key={key.toString()}>
              <Grid container direction='column' alignItems='center'>
                
                <Grid item>
                  <ContentText text={item.name} medium variant='light'/>
                </Grid>
                <Grid item style={{height:8}}/>
                <Grid item>
                  <ContentText text={item.status ? 'ON' : 'OFF'} medium />
                </Grid>
                <Grid item >
                  <IOSSwitch 
                    checked={item.status}
                    onChange={(event) => onChange(event, key+1)}
                    disabled={isloading}
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
      <Grid container justify='flex-end'>
        <Grid item>
          {loaderContent}
        </Grid>
      </Grid>
    </IoTCard>
  )
}

const IOSSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});