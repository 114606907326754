import { makeStyles } from '@material-ui/core';
import { blueGrey, green, grey, red } from '@material-ui/core/colors';
import React from 'react';
import machineIcon from '../../../../assets/solerpalau/water-pump.png'
import cx from 'classnames'

const useStyles = makeStyles(theme => ({
    root:{
        position:'relative',
        width:72,
        height:72,
        background:green[200],
        border:`4px solid ${green[800]}`,
        borderRadius:'50%',
        boxShadow: `0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)`,
        '&:hover':{
            cursor:'pointer',
            background:green[300],
        },
        [theme.breakpoints.down('md')]:{
            width:54,
            height:54,
        }
    },
    error:{
        background:red[200],
        border:`4px solid ${red[800]}`,
        '&:hover':{
            cursor:'pointer',
            background:red[300],
        },
    },
    image:{
        position:'absolute',
        top:'50%', left:'50%',
        transform:'translate(-50%, -50%)',
        width:'100%',
        height:'100%',
        boxSizing:'border-box',
        padding:4

    },
    label:{
        position:'absolute',
        bottom:0,
        left:'50%',
        transform:'translate(-50%, 100%)',
        background:'white',
        fontSize:14,
        padding:8,
        borderRadius:12,
        textAlign:'center',
        fontWeight:500,
        color:blueGrey[900],
        boxShadow: `0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)`,
        [theme.breakpoints.down('md')]:{
            fontSize:11
        }
    }

}))

const MachineButton = ({label, status}) => {

    const classes = useStyles()

    return(
        <div className={cx({
            [classes.root]:true,
            [classes.error]:status === 0
        })}>
            <img src={machineIcon} alt='' className={classes.image}/>
            <div className={classes.label}>{label}</div>
        </div>
    )
}

export default MachineButton