import React, {Component} from 'react'
import { withStyles, Grid } from '@material-ui/core';
import ContentText from '../../../../../components/Texts/ContentText';
import CustomEnergyGauge from './CustomEnergyGauge';
import CustomEnergyCurrent from './CustomEnergyCurrent';

class ElectricBoard extends Component {
  render(){
    return(
      <div>
        <Grid container>
          <Grid item xs={12} md={3}>
            <Grid container direction='column' alignItems='center'>
              <Grid item>
                <ContentText text='Phase A' medium/>
              </Grid>
              <Grid item>
                <CustomEnergyGauge />
              </Grid>
              <Grid item>
                <CustomEnergyCurrent />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid container direction='column' alignItems='center'>
              <Grid item>
                <ContentText text='Phase B' medium/>
              </Grid>
              <Grid item>
                <CustomEnergyGauge />
              </Grid>
              <Grid item>
                <CustomEnergyCurrent />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid container direction='column' alignItems='center'>
              <Grid item>
                <ContentText text='Phase C' medium/>
              </Grid>
              <Grid item>
                <CustomEnergyGauge />
              </Grid>
              <Grid item>
                <CustomEnergyCurrent />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={3}>
            <Grid container direction='column' alignItems='center'>
              <Grid item>
                <ContentText text='General' medium/>
              </Grid>
              <Grid item>
                <CustomEnergyGauge />
              </Grid>
              <Grid item>
                <CustomEnergyCurrent />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const style = theme => ({
  root:{}
})

export default withStyles(style)(ElectricBoard)