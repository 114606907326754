import {mainServer} from '../../../../variables/config'

export const request_user = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  console.log(logData)
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/me");
  return response.data.data.user
}
