import {mainServer} from '../../../../../../../variables/config'

export const request_camera_link = async(id) => {
  let response;
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  response = await mainServer.instance.get(`/camera/${id}/hls?expiration=1800`)
  //console.log(response.data.data)
  return response.data.data
}



export const request_move_camera = async(id, direction) => {
  let response;
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  response = await mainServer.instance.post(`/camera/${id}/ptz/${direction}`, {value:1000})
  console.log(response.data)
  //console.log(response.data.data)
  //return response.data.data
}