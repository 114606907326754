const styles = (theme) => ({
  root:{
    padding:'32px',
  },
  image:{
    borderRadius:'32px',
    marginBottom:32
  }
})

export default styles