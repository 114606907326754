import React, {Component} from 'react'
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper';
import ContentText from '../../../../../components/Texts/ContentText';
import { palette } from '../../../../../variables/config';
import { Grid, withStyles } from '@material-ui/core';
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';

import LineInputSelect from '../../../../../components/Inputs/LineInputSelect/LineInputSelect'
import LineInputText from '../../../../../components/Inputs/LineInputText/LineInputText'
import moment from 'moment'


class CameraRecordSettings extends Component {

  state = {
    formData: JSON.parse(JSON.stringify(form_data)),
    isValid:false,
  }

  render(){

    const {formData} = this.state
    const {classes} = this.props
    let contentA = null

    contentA = Object.keys(formData).map((item,key)=> {

      let inputContent = null
      
      switch (formData[item].config.type) {
        
        case 'select':
          inputContent = (
            <LineInputSelect
              wrap='vertical'
              data={formData[item]}
              onChange={this.onInputChange}
            />
          )
          break;
        default:
          inputContent = (
            <LineInputText
              data={formData[item]}
              onChange={this.onInputChange}
            />
          )
          break;
      }
      return(
        <Grid item xs={12} key={key.toString()}>
          {inputContent}
        </Grid>
      )
    })



    return(
      <CardWrapper>
        <div className={classes.root}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ContentText text={content.title} medium color={palette.primary.main}/>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {contentA}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify='flex-end'>
                <Grid item>
                  <RoundedButton label='Request' size='small' onClick={this.onSubmit}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CardWrapper>
    )
  }

  componentDidMount(){
    const {formData} = this.state
    let temp = JSON.parse(JSON.stringify(formData))
    Object.keys(formData).forEach(item => {
      temp[item].config = {...temp[item].config,...content.form[item]} // Init labels, placeholders, errors, helpers
    });
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
    if(id === 'product_id'){
      this.props.onRequestDiagnostic(data.value)
    }
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit} = this.props;
    const {formData} = this.state;
    let data2Send = {};

    Object.keys(formData).forEach((item)=>{
      if(formData[item].isValid){
          data2Send = {...data2Send, [item]:formData[item].value}
      }
    })
    //console.log(data2Send)
    if(JSON.stringify(data2Send)!=="{}"){
      onSubmit(data2Send)
    }else{
      console.log('Invalid form')
    }
      
  }


}

const styles = theme => ({
  root:{
    padding:16,
    width:300
  }
})

export default withStyles(styles)(CameraRecordSettings)

const content = {
  title:'Settings',
  form:{
    start_date:{
      label:'Start date',
      placeholder:'Start date',
      helper:'Invalid date'
    },
    start_hour:{
      label:'Start hour',
      placeholder:'Select a time',
      helper:'Invalid time'
    },
    end_date:{
      label:'End date',
      placeholder:'Type a name',
      helper:'Invalid date'
    },
    end_hour:{
      label:'End hour',
      placeholder:'Select a time',
      helper:'Invalid time'
    },
  }
}

const form_data = {
  start_date:{
    value: moment().format('YYYY-MM-DD'),
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: true,
    config:{
      id:'start_date',
      type:'date',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'date',
    }
  },
  start_hour:{
    value: '13:00',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: true,
    config:{
      id:'start_hour',
      type:'time',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'time',
    }
  },
  end_date:{
    value: moment().format('YYYY-MM-DD'),
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: true,
    config:{
      id:'end_date',
      type:'date',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'date',
    }
  },
  
  end_hour:{
    value: '14:00',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: true,
    config:{
      id:'end_hour',
      type:'time',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'time',
    }
  },
}