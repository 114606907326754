export const content = {
  title:'Camera',
  nodata:'No data',
  error:'No data',
  button:'Cerrar',
  form:{
    identifier:{
      label:'Identifier',
      placeholder:'Type a identifier',
      helper:'Invalid identifier'
    },
    name:{
      label:'Name',
      placeholder:'Type a name',
      helper:'Invalid name'
    },
  },
}