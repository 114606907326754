import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import WarningModal from '../../../../components/Modals/WarningModal'


import ZoneList from './ZoneList/ZoneList'



import {request_users, request_delete_user, request_welcome, request_zones, request_add_zone, request_delete_zone} from './requests'
import {cleanData2Table} from './customFunctions'
import AddZoneModal from './Modals/AddZoneModal/AddZoneModal';


class Zones extends Component{

  state = {
    isloading:false,
    users:[],
    zones:[],
    filter:null,
    selected_zone:null,
    tableConfig:{
      filter:{
        user_type:null,
        status:null,
        general:null
      },
      searchText:'',
      page:0,
      rowsPerPage:5,
      totalItems:0
    },
    modals:{
      add_zone:{open:false,issending:false,error:null},
      warning_delete_item:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  render(){

    const {classes, history, language,translations} = this.props
    const {users, zones, modals, success, filter, tableConfig, isloading} = this.state
    const content = contentData[language]

    const data_users = cleanData2Table(zones,content.nodata)
    //console.log(data_users)


    console.log(zones)

    let modalcontent = null

    if(modals.warning_delete_item){
      modalcontent=(
        <WarningModal 
          data={modals.warning_delete_item}
          content={content.modals.warning_modal}
          onClose={() => this.onCloseModal('warning_delete_item')}
          onDelete={this.onDeleteZone}
        />
      )
    }

    if(modals.add_zone.open){
      modalcontent = <AddZoneModal 
        fullWidth={true}
        addSpace={true}
        data={modals.add_zone}
        onClose={() => this.onCloseModal('add_zone')}
        onSubmit={this.onAddZone}
      />
    }
    
    return(
      <BasicView
        history={history}
        content={content.layout}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar} 
        isloading={isloading}
      >
        {modalcontent}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ZoneList 
              filter={filter}
              filters={content.filters}
              master_filters={content.superfilters}
              config={tableConfig}
              onChangeFilter={this.onChangeFilter}
              header={content.header}
              data={data_users}
              onUserSelected={this.onPatientSelected}
              onAddItem={() => this.onOpenModal('add_zone')}
              onDeleteItem={this.onPreDeleteZone}
              onChangeConfig={this.onChangeTableConfig}
            />
          </Grid>
        </Grid>
        
      </BasicView>
      
    )
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    this.setState({isloading:true})
    try{
      let response
      const {tableConfig} = this.state
      const url = this.getUrlWithFilters2(tableConfig)
      response = await request_zones(url.url, url.params)
      let temp = {...tableConfig}
      temp.totalItems = response.zone_count
      this.setState({tableConfig:temp, zones:[...response.zones],})

      console.log(response)
    }catch(e){
      console.log(e)
    }
    this.setState({isloading:false})
  }

  onPreDeleteZone = (id) => {
    console.log(id)
    this.setState({selected_zone:id})
    this.onOpenModal('warning_delete_item')
  }

  onDeleteZone = async() => {
    const {match} = this.props
    const {id} = match.params
    const {selected_zone} = this.state
    
    const type = 'warning_delete_item'
    try {
      this.onEditModal(type,{issending:true})
      let response
      response = await request_delete_zone(selected_zone)
      console.log(response)
      const {tableConfig} = this.state
      const url = this.getUrlWithFilters2(tableConfig)
      response = await request_zones(url.url, url.params)
      let temp = {...tableConfig}
      temp.totalItems = response.zone_count
      this.setState({tableConfig:temp, zones:[...response.zones],})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }

  onAddZone = async(data) => {
    let data2send = {...data, devices:[]}
    console.log(data2send)
    
    const type = 'add_zone'
    try {
      this.onEditModal(type,{issending:true})
      let response
      await request_add_zone(data2send)

      /** REQUEST ALL AGAIN */
      const {tableConfig} = this.state
      const url = this.getUrlWithFilters2(tableConfig)
      response = await request_zones(url.url, url.params)
      let temp = {...tableConfig}
      temp.totalItems = response.zone_count
      this.setState({tableConfig:temp, zones:[...response.zones],})


      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }



  getUrlWithFilters2 = (tableConfig) => {
    const {page, rowsPerPage} = tableConfig

    let url = '/list'
    let params = {limit:rowsPerPage,offset:page*rowsPerPage}

    return {
      url:url,
      params: {...params}
    }
  }

  onChangeTableConfig = async(data) => {
    const payload = this.getUrlWithFilters2(data)
    let response = await request_zones(payload.url, payload.params)

    let temp = {...data}
    if(data.filter.general !== undefined && data.filter.general !== null){
      if(data.filter.general.id !== null){
        temp.isSearch = true
      }else{
        temp.isSearch = false
      }
    }else{
      temp.isSearch = false
    }
    temp.totalItems = response.zone_count
    this.setState({tableConfig:temp, zones:[...response.zones],})
  }

  onChangeFilter = (item) => {
    this.setState({filter:item.id})
  }

  onPatientSelected = async(id) => {
    const {history} = this.props
    history.push('/zones/'+id)
  }

  onAddPatient = async() => {
    const {history} = this.props
    history.push('/zones/add')

  }

  onPreDeleteUser = (id) => {
    const type = 'warning_delete_item'
    this.onEditModal(type, {open:true, id:id})
  }

  

  onDeleteUser = async(id) => {
    const type = 'warning_delete_item'
    try {
      this.onEditModal(type, {issending:true})
      let response = await request_delete_user(id)
      this.setState({users:[...response]})
      this.onEditModal(type, {open:false,issending:false, error:null})
      this.onActivateSuccessSnack()
    } catch (error) {
      if(error.response){
        this.onEditForm(type,{issending:false, error:error.response.data.error.message})
      }
    }
  }

  onOpenModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }
}

export default withStyles(styles)(Zones)