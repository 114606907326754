import { Grid, LinearProgress } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import BasicView from '../../../components_v1/Structure/Layouts/BasicView';
import ControllersView from './components/ControllersView';
import ZoneCard from './components/ZoneCard';
import {request_device} from './requests'
import moment from 'moment'
import { ntcPoints } from '../../../variables/sensors/ntcData';
import { onGetCurrentPoint, onGetResistancePoint, onGetTemperaturePoint } from '../../../shared/utility';
import LoadingContainer from '../../../components_v1/Structure/Layouts/LoadingContainer';
// ID 5ffa9981b816cf0019ca2978

const deviceID = '5ffa9981b816cf0019ca2978'

const BearingControlSite = ({history}) => {

    const [device, setDevice] = useState({
        id:null,
        temperature:null,
        vibration:null,
        status:1
    })
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const initModule = async() => {
            setLoading(true)
            const _device = await request_device(deviceID)
            let _aux = {...device}
            const timestamp = moment()
            if(_device){
                const rTemp = onGetResistancePoint(_device.data.universal_input_3.value/100)
                const temp = onGetTemperaturePoint(rTemp)
                const currentPoint = onGetCurrentPoint(_device.data.universal_input_1.value*100)
                _aux.id = _device._id
                _aux.temperature = temp
                _aux.vibration = currentPoint
                _aux.status = timestamp.diff(moment(_device.data.last_seen.value)) < 15*60*1000
            }
            //console.log(_aux)
            setDevice(_aux)
            setLoading(false)

        }
        initModule()
    }, [count])

    useEffect(() => {
        const interval = setInterval(() => {
            let _count = count + 1
            setCount(_count)
          }, 15000);
          return () => clearInterval(interval);
    }, [])

    const onGo2Page = () => {
        console.log(deviceID)
        history.push(`/bearing-site/${deviceID}`)
    }


    return(
        <div>
            <BasicView title='Motor bearing monitoring' >
                {loading ? <LinearProgress /> : null}
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ControllersView device={device} onGo2Page={onGo2Page}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ZoneCard onSelectedDevice={onGo2Page}/>
                        </Grid>
                    </Grid>
                </div>
            </BasicView>
        </div>
    )
}

export default BearingControlSite