export const deviceList = [
  {
    id:1,
    name:'TH-001',
    identifier:'Zone A',
    model:{
      type:'Sensor',
      name:'Temperature/Humidity' // Temperature/Humidity // Presence/Motion // Open/Close
    },
    data:{
      temperature:{
        value:23.5,
        unit:'°C',
      },
      humidity:{
        value:33.6,
        unit:'%RH',
      }
    }
  },
  {
    id:2,
    name:'PR-001',
    identifier:'Zone A',
    model:{
      type:'Sensor',
      name:'Presence/Motion' // Temperature/Humidity // Presence/Motion // Open/Close
    },
    data:{
      presence:{
        value:0,
        unit:'',
      },
    }
  },
  {
    id:3,
    name:'OP-001',
    identifier:'Zona A',
    model:{
      type:'Sensor',
      name:'Open/Close' // Temperature/Humidity // Presence/Motion // Open/Close
    },
    data:{
      opening:{
        value:0,
        unit:'',
      },
    }
  },
  {
    id:4,
    name:'TH-002',
    identifier:'Zone B',
    model:{
      type:'Sensor',
      name:'Temperature/Humidity' // Temperature/Humidity // Presence/Motion // Open/Close
    },
    data:{
      temperature:{
        value:23.5,
        unit:'°C',
      },
      humidity:{
        value:33.6,
        unit:'%RH',
      }
    }
  },
  {
    id:5,
    name:'PR-002',
    identifier:'Zone B',
    model:{
      type:'Sensor',
      name:'Presence/Motion' // Temperature/Humidity // Presence/Motion // Open/Close
    },
    data:{
      presence:{
        value:0,
        unit:'',
      },
    }
  },
  {
    id:6,
    name:'OP-002',
    identifier:'Zona B',
    model:{
      type:'Sensor',
      name:'Open/Close' // Temperature/Humidity // Presence/Motion // Open/Close
    },
    data:{
      opening:{
        value:0,
        unit:'',
      },
    }
  },
  
]