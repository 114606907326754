export const ntcPoints = [
    {resistance:333560, temperature:-40},
    {resistance:241070, temperature:-35},
    {resistance:176080, temperature:-30},
    {resistance:129930, temperature:-25},
    {resistance:96807, temperature:-20},
    {resistance:72809, temperature:-15},
    {resistance:55253, temperature:-10},
    {resistance:42292, temperature:-5},
    {resistance:32639, temperature:0},
    {resistance:25391, temperature:5},
    {resistance:19902, temperature:10},
    {resistance:15713, temperature:15},
    {resistance:12493, temperature:20},
    {resistance:10000, temperature:25},
    {resistance:8055.9, temperature:30},
    {resistance:6530, temperature:35},
    {resistance:5324.6, temperature:40},
    {resistance:4366.5, temperature:45},
    {resistance:3600.5, temperature:50},
    {resistance:2984.6, temperature:55},
    {resistance:2486.6, temperature:60},
    {resistance:2081.8, temperature:65},
    {resistance:1751.1, temperature:70},
    {resistance:1479.6, temperature:75},
    {resistance:1255.6, temperature:80},
    {resistance:1070, temperature:85},
    {resistance:915.55, temperature:90},
    {resistance:786.43, temperature:95},
    {resistance:678.07, temperature:100},
    {resistance:586.75, temperature:105},
    {resistance:509.52, temperature:110},
    {resistance:443.94, temperature:115},
    {resistance:388.06, temperature:120},
    {resistance:340.29, temperature:125},
]