import { CircularProgress, Fade, Grid } from '@material-ui/core';
import React from 'react';
import DisplayText from '../../Texts/DisplayText';

const LoadingContainer = ({children, loading}) => {
    return ( 
        <div>
            {loading ? (
                <Grid container alignItems='center' direction='column'><Grid item><CircularProgress size={32}/></Grid><Grid item><DisplayText>Cargando...</DisplayText></Grid></Grid>
            ) : null}
            <Fade in={!loading}>{children}</Fade>
        </div>
     );
}
 
export default LoadingContainer;