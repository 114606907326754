import React, {Component} from 'react'
import { Grid, withStyles } from '@material-ui/core'
import moment from 'moment'
import { request_camera_link } from './requests';
import Hls from 'hls.js'
//import ReactHLS from 'react-hls-player'; 

//import Hls from "hls.js";
//const Hls = window.Hls
const source = "https://b-604520a7.kinesisvideo.us-east-1.amazonaws.com/hls/v1/getHLSMasterPlaylist.m3u8?SessionToken=CiD6-s0VKI90P_Frz9hLWcoS1ft4TIRbEgX7hOSY_9OHfBIQ_o2FBjQaexsT4D52p1eVLhoZ-sqXnimzC-AEnk5ctD1T90GpCHBm5usTzCIgdjgQj57KVIFiXHCEueD-8l2kUMe49Q8wZgSlS--UGsg~"


class DivHLSPlayerModal extends Component {

  state = {
    camera_data:null,
    formData: {},
    isValid:false,
  }

  componentDidMount = async() =>{
    let response = await this.initModule()
    console.log('Me monteee!!!')
    if(Hls.isSupported() && this.player) {
      console.log(this.player)
      const streamURL = "https://b-604520a7.kinesisvideo.us-east-1.amazonaws.com/hls/v1/getHLSMasterPlaylist.m3u8?SessionToken=CiACMf5OE3Gv2v_WhKJwFUFbBzlvDw1boAlnrAzHaKvzxxIQNVBBrCZr8QuOP04pPyiV8xoZrp1oWdfKcAL8mAh8J4k6rUvL3ilS-BfsmyIgU27R7RJqTy3S3N-PRZC6XzlTJHs_G5pB9TIAD_4CDkg~"
      const video = this.player
      const hls = new Hls();
      hls.loadSource(streamURL);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED,function() {
        video.play();
      });
    }
  }

  render(){

    const {data, onClose, addSpace, camera, catalogs, origin_data, onDeleteProduct, classes} = this.props
    const {formData} = this.state

    let spaceContent = null

    //console.log(camera)
    if(addSpace){
      spaceContent = <Grid item xs={12} style={{height:'200px'}}/>
    }

    console.log(this.player)

    //console.log(this.player)

    const style = {
      width:640,
      height:360,
      background:'#000'
    }
    
    return(
      <div className={classes.wrapper}>
        <div className={classes.inner}>
          <video style={style} ref={(player)=>this.player = player} autoPlay={true}></video>
        </div>
      </div>
    )
  }


  initModule = async() => {
    
    const {camera} = this.props
    let response
    try {
      response = await request_camera_link(camera.camera_id)
      console.log(response)
      this.setState({camera_data:{...response}})
      return response

    } catch (error) {
      
    }

    
  }
}


const styles = theme => ({
  video:{
    width:400
  },
  wrapper:{
    position:'relative'
  }
})

export default withStyles(styles)(DivHLSPlayerModal)