import { mainServer } from "../../../variables/config";

export const request_device = async (id) => {
    mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
    let response = await mainServer.instance.get(`/device/${id}`)
    return response.data.data.device
}

export const request_records = async (id, params) => {
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  let response = await mainServer.instance.get(`/device/${id}/record`, {params:{...params}})
  return response.data.data.record
}