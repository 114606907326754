import React, {Component} from 'react'
import Subtitle from '../../../../components/Texts/Subtitle';
import { withStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import ContentText from '../../../../components/Texts/ContentText';

const styles = (theme) => ({
  root:{
    paddingTop:0,
    paddingBottom:0,
    [theme.breakpoints.only('md')]: {
    },
  },
  image:{
    width:48,
    [theme.breakpoints.only('md')]: {
      width:48,
    },
  },
  text:{
    color:grey[700],
    fontSize:16,
    fontWeight:'500',
    [theme.breakpoints.only('md')]: {
      fontSize:16
    },
  }
})

class SensorItem extends Component {
  render(){

    const {data, classes} = this.props

    return(
      <div className={classes.root}>
        <Grid container direction='column' spacing={1}>
          <Grid item>
            <ContentText text={data.title} variant='light' medium align='center'/>
          </Grid>
          <Grid item>
            <Grid container alignItems='center' spacing={2}>
              <Grid item>
                <img src={data.image} alt='' className={classes.image}/>
              </Grid>
              <Grid item xs>
                <Typography variant='h5' className={classes.text}>
                  {data.value.toFixed(1) + ' ' + data.unit}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </div>
    )
    
  }
}

export default withStyles(styles)(SensorItem)
