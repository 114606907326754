import React, { Component } from 'react'
import { withStyles } from '@material-ui/styles';
import { SvgIcon, Icon, Grid } from '@material-ui/core';
import ReactSVG from 'react-svg'

import Caption from '../../../../../../components/Texts/Caption'

import allIcon from '../../../../../../assets/devices/wifi.svg'
import svgimage from '../../../../../../assets/devices/man.svg'
import temperatureIcon from '../../../../../../assets/devices/thermometer.svg'
import doorIcon from '../../../../../../assets/devices/open-exit-door.svg'
import energyIcon from '../../../../../../assets/devices/light.svg'

import { grey, blue } from '@material-ui/core/colors';


const menuItems = [
  {id:'ALL',name:'All',icon:allIcon},
  {id:'MOTION',name:'Presence',icon:svgimage},
  {id:'TH',name:'T/H',icon:temperatureIcon},
  {id:'OPEN/CLOSE',name:'O/C',icon:doorIcon},
  {id:'ENERGY',name:'AC',icon:energyIcon},
]

class MenuBar extends Component {
  render(){

    const {classes, onSelected, selected} = this.props

    return(
      <div>
        <Grid container spacing={1}>
          {menuItems.map((item,key)=>{
            return(
              <Grid item key={key.toString()}> 
                <MenuButton data={item} active={item.id===selected} onSelected={onSelected}/>
              </Grid>
             
            )
          })}
        </Grid>
      </div>
    )
  }
}

const styles = theme => ({
  root:{
    width:32,
    color:'red',
    '':{

    }
  }
})

export default withStyles(styles)(MenuBar)

class MenuButtonW extends Component {
  render(){

    const {classes, data, active, onSelected} = this.props

    return(
      <div className={classes.root} onClick={() => onSelected(data.id)}>
        <Grid container direction='column' alignItems='center'>
          <Grid item>
            <ReactSVG 
              src={data.icon} 
              className={classes.button} 
              beforeInjection={svg => {svg.setAttribute('fill', active ? blue[900] : grey[400])}}/>
          </Grid>
          <Grid item>
            <Caption text={data.name} align='center' variant={active ? 'bold' : 'light'} medium={active}/>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles_button = theme => ({
  root:{
    width:60,
    padding:4,
    borderRadius:8,
    '&:hover':{
      cursor:'pointer',
      background: grey[50],
      
    }
  },
  button:{
    width:32,
  }
})

const MenuButton = withStyles(styles_button)(MenuButtonW)



