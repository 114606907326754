import React, {Component} from 'react'
import { withStyles } from '@material-ui/styles';
import sampleimage from '../../../../../../../assets/lab/appartment.jpg'
import sensorimage from '../../../../../../../assets/devices/sensor.png'
import { Card, Avatar, Typography, Grid, IconButton, Icon, withWidth, Badge } from '@material-ui/core';
import { grey, green, blue, red } from '@material-ui/core/colors';

import Draggable from 'react-draggable';
import Caption from '../../../../../../../components/Texts/Caption';
import ReactSVG from 'react-svg';

import cameraIcon from '../../../../../../../assets/devices/security-camera.svg'
import lumminaryIcon from '../../../../../../../assets/devices/lightning.svg'
import manIcon from '../../../../../../../assets/devices/man.svg'
import controlIcon from '../../../../../../../assets/devices/controls.svg'
import temperatureIcon from '../../../../../../../assets/devices/thermometer.svg'
import doorIcon from '../../../../../../../assets/devices/open-exit-door.svg'
import energyIcon from '../../../../../../../assets/devices/light.svg'
import { RoundedButton } from '../../../../../../../components/Buttons/GeneralButtons';
import PopperWrapper from '../../../../../../../components/Poppers/PopperWrapper';
import DevicePopper from './DevicePopper';

import moment from 'moment-timezone'
const timezone_data = "America/Mexico_City"



const mapDimmensions = {
  'xs':{
    width:300,
    height:225,
  },
  'sm':{
    width:300,
    height:225,
  },
  'md':{
    width:500,
    height:375,
  },
  'lg':{
    width:600,
    height:450,
  },
  'xl':{
    width:900,
    height:675,
  }
}

class MapView extends Component {

  state = {
    activeDrags: 0,
    ui:{},
    deltaPosition: {
      x: 0, y: 0
    },
    open:false,
    anchorEl:null,
    device:null,
  };

  

  render(){

    const {classes, editable, onChangeEditable, width, zones, onChangeImage, image, zone, onDelete, title} = this.props
    const dragHandlers = {onStart: this.onStart, onStop: this.onStop};
    const {deltaPosition, controlledPosition, anchorEl, open, device} = this.state;

    //console.log(zone)

    const dimmensions = mapDimmensions[width]

    let sensorsContent = zone.map((item,key)=>{

      let xpos = 0
      let ypos = 0

      if(item.x_coordinate <= 1 && item.y_coordinate <= 1){
        xpos = item.x_coordinate*dimmensions.width;
        ypos = item.y_coordinate*dimmensions.height;
      }
      
      

      if(item.visible){

        let imageContent = ''
        let type = 'device'
        let general_id = null

        if(item.device){
          general_id = item.device_id
          switch(item.device.model.name){
            case 'Temperature/Humidity':
              imageContent = temperatureIcon
              break
            case 'Presence/Motion':
              imageContent = manIcon
              break
            case 'Open/Close':
              imageContent = doorIcon
              break
            case 'Lights Switch':
            case 'Lights Dimming':
              imageContent = lumminaryIcon
              break
            default:
              imageContent = controlIcon
              break
          }
        }

        let status_conn = false

        if(item.camera){
          //console.log(item.camera)
          imageContent = cameraIcon
          type='camera'
          general_id = item.camera_id
          status_conn = item.camera.status === 'Online'

          console.log(item.camera)
        }else{
          const timestamp = item.device.data.last_seen.value

          const sensor_time = moment(timestamp).tz(timezone_data)
          const current_time = moment().tz(timezone_data)
          status_conn = current_time.diff(sensor_time) < 60*60*1000
        }

        //let status_conn = false

        
        
        return(
          <Draggable 
            key={key.toString()}
            disabled={!editable}
            bounds="parent"
            defaultPosition={{x: 0, y: 0}}
            position={{
              x:xpos,
              y:ypos,
            }}
            onDrag={(e, ui) => this.handleDrag(e, ui, key)}   
            onStop={() => this.onStop(general_id, type)}  
            >
              <div className={classes.box} onClick={(event) => this.onOpenMenu(event, item)}>
                
                <div style={{position:'relative'}}>
                  <div className={classes.statusIcon} style={{color: status_conn ? green[700] : red[700]}}>
                    <Icon style={{fontSize:14}}>brightness_1</Icon>
                  </div>
                  <div className={classes.numberIcon}>
                    <Avatar className={classes.numberAvatar}>{item.id ? item.id : 1}</Avatar>
                  </div>
                  <Card className={classes.imageSensorContainer} elevation={5}>
                    <ReactSVG 
                      src={imageContent}
                      className={classes.imageSensor} 
                      beforeInjection={svg => {svg.setAttribute('fill', 'white')}}/>
                  </Card>
                </div>
                
              </div>
            </Draggable>
        )
      }
      return null
      


    })

    return(
      <div>
        <PopperWrapper
          anchorEl={anchorEl}
          open={open && !editable}
          onClickAway={this.onCloseMenu}
        >
          <DevicePopper
            device={device}
            onDelete={this.onDelete}
            onViewDevice={this.onViewDevice}
          />
        </PopperWrapper>
        <div className={classes.header}>
          <Grid container alignItems='center' spacing={2}>
            <Grid item>
              <Typography variant='h5' className={classes.title}>
                {title}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton size='small' color='primary' onClick={onChangeEditable}>
                <Icon>{editable ? 'lock_open':'lock'}</Icon>
              </IconButton>
            </Grid>
          </Grid>
        </div>
        <div className={classes.root}>

          <div style={{position:'absolute '}}>
            <img src={image} alt='Sample Image'  width={dimmensions.width} height={dimmensions.height} style={{borderRadius:8}}/>
          </div>
          
          {sensorsContent}
          
          
        </div>
        <div style={{marginTop:16}}>
          <Grid container justify='flex-end'>
            <Grid item>
              <RoundedButton 
                label='Change'
                size='small'
                icon='photo_camera'
                onClick={onChangeImage}
              />
            </Grid>
          </Grid>
        </div>
      </div>
      
    )
  }

  onDelete = (device) => {
    const {onDelete} = this.props
    this.onCloseMenu()
    onDelete(device)
  }

  onViewDevice = (device) => {
    const {onViewDevice} = this.props
    this.onCloseMenu()
    onViewDevice(device)
  }

  onCloseMenu = () => {
    this.setState({open: false,anchorEl: null});
  };
 

  onOpenMenu = (event, item) => {
    const {onOpenHLSViewer} = this.props
    if(item.device){
      const { currentTarget } = event;
      this.setState({anchorEl: currentTarget,open: true, device:item.device});
    }else{
      onOpenHLSViewer(item)
    }
    
  }

  handleDrag = (e, ui, id) => {
    this.setState({ui:{...ui}})
  };

  onStart = () => {
    //console.log(this.state.activeDrags)
    this.setState({activeDrags: ++this.state.activeDrags});
  };

  onStop = (id, type) => {
    const {ui} = this.state
    const {width, zones, onChangeZones, onChangeDevicePosition} = this.props

    let temp = [...zones]

    const dimmensions = mapDimmensions[width]

    //temp[id].drawing = {
    //  x:ui.x/dimmensions.width,
    //  y:ui.y/dimmensions.height
    //}
    
    let data2send = {
      x_coordinate:ui.x/dimmensions.width,
      y_coordinate:ui.y/dimmensions.height
    }
    //console.log(temp)
  
    onChangeZones(temp)


    //console.log(id)
    onChangeDevicePosition(id, data2send, type)
    //this.setState({zones:[...temp]})

  };
}

const styles = theme => ({
  title:{
    color:grey[700],
    fontWeight:'500'
  },
  header:{
    boxSizing:'border-box',
    paddingTop:18,
    height:75
  },
  draggable:{
    display: 'inline-block'
  },
  root:{
    position:'relative',

    width:'500px',
    height:'375px',
    borderRadius:'8px',
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    [theme.breakpoints.only('lg')]: {
      width:'600px',
      height:'450px',
    },
    [theme.breakpoints.only('xl')]: {
      width:'900px',
      height:'675px',
    },
    [theme.breakpoints.down('sm')]: {
      width:'350px',
      height:'262.5px',
    },
  },
  elementContainer:{
    position:'absolute',
    '&:hover':{
      cursor:'pointer'
    },
    left:'10%',
    top:'10%'
  },
  image:{
    borderRadius:'8px',
    width:'100%'
  },
  imageSensor:{
    margin:'auto'
  },
  imageSensorContainer:{
    position:'relative',
    padding:4,
    borderRadius:"50%",
    background:theme.palette.primary.main,
    '&:hover':{
      cursor:'pointer'
    },
  },
  statusIcon:{
    position:'absolute', 
    zIndex:2,
    color:green[500],
    right:0,
    bottom:-4
  },
  numberIcon:{
    position:'absolute',
    zIndex:2,
    top:-8,
    left:-8,
  },
  numberAvatar:{
    fontSize:12,
    width:20,
    height:20,
    background:blue[600],
    border:'1px solid white'
  },
  box:{
    position: "absolute", 
    width:36,
    display: 'inline-block'
  }
})

export default withStyles(styles)(withWidth()(MapView))