import Dashboard from '../views/Admin/Dashboard/Dashboard'
import UserProfile from '../views/Admin/Profile/UserProfile/UserProfile'
import EditUserProfile from '../views/Admin/Profile/EditUserProfile/EditUserProfile.jsx';
import Devices from '../views/Admin/Devices/Devices/Devices';
import DeviceView from '../views/Admin/Devices/DeviceView/DeviceView';
import Zones from '../views/Admin/Zones/Zones/Zones';
import ZoneView from '../views/Admin/Zones/ZoneView/ZoneView';
import LabView from '../views/Admin/Lab/LabView/LabView';
import DeviceZoneView from '../views/Admin/Zones/DeviceZoneView/DeviceZoneView';
import Energys from '../views/Admin/Energy/Energys/Energys';
import CCTVs from '../views/Admin/CCTV/CCTVs/CCTVs';
import Lights from '../views/Admin/Lights/Lights/Lights';
import AccessControl from '../views/Admin/AccessControl/AccessControl/AccessControl';
import HVACs from '../views/Admin/HVACs/HVACs/HVACs';
import Alarms from '../views/Admin/Alarms/Alarms/Alarms';
import CCTVView from '../views/Admin/CCTV/CCTVView/CCTVView';
import BearingControlSite from '../views/BearingControl/BearingControlSite/BearingControlSite';
import BearingControlView from '../views/BearingControl/BearingControlView/BearingControlView';



const dashboardAdmin = {
    sections:[
        {
            path: "/dashboard",
            icon:'dashboard',
            label:'Dashboard',
            component: Dashboard,
            sidebar:true,
            exact:true,
            level:4
        },
        {
            path: "/bearing-site",
            icon:'dashboard',
            label:'Motor bearings',
            component: BearingControlSite,
            sidebar:true,
            exact:true,
            level:4
        },
        {
            path: "/bearing-site/:id",
            icon:'dashboard',
            label:'Chumaceras',
            component: BearingControlView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/profile",
            icon:'work',
            label:'Asesores',
            component: UserProfile,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/profile/edit",
            icon:'work',
            label:'Asesores',
            component: EditUserProfile,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/devices",
            icon:'device_hub',
            label:'Devices',
            component: Devices,
            sidebar:true,
            exact:true,
            level:4
        },
        {
            path: "/devices/:id",
            icon:'device_hub',
            label:'Devices',
            component: DeviceView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/zones",
            icon:'view_quilt',
            label:'Zones',
            component: Zones,
            sidebar:true,
            exact:true,
            level:4
        },
        {
            path: "/zones/:id",
            icon:'view_quilt',
            label:'Zones',
            component: ZoneView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/zones/:id/device/:deviceid",
            icon:'view_quilt',
            label:'Zones',
            component: DeviceZoneView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/lab",
            icon:'memory',
            label:'Lab',
            component: LabView,
            sidebar:false,
            exact:true,
            level:4
        },
        {
            path: "/energy",
            icon:'flash_on',
            label:'Energy',
            component: Energys,
            sidebar:true,
            exact:true,
            level:4
        },
        /*{
            path: "/cctv",
            icon:'videocam',
            label:'CCTV',
            component: CCTVs,
            sidebar:true,
            exact:true,
            level:4
        },
        {
            path: "/cctv/:id",
            icon:'videocam',
            label:'CCTV',
            component: CCTVView,
            sidebar:false,
            exact:true,
            level:4
        },*/
        { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
    ]
}

export default dashboardAdmin;