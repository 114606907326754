import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Grid,
  Typography,
  withWidth,
  InputAdornment,
  TextField,
  Icon
} from '@material-ui/core';
import {ruleValidation} from '../customFunctions.jsx';
import {blueGrey, grey, red} from '@material-ui/core/colors';
import ContentText from '../../Texts/ContentText.jsx';

const CssTextField = withStyles(theme => ({
  root: {
    paddingTop:6,
    '& input':{
      color:grey[700],
      fontSize:14,
      fontWeight:'500'
    },
    '& .MuiInput-underline': {
      borderBottomColor: grey[300],
      borderBottomWidth:2,
      '&:hover:not($disabled):after': {
        //its when its hover and input is focused 
      },
      '&:hover:not($disabled):before': {
        borderBottomWidth:2,
        borderBottomColor: theme.palette.secondary.light,//its when you hover and input is not foucused 
      },
      '&:after': {
        borderBottomColor: theme.palette.secondary.main,//when input is focused, Its just for example. Its better to set this one using primary color
      },
      '&:before': {
        borderBottomWidth:2,
        borderBottomColor: grey[300],// when input is not touched
      },
    }
  },
}))(TextField);

class LineInputText extends Component{

  onInnerChange = event => {
    const {onChange, data} = this.props;
    const response = event.target.value;
    let temp = {...data};
    temp.value = response;
    temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
    temp.isVisited = true;
    onChange(temp);
  }

  render(){

    const {
      onKeyPress,
      disabled,
    } = this.props;

    const {value, isVisited, isValid, isRequired} = this.props.data;
    const {type,label,placeholder,fullWidth,icon, multiline, rows, helper} = this.props.data.config;

    const error = isVisited && !isValid;

    let inputaddornment = null;
    if(icon){
      inputaddornment = 
      <InputAdornment position='start'>
        <Icon style={{color:blueGrey[500]}}>
          {icon.name}
        </Icon>
      </InputAdornment>
    }

    let inputlabel = null;
    if(label){
      let signal = isRequired ? '*' : ''
      inputlabel = (
        <Grid item>
          <ContentText text={label+signal} variant='light' medium/>
        </Grid>
      )
    }

    return(
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction='column' spacing={0}>
            {inputlabel}
            <Grid item>
                <CssTextField
                  placeholder={placeholder}
                  fullWidth={fullWidth}
                  disabled={disabled}
                  error={error}
                  value={value ? value : ''}
                  onChange={this.onInnerChange}
                  multiline={multiline}
                  rows={rows}
                  type={type}
                  startadornment={inputaddornment}
                  onKeyPress={onKeyPress}
                  inputProps={{
                    spellCheck:"false",
                    autoComplete:"false",
                    name:"hidden"
                  }}
                />
            </Grid>
            <Grid item>
              <Typography variant='caption' style={{color:red[700]}}>
                {error ? helper : ''}
              </Typography>
            </Grid>
          </Grid>

        </Grid>

      </Grid>
    );
  }
};

export default withStyles(styles)(withWidth()(LineInputText));
