import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Avatar,
  Grid,
  withWidth,
  Hidden,
  Typography
} from '@material-ui/core';

import UserPopper from '../../Poppers/UserPopper/UserPopper.jsx';

class UserButton extends Component{

  state = {
    anchorEl: null,
    open:false
  }

  handleClick = () => {
    this.setState({
      open: false,
      anchorEl: null
    });
  };
 

  handleOpenUserPopper = event => {
    const { currentTarget } = event;
    this.setState({
      anchorEl: currentTarget,
      open: true
    });
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onMenuSelected = (url) => {
    console.log(url)
    console.log('Cerrar todo')
    this.handleClick()
    this.props.onMenuSelected(url)
  }

  render(){

    const {classes, user, onLogout, onMenuSelected} = this.props;
    const {open, anchorEl} = this.state;

    let avatarText = ''
    if(user){
      avatarText = avatarText + (user.first_name ? user.first_name[0] : '')
      avatarText = avatarText + (user.last_name ? user.last_name[0] : '')
    }

    return(
      <div>
        <UserPopper
          open={open}
          anchorEl={anchorEl}
          onClickLogout={onLogout}
          onItemSelected={this.onMenuSelected}
          onClickAway={this.handleClick}
        />
         <div className={classes.root} onClick={this.handleOpenUserPopper}>
          <Grid container alignItems='center' justify='center' spacing={8}>
            <Grid item>
              <Avatar className={classes.avatar}>
                <Typography variant='body2' className={classes.text}>
                  {avatarText}
                </Typography>
              </Avatar>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
};

export default withStyles(styles)(withWidth()(UserButton));
