import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';

import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import {blueGrey, grey} from '@material-ui/core/colors';
import ContentText from '../../Texts/ContentText';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { NoSsr, Icon } from '@material-ui/core';

const CssTextField = withStyles(theme => ({
  root: {
    '& input':{
      color:grey[700],
      fontSize:14,
      fontWeight:'500'
    },
    '& .MuiInput-underline': {
      borderBottomColor: grey[300],
      borderBottomWidth:2,
      '&:hover:not($disabled):after': {
        //its when its hover and input is focused 
      },
      '&:hover:not($disabled):before': {
        borderBottomWidth:2,
        borderBottomColor: theme.palette.secondary.light,//its when you hover and input is not foucused 
      },
      '&:after': {
        borderBottomColor: theme.palette.secondary.main,//when input is focused, Its just for example. Its better to set this one using primary color
      },
      '&:before': {
        borderBottomWidth:2,
        borderBottomColor: grey[300],// when input is not touched
      },
    }
  },
}))(TextField);

const useStyles = makeStyles(theme => ({
  root: {
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
    fontSize:14,
    color:grey[700],
    fontWeight:'500'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    fontSize:14
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
    fontSize:14
  },
  singleValue: {
    fontSize: 14,
    color:grey[700],
    fontWeight:'500',
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 14,
    color:grey[400],
    fontWeight:'400'
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  addButton:{
    marginTop:8,
    background:theme.palette.secondary.main,
    borderRadius:'50%',
    color:'white',
    padding:2,
    fontSize:18
  }
}));

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <CssTextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {

  let item2return = null

  const {value} = props.data

  if(value !== -1){
    item2return = (
      <MenuItem
        ref={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontSize:14,
          fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
      >
        {props.children}
      </MenuItem>
    )
  }else{
    item2return = (
      <MenuItem
        ref={props.innerRef}
        selected={props.isFocused}
        component="div"
        {...props.innerProps}
      >
        <Grid container wrap='nowrap' alignItems='center' spacing={1}>
          <Grid item>
            <Icon className={props.selectProps.classes.addButton}>add</Icon>
          </Grid>
          <Grid item xs>
            <ContentText variant='body2' text='Agregar'/>
          </Grid>
        </Grid>
      </MenuItem>
    )
  }

  return item2return
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  return (
    <Typography 
      className={props.selectProps.classes.singleValue} 
      {...props.innerProps}
      noWrap={true}  
    >
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

function IntegrationReactSelect(props) {
  const classes = useStyles();
  const theme = useTheme();

  function handleChange(item) {
    const {data, onChange, onAdd} = props;
    if(item.value !== -1){
      let temp = {...data};
      temp.value = item.value;
      temp.isValid = true;
      temp.isVisited = true;
      onChange(temp);
    }else{
      if(onAdd){
        onAdd()
      }
    }
  }

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      fontSize:14,
      padding:0,
      '& input': {
        font: 'inherit',
        fontSize:14,
      },
    }),
    menuList: styles => ({
      ...styles,
      maxHeight:'200px',
    }),
    control: styles => ({
      ...styles,
      padding:0,
    }),
  };

  const { data, disabled } = props;

  let options;
  let value;
  let isClearable;
  let id;
  let label = '';
  let placeholder = '';
  let inputlabel = null;
  
  if(data){
    if(data.config.addoption){
      options = [
        {value:-1, label:'Agregar'},
        ...data.options];
    }else{
      options = [...data.options];
    }
    
    id = data.config.id;
    isClearable = data.isClearable;
    options.forEach((item, key) => {
      if(item.value === data.value){
        value = key;
      }
    })
    if(data.config.label){
      label = data.config.label
    }
    if(data.config.placeholder){
      placeholder = data.config.placeholder
    }

    if(label){
      let signal = data.isRequired ? '*' : ''
      inputlabel = (
        <Grid item xs={12}>
          <ContentText text={label+signal} variant='light' medium />
        </Grid>
      )
    }
    //addption = data.config.addoption
  }

  

  return (
    <div className={classes.root}>
      
        <Grid container>
          {inputlabel}
          <Grid item xs={12}>
            <NoSsr>
              <Select
                classes={classes}
                styles={selectStyles}
                TextFieldProps={{
                  InputLabelProps: {
                    htmlFor: 'react-select-single',
                    shrink: true,
                  },
                }}
                options={options}
                components={components}
                value={value !== undefined ? options[value] : null}
                onChange={handleChange}
                placeholder={placeholder}
                isClearable={isClearable}
                isDisabled={disabled}
              />
            </NoSsr>
          </Grid>
        </Grid>
        
    </div>
  );
}

export default IntegrationReactSelect;
