export const form_data = {
  name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'name',
      type:'text',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'distance',
      min:1,
      max:255
    }
  },
  user:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'user',
      type:'text',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'distance',
      min:1,
      max:255
    }
  },
  password:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'password',
      type:'password',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'distance',
      min:1,
      max:255
    }
  },
  ip:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'ip',
      type:'text',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'distance',
      min:1,
      max:255
    }
  },
}