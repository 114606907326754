import React from 'react'
import { IoTCard } from '../../../../../../components/Layouts/IoTCard/IoTCard';
import { DataItemWrapper } from '../../../../../../components/DisplayData/DisplayData';
import { Grid, Icon } from '@material-ui/core';
import ContentText from '../../../../../../components/Texts/ContentText';
import { green, red } from '@material-ui/core/colors';

export const InputsController = props => {

  const {data, onEdit} = props

  let r_eui, r_technology,r_name, r_identifier

  let led_status = [
    {name:'Input A', status:false},
    {name:'Input B', status:false},
    {name:'Input C', status:false},
    {name:'Input D', status:false},
  ]

  if(data){
    led_status[0].status = data.data.digital_input_1.value === 1
    led_status[1].status = data.data.digital_input_2.value === 1
    led_status[2].status = data.data.digital_input_3.value === 1
    led_status[3].status = data.data.digital_input_4.value === 1
  }

  return(
    <IoTCard title={{text:'Digital Inputs', align:'center'}}>
      <Grid container spacing={3}>
        {led_status.map((item,key)=>{
          return(
            <Grid item xs={12} md={6} key={key.toString()}>
              <Grid container direction='column' alignItems='center'>
                
                <Grid item>
                  <ContentText text={item.name} medium variant='light'/>
                </Grid>
                <Grid item style={{height:8}}/>
                <Grid item>
                  <ContentText text={item.status ? 'ON' : 'OFF'} medium />
                </Grid>
                <Grid item>
                  <Icon style={{fontSize:36,color: item.status ? green[700] : red[700] }}>brightness_1</Icon>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
      </Grid>
    </IoTCard>
  )
}