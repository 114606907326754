import React from 'react'
import { IoTCard } from '../../../../../../components/Layouts/IoTCard/IoTCard';
import { DataItemWrapper } from '../../../../../../components/DisplayData/DisplayData';
import { Grid } from '@material-ui/core';

export const SensorDetails = props => {

  const {data, onEdit} = props

  let r_eui, r_technology,r_name, r_identifier

  if(data){
    r_eui = data.eui
    r_technology = data.technology
    r_name = data.name
    r_identifier = data.identifier
  }

  return(
    <IoTCard title={{text:'Device details', align:'center'}} isedit onEdit={onEdit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <DataItemWrapper 
            label='Name'
            data={r_name}
            error='No data'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DataItemWrapper 
            label='Identifier'
            data={r_identifier}
            error='No data'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DataItemWrapper 
            label='EUI'
            data={r_eui}
            error='No data'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DataItemWrapper 
            label='EUI'
            data={r_eui}
            error='No data'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DataItemWrapper 
            label='Techonology'
            data={r_technology}
            error='No data'
          />
        </Grid>
      </Grid>
    </IoTCard>
  )
}