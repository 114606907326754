import React from 'react'
import CardWrapper from '../Cards/CardWrapper/CardWrapper';
import moment from 'moment-timezone'

import { Grid } from '@material-ui/core';
import ContentText from '../Texts/ContentText';
import Subtitle from '../Texts/Subtitle';
import { getBatteryPercentage } from '../../variables/config';

import temperatureIcon from '../../assets/devices/thermometer.png'
import humidityIcon from '../../assets/devices/humidity.png'
import batteryIcon from '../../assets/devices/battery.png'
import reportIcon from '../../assets/devices/stopwatch.png'
import openIcon from '../../assets/devices/openblue.png'
import closeIcon from '../../assets/devices/closeblue.png'
import counterIcon from '../../assets/devices/counter.png'
import withpresenceIcon from '../../assets/devices/human.png'
import nopresenceIcon from '../../assets/devices/humanEmpty.png'






const timezone_data = "America/Mexico_City"


export const SensorCard = props => {

  const {type, data} = props

  console.log(type)
  console.log(data)

  let imageContent = null
  let value = ''
  let title = ''
  if(data){
    switch (type) {
      case 'temperature':
        title='Temperature'
        imageContent = temperatureIcon
        value = data.value ? data.value + ' ' + data.unit : ''
        break;
      case 'humidity':
        title='R. Humidity'
        imageContent = humidityIcon
        value = data.value ? data.value + ' ' + data.unit : ''
        break;
      case 'open/close':
        title='Position'
        imageContent = data.value ? openIcon : closeIcon
        value = data.value ? 'Opened' : 'Closed'
        break;
      case 'presence':
        title='Status'
        imageContent = data.value ? withpresenceIcon : nopresenceIcon
        value = data.value ? 'Occupied' : 'Empty'
        break;
      case 'counter':
        title='Counter'
        imageContent = counterIcon
        value = data.value ? data.value : 0
        break;
      case 'energy':
        title='Battery'
        imageContent = batteryIcon
        value = '97.8%'//getBatteryPercentage(data.value) + ' %'
        break;
      case 'time':
        title='Last Report'
        imageContent = reportIcon
        const sensor_time = moment(data.value).tz(timezone_data)
        value = sensor_time.format('MM/DD/YYYY HH:mm:ss')
        break;
    
      default:
        break;
    }
  }

  return(
    <CardWrapper>
      <div style={{padding:32}}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justify='center' direction='column' alignItems='center' spacing={1}>
              
              <Grid item>
                <img src={imageContent} alt='' style={{width:48}}/>
              </Grid>
              <Grid item>
                <ContentText text={title} medium variant='light'/>
              </Grid>
              <Grid item>
                <Subtitle text={value} medium align='center' noWrap/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </div>
    </CardWrapper>
  )
}