import React, {Component} from 'react'
import ContentText from '../../../../components/Texts/ContentText';
import { Grid, Slider, Typography, Icon } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { grey, green, red, amber } from '@material-ui/core/colors';




const styles = (theme) => ({
  root:{
    padding:16,
  },
  image:{
    width:72
  },
  value:{
    color: grey[700],
    fontWeight:'500'
  },
  sliderContainer:{
    width:200,
    margin:'auto',
    [theme.breakpoints.only('md')]: {
      width:120,
    },
  }
})

class HVACItem extends Component {

  render(){

    const {data, isLast, classes} = this.props

    return(
      <div className={classes.root} style={{borderRight: !isLast ? `2px solid ${grey[200]}` : null}}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ContentText text={data ? data.title : ''} medium align='center' variant='light'/>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify='center'>
              <Grid item>
                <img src={data ? data.image : null} alt='' className={classes.image}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems='center' spacing={1}>
              <Grid item xs={12} lg={6}>
                <SingleItem title='Fan' status={data.fan > 1} energy={data.fan}/>
              </Grid>
              <Grid item xs={12} lg={6}>
                <SingleItem title='Compressor' status={data.compressor > 1} energy={data.compressor}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}> 
            
          </Grid>
        </Grid>
      </div>
    )
    
  }

  onChangeSlider = (event, value) => {
    console.log(event)
    console.log(value)
  }
}

export default withStyles(styles)(HVACItem)

const SingleItem = (props) => {

  const {status, energy} = props

  return(
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ContentText text={props.title} align='center' medium variant='light'/>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction='column' spacing={1} alignItems='center'>
            <Grid item>
              <Grid container wrap='nowrap' spacing={1}>
                <Grid item>
                  <Icon style={{fontSize:14, color: status ? green[700] : red[700]}}>brightness_1</Icon>
                </Grid>
                <Grid item>
                  <ContentText text={status ? 'Active' : 'Inactive'} medium/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container wrap='nowrap' alignItems='center'>
                <Grid item>
                  <Icon style={{fontSize:18, color: amber[700]}}>flash_on</Icon>
                </Grid>
                <Grid item>
                  <ContentText text={energy.toFixed(1) + ' A'} medium/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}