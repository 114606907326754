import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'
import MapView from './Components/MapView/MapView';
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import DeviceList from './Components/DeviceList/DeviceList';

import {deviceList, initData} from './fakeData.jsx'
import { image_path } from '../../../../../variables/config';
import DivHLSPlayerModal from './Modals/HLSPlayerModal/DivHLSPlayerModal';

class ZoneMap extends Component{

  state = {
    isloading:false,
    zones:[],
    selectedTab:'ALL',
    editable:false,
    modals:{
      add_zone:{open:false,issending:false,error:null},
      warning_delete_item:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  render(){

    const {classes, history, language, onChangeImage, zone, 
      onChangeVisibility, onChangeDevicePosition, onAddDevice, onDelete, onViewDevice, onOpenHLSViewer} = this.props
    const {success, isloading, editable, zones, selectedTab} = this.state
    const content = contentData[language]

    let modalcontent = null

    //onsole.log(JSON.stringify(zones))

    const filterZones = this.onFilterData(zones, selectedTab)
    const realzones = this.onFilterData2(zone, selectedTab)

    //console.log(realzones)

    //console.log('Current')
    //console.log(filterZones)
    //console.log('Next')
    //console.log(realzones)

    let image_data = null
    if(zone){
      image_data = image_path + zone.drawing.image
      //console.log(image_data)
    }
    
    return(
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container justify='flex-end'>
              <Grid item>
                <RoundedButton  label='Add device' size='small' onClick={onAddDevice}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container  spacing={1}>
              <Grid item>
                <MapView 
                  title={zone ? zone.name : ''}
                  image={image_data}
                  zones={filterZones}
                  zone={realzones}
                  editable={editable} 
                  onChangeZones={this.onChangeZones}
                  onChangeDevicePosition={onChangeDevicePosition}
                  onChangeEditable={this.onChangeEditable}
                  onDelete={onDelete}
                  onOpenHLSViewer={onOpenHLSViewer}
                  onViewDevice={onViewDevice}
                  onChangeImage={onChangeImage}/>
              </Grid>
              <Grid item xs>
                <DeviceList selectedTab={selectedTab} zones={realzones} onChangeVisibility={onChangeVisibility} onSelected={this.onSelected} cameras={zone ? zone.cameras : []}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {/*<DivHLSPlayerModal />*/}
          </Grid>
        </Grid>
      </div>
        
      
    )
  }
  

  componentDidMount(){
    this.initZones()
    this.initModule()
  }

  initZones = () => {
    let temp = deviceList.map((item,key) => {
      return {
        id:key,
        device: {...item},
        visible: true,
        drawing:{
          x:0,
          y:0,
        }
      }
    })

    this.setState({zones:[...initData]})
  }

  onChangeZones = (data) => {
    this.setState({zones:[...data]})
  }

  initModule = async() => {
  }

  onChangeVisibility = (data) => {
    const {zones} = this.state
    let temp = [...zones]
    temp[data.id].visible = !temp[data.id].visible
    this.setState({zones:[...temp]}) 
  }

  onFilterData = (data, selector) => {
    let new_data = []

    let tab = 'ALL'
    if(selector !== 'ALL'){
      switch(selector){
        case 'MOTION':
          tab = 'Presence/Motion'
          break;
        case 'TH':
          tab = 'Temperature/Humidity'
          break;
        case 'OPEN/CLOSE':
          tab = 'Open/Close'
          break;
        case 'ENERGY':
          break;
        default:
          break;
      }
    }else{
      return data
    }

    data.forEach((item, key) => {
      if(item.device.model.name === tab){
        new_data.push({...item, id:key+1})
      }
    })

    return new_data
  }

  onFilterData2 = (data, selector) => {
    let new_data = []

    let tab = 'ALL'
    if(selector !== 'ALL'){
      switch(selector){
        case 'MOTION':
          tab = 'Presence/Motion'
          break;
        case 'TH':
          tab = 'Temperature/Humidity'
          break;
        case 'OPEN/CLOSE':
          tab = 'Open/Close'
          break;
        case 'ENERGY':
          break;
        case 'LUMMINARIES':
          tab = 'Lights'
          break;
        case 'CCTV':
          tab = 'CCTV'
          break
        default:
          break;
      }
    }

    if(data){
      //console.log(data)
      data.devices.forEach((item, key) => {
        if(tab !== 'ALL' && tab!== 'CCTV'){
          if(item.device.model.name.includes(tab)){
            new_data.push({...item, id:key+1})
          }
        }else if(tab === 'CCTV'){
          new_data = new_data
        }else{
          new_data.push({...item, id:key+1})
        }
        
      })
      if(tab === 'CCTV'){
        new_data = [...data.cameras]
      }
    }
    

    

    return new_data
  }

  onSelected = (id) => {
    this.setState({selectedTab:id})
  }

  onChangeEditable = () => {
    this.setState(prevState => {
      this.setState({editable:!prevState.editable})
    })
  }

  
}

export default withStyles(styles)(ZoneMap)