import React, {Component} from 'react'
import { withStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { SensorCard } from '../../../../../../components/DevicesCards/SensorCard';
import SimpleLoader from '../../../../../../components/Loaders/SimpleLoader';
import ContentText from '../../../../../../components/Texts/ContentText';
import { SensorDetails } from './SensorDetails';
import { SensorSettings } from './SensorSettings';
import SensorDetailsModals from './Modals/SensorDetailsModal/SensorDetailsModal'
import { request_edit_device_details, request_edit_device_settings } from './requests';
import SensorSettingsModal from './Modals/SensorSettingsModal/SensorSettingsModal';
import { InputsController } from './InputsController';
import { OutputsController } from './OutputsController';

class DigitalControllerView extends Component {

  state = {
    device: null,
    isloading:false,
    issending:false,
    modals:{
      edit_sensor_details:{open:false,issending:false,error:null},
      edit_sensor_settings:{open:false,issending:false,error:null},
      warning_delete_file:{open:false, issending:false, error:null}
    },
  }

  
  render(){

    const {classes} = this.props
    const {device, isloading, modals, issending} = this.state

    console.log(device)
    
    let contentMain = (
      <div className={classes.loaderContainer}>
        <Grid container alignItems='center' direction='column' spacing={1}>
          <Grid item>
            <SimpleLoader />
          </Grid>
          <Grid item>
            <ContentText text={'Loading...'} medium/>
          </Grid>
        </Grid>
      </div>
    )

    //let presence_data, report_data, energy_data, device_settings

    if(device){
      
    }

    if(!isloading){
      contentMain = (
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <InputsController 
              data={device} />
          </Grid>
          <Grid item xs={12} md={6}>
            <OutputsController 
              isloading={issending}
              onChange={this.onChange}
              data={device} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SensorDetails 
              data={device} 
              onEdit={() => this.onOpenModal('edit_sensor_details')}/>
          </Grid>
          <Grid item xs={12} md={6}>
            <SensorSettings data={device} onEdit={this.onOpenModal}
            onEdit={() => this.onOpenModal('edit_sensor_settings')}/>
          </Grid>
        </Grid>
      )
    }

    let modalcontent = null

    if(modals.edit_sensor_details.open){
      modalcontent = (
        <SensorDetailsModals 
          fullWidth={true}
          addSpace={true}
          origin_data={device}
          data={modals.edit_sensor_details}
          onClose={() => this.onCloseModal('edit_sensor_details')}
          onSubmit={this.onEditDeviceDetails}
        />
      )
    }
    
    

    if(modals.edit_sensor_settings.open){
      modalcontent = (
        <SensorSettingsModal 
          fullWidth={true}
          addSpace={true}
          origin_data={null}
          data={modals.edit_sensor_settings}
          onClose={() => this.onCloseModal('edit_sensor_settings')}
          onSubmit={this.onEditDeviceSettings}
        />
      )
    }

    return(
      <div>
        {modalcontent}
        {contentMain}
      </div>
    )
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {

    const {device} = this.props
    this.setState({device:{...device}})
    try {
      
    } catch (error) {
      
    }
  }

  onChange = (event, output_id) => {
    console.log(event.target.checked)
    console.log(output_id )
    let data2send = {
      digital_outputs:{
        [output_id.toString()]:event.target.checked ? 1 : 0
      }
    }
    console.log(data2send)
    this.onEditOutputController(data2send)
  }

  onEditDeviceDetails = async(data) => {
    console.log(data)
    const {match} = this.props
    const {id} = match.params
    
    const type = 'edit_sensor_details'
    try {
      this.onEditModal(type,{issending:true})
      let response
      response = await request_edit_device_details(id,data)
      console.log(response)
      await this.setState({device:{...response}})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }

  onEditDeviceSettings = async(data) => {
    console.log(data)
    const {match} = this.props
    const {id} = match.params
    
    const type = 'edit_sensor_settings'
    try {
      this.onEditModal(type,{issending:true})
      let response
      response = await request_edit_device_settings(id,data)
      console.log(response)
      await this.setState({device:{...response}})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }

  onEditOutputController = async(data) => {
    console.log(data)
    const {match} = this.props
    const {id} = match.params
    
    this.setState({issending:true})
    try {
      let response
      response = await request_edit_device_settings(id,data)
      console.log(response)
      await this.setState({device:{...response}})
    } catch (error) {
      console.log(error)
    }
    this.setState({issending:false})
  }

  onOpenModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

}

const styles = theme => ({
  loaderContainer:{
    padding:'40px'
  },
  root:{}
})

export default withStyles(styles)(DigitalControllerView)