import React from 'react'
import { IoTCard } from '../../../../../../components/Layouts/IoTCard/IoTCard';
import { DataItemWrapper } from '../../../../../../components/DisplayData/DisplayData';
import { Grid } from '@material-ui/core';

export const SensorSettings = props => {

  const {data, onEdit} = props

  let r_period, r_max

  if(data){
    r_period = data.data.period.value + ' ' + data.data.period.unit
    //r_max = data.data.max_silenced_reports.value + 0
  }

  return(
    <IoTCard title={{text:'Device settings', align:'center'}} isedit onEdit={onEdit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <DataItemWrapper 
            label='Period'
            data={r_period}
            error='No data'
          />
        </Grid>
        <Grid item xs={12} style={{height:72}}/>
      </Grid>
    </IoTCard>
  )
}