import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import GeneralView from './GeneralView/GeneralView'
import moment from 'moment'
import {request_user, request_record, request_device} from './requests'

import { IoTCard } from '../../../../components/Layouts/IoTCard/IoTCard';
import TemperatureSensorView from './TemperatureSensor/TemperatureSensorView/TemperatureSensorView';
import OpenCloseSensorView from './OpenCloseSensor/OpenCloseSensorView/OpenCloseSensorView';
import PresenceSensorView from './PresenceSensor/PresenceSensorView/PresenceSensorView';
import DigitalControllerView from './DigitalController/DigitalControllerView/DigitalControllerView';

class DeviceZoneView extends Component{

  state = {
    isloading:false,
    user:null,
    data:[{data:[]}],
    device:null
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {match} = this.props
    const {id, deviceid} = match.params

    this.setState({isloading:true})
    try{
      let response = await request_device(deviceid)
      this.setState({device:{...response}})
    }catch(e){
      console.log(e)
    }

    this.setState({isloading:false})
    

    
  }

  

  onEditUser = async() => {
    const {match} = this.props
    const id = match.params.id
    //history.push('/agents/'+id+'/edit')
  }

  onReturn = () => {
    const {history, match} = this.props
    const {id} = match.params
    history.push(`/zones/${id}`)
  }


  render(){

    const {classes, history, language, catalogs, translations, match} = this.props
    const {user, isloading, device} = this.state
    const content = contentData[language]

    let contentView = null

    if(device){
      switch (device.model.name) {
        case 'Temperature/Humidity':
          contentView = (
            <TemperatureSensorView device={device} history={history} match={match}/>
          )
          break;
        case 'Open/Close':
          contentView = (
            <OpenCloseSensorView device={device} history={history} match={match}/>
          )
          break;
        case 'Presence/Motion':
          contentView = (
            <PresenceSensorView device={device} history={history} match={match}/>
          )
          break;
        case 'Digital':
          contentView = (
            <DigitalControllerView device={device} history={history} match={match}/>
          )
          break;
      
        default:
          break;
      }
    }
    
    return(
      <BasicView
        isloading={isloading}
        history={history}
        selected={0}
        onChange={this.onChangeView}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
      >
        {contentView}
        
      </BasicView>
      
    )
  }
}

export default withStyles(styles)(DeviceZoneView)

/*requestByRangeData = () => {
    try{

      let timestamp_start = moment('2019-07-30T21:07:55.414Z')
      let timestamp_end
      let response
      for(let i=0; i<3; i++){
          console.log('///////////// DATA DATE a /////////////')
          
          timestamp_end = timestamp_start.clone()
          timestamp_end.add(1,'days')
          console.log(timestamp_start.format())
          console.log(timestamp_end.format())

          response = await request_record(id, timestamp_start.format(), timestamp_end.format())
          console.log(response.record_count)

          timestamp_start = timestamp_end.clone()
      }
      
    }catch(e){
      console.log(e)
    }
  }*/