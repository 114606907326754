import React, {Component} from 'react'
import ContentText from '../../../../components/Texts/ContentText';
import { Grid, Slider, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';




const styles = (theme) => ({
  root:{
    padding:16,
  },
  image:{
    width:28
  },
  value:{
    color: grey[700],
    fontWeight:'500'
  },
  sliderContainer:{
    width:200,
    margin:'auto',
    [theme.breakpoints.only('md')]: {
      width:120,
    },
  }
})

class EnergyItem extends Component {

  render(){

    const {data, isLast, classes} = this.props

    return(
      <div className={classes.root} style={{borderRight: !isLast ? `1px solid ${grey[400]}` : null}}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <ContentText text={data ? data.title : ''} medium align='center' variant='light'/>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify='center'>
              <Grid item>
                <img src={data ? data.image : null} alt='' className={classes.image}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h6' align='center' className={classes.value}>
              {data ? (data.value*100).toFixed(2) + '%' : ''}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.sliderContainer}>
              <PrettoSlider 
                onChange={this.onChangeSlider} 
                valueLabelDisplay="auto" 
                aria-label="pretto slider" 
                value={data ? data.value*100 : 0} 
              />
            </div>
            
          </Grid>
        </Grid>
      </div>
    )
    
  }

  onChangeSlider = (event, value) => {
    console.log(event)
    console.log(value)
  }
}

export default withStyles(styles)(EnergyItem)

const pretto_styles = (theme) => ({
  root: {
    color: theme.palette.secondary.main,
    height: 8,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -4,
    marginLeft: -8,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})

const PrettoSlider = withStyles(pretto_styles)(Slider);