export const contentData = {
  spanish:{
    layout:{
      title:'Device details',
      navigation:[
        {name:'Devices',path:'/devices'},
        {name:'Details',path:'/devices/:id'}
      ]
    },
  }
}
