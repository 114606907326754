import React from 'react'
import { IoTCard } from '../../../../components/Layouts/IoTCard/IoTCard';
import { DataItemWrapper } from '../../../../components/DisplayData/DisplayData';
import { Grid } from '@material-ui/core';

export const ZoneDetails = props => {

  const {data, onEdit} = props

  let r_name, r_identifier, r_temp, r_hum, r_occupancy, r_open

  if(data){
    r_name = data.name
    r_identifier = data.identifier
    r_temp = data.data.temperature_average.value + data.data.temperature_average.unit
    r_hum = data.data.humidity_average.value + data.data.humidity_average.unit
    if(data.data.presence_average.value !== null)
      r_occupancy = data.data.presence_average.value ? '-' : '-'
    if(data.data.opening_average.value !== null)
      r_open = data.data.opening_average.value ? '-' : '-'
  }

  return(
    <IoTCard title={{text:'Zone details', align:'center'}} isedit onEdit={onEdit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <DataItemWrapper 
            label='Name'
            data={r_name}
            error='No data'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DataItemWrapper 
            label='Description'
            data={r_identifier}
            error='No data'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DataItemWrapper 
            label='Avg. Temperature'
            data={r_temp}
            error='No data'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DataItemWrapper 
            label='Avg. humidity'
            data={r_hum}
            error='No data'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DataItemWrapper 
            label='Occupancy'
            data={r_occupancy}
            error='No data'
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DataItemWrapper 
            label='Opening devices'
            data={r_open}
            error='No data'
          />
        </Grid>
      </Grid>
    </IoTCard>
  )
}