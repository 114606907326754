import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import classNames from 'classnames';
import {
  Drawer,
  Grid,
  Typography,
  Hidden,
} from '@material-ui/core';

import MenuBar from './MenuBar/MenuBar.jsx';
import mainLogo from '../../assets/dashboard/LOGO_white.png'
import SidebarAvatar from '../Avatars/SidebarAvatar.jsx';

import { Scrollbars } from 'react-custom-scrollbars';
import ContentText from '../Texts/ContentText.jsx';

class SideBar extends Component{

  render(){
    const {classes, history, routes, user, open, onChange, onClose} = this.props;

    let level_type = 'Admin'

    let mainContent = null

    mainContent = (
      
<div className={classes.root}>
<Scrollbars autoHeightMin="100%" autoHeightMax="100%" 
renderTrackHorizontal={props => <div {...props} style={{display: 'none', overflow:'hidden'}} className="track-horizontal"/>}
renderView={props => (
  <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
)}

>
  <Grid container spacing={6}>
            <Grid item xs={12}>
              <Grid container justify='center' alignItems='center' direction='column' spacing={0}>
                <Grid item>
                  <div className={classes.mainLogoContainer}>
                    <img src={mainLogo} alt='' className={classes.mainlogo} width={64} height={64}/>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify='center'>
                <Grid item>
                  <SidebarAvatar 
                    image={user ? user.image : null}
                    name={user ? user.first_name : ''}
                    user_type={level_type}
                  />
                </Grid>
              </Grid>
              
            </Grid>
            <Grid item xs={12} style={{paddingBottom:0}}>
              <MenuBar 
                history={history} 
                routes={routes} 
                onClick={onChange}
              />
              
            </Grid>
            <Grid item xs={12} style={{height:50}}/>
            
          </Grid>
          </Scrollbars>
        </div>
      
        
      
    )

    let drawerDesktop = (
      <Hidden smDown>
        <Drawer
          variant="persistent"
          open={true}
          classes={{
            paper: classNames(classes.drawerPaper,{
              [classes.drawerPaperClose]:!open
            })
          }}
        >

          {mainContent}
          
        </Drawer>
      </Hidden>
    )

    let drawerMobile = (
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={open}
          onClose={onClose}
          classes={{
            paper: classNames(classes.drawerPaper,{
              [classes.drawerPaperClose]:!open
            })
          }}
        >
          {mainContent}
          
        </Drawer>
      </Hidden>
    )
    return(
      <div>
        {drawerDesktop}
        {drawerMobile}
      </div>
    );
  }
}

export default withStyles(styles)(SideBar);
