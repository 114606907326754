import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Card,
  Grid,
  Button,
  CircularProgress,
  withWidth,
  Typography
} from '@material-ui/core';
import {connect} from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import * as actions from '../../redux/actions.jsx';
import {SignIn as settings} from '../../variables/config.jsx';

import {RoundedButton} from '../../components/Buttons/GeneralButtons'
import InputText from '../../components/Inputs/InputText/InputText';
import {contentData} from './content'
import {formData} from './data'

const global_username = 'trionix'
const global_password = 'trionix123'

class SignIn extends Component{

  state = {
    formData: JSON.parse(JSON.stringify(formData)),
    isValid: false,
    isloading:false,
    error:null,
  }

  componentDidMount(){
    const {formData} = this.state;
    const content = contentData['spanish'];
    let temp = {...formData};
    Object.keys(formData).forEach(item => {
      temp[item].config = {...temp[item].config,...content.form[item]};
    });
    this.setState({formData:{...temp}})
  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {formData, isValid} = this.state;
    let data2Send = {};
    
    if(isValid){
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
            data2Send = {...data2Send, [item]:formData[item].value}
        }
      })
      this.onSignInHandler(data2Send)
    }
    
  }

  onSignInHandler = async(data) => {
    if(data.username === global_username && data.password === global_password){
      this.props.history.push('/')
    }else{
      this.setState({error:'Credenciales inválidas'})
    }
  }

  onKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.onSubmit()
    }
  }

  render(){
    const {classes, width} = this.props;
    const {formData, isloading, error} = this.state;
    const content = contentData['spanish']

    let textContent = null;
    if(true){  // Check language
      textContent = content.spanish;
    }



    let formContent = null;

    let selectItemsData = null;
    let contentRedirect = null;

    let errorMesage = null;
    if(isloading){
      errorMesage = (
        <CircularProgress
          size={30}
          className={classes.progress}
          style={{ color: settings.spinner.color }}
          thickness={7}
        />
      );
    } else if(error){
      errorMesage = (
        <Grid item>
          <div className={classes.errorMessage}>
            {error}
          </div>
        </Grid>
      );
    };


    let mainContent = (
      <div>
        <div className={classes.background}/>
        <div className={classes.container}>
          <Grid container direction='column' justify='center' alignItems='center'>
            <Grid item>
              <Card className={classes.card}>
                <div className={classes.cardContent}>
                  <Grid container justify='center' alignItems='center' spacing={5}>
                    <Grid item xs={12}> 
                      <Grid container spacing={1} justify='center' direction='column' alignItems='center'>
                        
                        <Grid item>
                          <img src={settings.logo} alt="CD" className={classes.ima}/>
                        </Grid>
                        <Grid item>
                          <Typography className={classes.mainTitle}>Smart BUildings</Typography>
                        </Grid>
                      </Grid>   
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.formContent}>
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <InputText
                              data={formData.username}
                              onChange={this.onInputChange}
                              onKeyPress={this.onKeyPress}
                            />
                          </Grid>
                          
                          <Grid item xs={12}>
                            <InputText
                              data={formData.password}
                              onChange={this.onInputChange}
                              onKeyPress={this.onKeyPress}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container direction='column' alignItems='center' spacing={1}>
                        <Grid item>
                          <RoundedButton 
                            label={content.button.label}
                            onClick={this.onSubmit}
                            color='secondary'
                          />
                        </Grid>
                        <Grid item xs={12} style={{height:'5px'}}>
                          {errorMesage}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
    );




    return(
      <div className={classes.root}>
        {contentRedirect}
        {mainContent}
      </div>
    );
  }

  
}



export default withRouter((withStyles(styles)(withWidth()(SignIn))));
