import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import SimpleCard from '../../../../components_v1/Structure/Cards/SimpleCard';
import LoadingContainer from '../../../../components_v1/Structure/Layouts/LoadingContainer';
import DisplayText from '../../../../components_v1/Texts/DisplayText';

const DetailInfoCard = ({loading, id, power, technology, eui, modelType, modelName}) => {
    return ( 
        <LoadingContainer loading={loading}>
            <div>
                <SimpleCard>
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DisplayText variant='subtitle1' color='primary'>Información técnica</DisplayText>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <DisplayData label='Identifier' data={id}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DisplayData label='Power source' data={power}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DisplayData label='Technology' data={technology}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DisplayData label='EUI' data={eui}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DisplayData label='Type' data={modelType}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <DisplayData label='Category' data={modelName}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </SimpleCard>
            </div>
        </LoadingContainer>
        
     );
}
 
export default DetailInfoCard;

const DisplayData = ({label, data}) => {
    return(
        <Grid container>
            <Grid item xs={12}>
                <DisplayText variant='subtitle2' color='textSecondary'>{label}</DisplayText>
            </Grid>
            <Grid item xs={12}>
                <DisplayText variant='body1' >{data}</DisplayText>
            </Grid>
        </Grid>
    )
}