import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'




import {request_delete_user, request_zones, request_add_zone, request_delete_zone} from './requests'

import EnergyChart from './components/EnergyChart';
import EnergyGauge from './components/EnergyGauge';
import { IoTCard } from '../../../../components/Layouts/IoTCard/IoTCard';
import {fakedata} from './fakedata'
import DeviceList from './DeviceList/DeviceList';
import EnergyConsumption from './components/EnergyConsumption';
import ElectricBoard from './components/ElectricBoard';
import EnergyGroup from './components/EnergyGroup';

class Energys extends Component{

  state = {
    isloading:false,
    users:[],
    zones:[],
    filter:null,
    selected_zone:null,
    tableConfig:{
      filter:{
        user_type:null,
        status:null,
        general:null
      },
      searchText:'',
      page:0,
      rowsPerPage:5,
      totalItems:0
    },
    modals:{
      add_zone:{open:false,issending:false,error:null},
      warning_delete_item:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  render(){

    const {classes, history, language,translations} = this.props
    const {users, zones, modals, success, filter, tableConfig, isloading} = this.state
    const content = contentData[language]

    console.log(fakedata.energy_table.table)
    
    return(
      <BasicView
        history={history}
        content={content.layout}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar} 
        isloading={isloading}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <IoTCard title={{text:'Energy Cost', align:'center'}}>
              <div style={{paddingTop:11}}/>
              <EnergyGauge />
            </IoTCard>
          </Grid>
          <Grid item xs={12} md={8}>
            <IoTCard title={{text:'Energy Chart', align:'center'}}>
              <EnergyChart />
            </IoTCard>
          </Grid>
          <Grid item xs={12} md={8}>
              <DeviceList 
                header={fakedata.energy_table.header}
                data={fakedata.energy_table.table}
                filter={null}
                filters={[]}
                master_filters={[]}
                config={tableConfig}
                onChangeFilter={this.onChangeFilter}
              />
          </Grid>
          <Grid item xs={12} md={4}>
            <div style={{marginTop:16}}>
              <IoTCard title={{text:'Energy Consuption', align:'center'}}>
                <EnergyConsumption/>
              </IoTCard>
            </div>
          </Grid>
          <Grid item xs={12} md={12}>
            <IoTCard title={{text:'Main electric board', align:'center'}}>
              <ElectricBoard/>
            </IoTCard>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {fakedata.energy_groups.map((item,key)=>{
                return(
                  <Grid item xs={12} md={6} key={key.toString()}>
                    <EnergyGroup data={item}/>
                  </Grid>
                )
              })}
              
            </Grid>
          </Grid>
        </Grid>
        
        
      </BasicView>
      
    )
  }

  componentDidMount(){
    //this.initModule()
  }

  initModule = async() => {
    this.setState({isloading:true})
    try{
      let response
      const {tableConfig} = this.state
      const url = this.getUrlWithFilters2(tableConfig)
      response = await request_zones(url.url, url.params)
      let temp = {...tableConfig}
      temp.totalItems = response.zone_count
      this.setState({tableConfig:temp, zones:[...response.zones],})

      console.log(response)
    }catch(e){
      console.log(e)
    }
    this.setState({isloading:false})
  }

  onPreDeleteZone = (id) => {
    console.log(id)
    this.setState({selected_zone:id})
    this.onOpenModal('warning_delete_item')
  }

  onDeleteZone = async() => {
    const {match} = this.props
    const {id} = match.params
    const {selected_zone} = this.state
    
    const type = 'warning_delete_item'
    try {
      this.onEditModal(type,{issending:true})
      let response
      response = await request_delete_zone(selected_zone)
      console.log(response)
      const {tableConfig} = this.state
      const url = this.getUrlWithFilters2(tableConfig)
      response = await request_zones(url.url, url.params)
      let temp = {...tableConfig}
      temp.totalItems = response.zone_count
      this.setState({tableConfig:temp, zones:[...response.zones],})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }

  onAddZone = async(data) => {
    let data2send = {...data, devices:[]}
    console.log(data2send)
    
    const type = 'add_zone'
    try {
      this.onEditModal(type,{issending:true})
      let response
      await request_add_zone(data2send)

      /** REQUEST ALL AGAIN */
      const {tableConfig} = this.state
      const url = this.getUrlWithFilters2(tableConfig)
      response = await request_zones(url.url, url.params)
      let temp = {...tableConfig}
      temp.totalItems = response.zone_count
      this.setState({tableConfig:temp, zones:[...response.zones],})


      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }



  getUrlWithFilters2 = (tableConfig) => {
    const {page, rowsPerPage} = tableConfig

    let url = '/list'
    let params = {limit:rowsPerPage,offset:page*rowsPerPage}

    return {
      url:url,
      params: {...params}
    }
  }

  onChangeTableConfig = async(data) => {
    const payload = this.getUrlWithFilters2(data)
    let response = await request_zones(payload.url, payload.params)

    let temp = {...data}
    if(data.filter.general !== undefined && data.filter.general !== null){
      if(data.filter.general.id !== null){
        temp.isSearch = true
      }else{
        temp.isSearch = false
      }
    }else{
      temp.isSearch = false
    }
    temp.totalItems = response.zone_count
    this.setState({tableConfig:temp, zones:[...response.zones],})
  }

  onChangeFilter = (item) => {
    this.setState({filter:item.id})
  }

  onPatientSelected = async(id) => {
    const {history} = this.props
    history.push('/zones/'+id)
  }

  onAddPatient = async() => {
    const {history} = this.props
    history.push('/zones/add')

  }

  onPreDeleteUser = (id) => {
    const type = 'warning_delete_item'
    this.onEditModal(type, {open:true, id:id})
  }

  

  onDeleteUser = async(id) => {
    const type = 'warning_delete_item'
    try {
      this.onEditModal(type, {issending:true})
      let response = await request_delete_user(id)
      this.setState({users:[...response]})
      this.onEditModal(type, {open:false,issending:false, error:null})
      this.onActivateSuccessSnack()
    } catch (error) {
      if(error.response){
        this.onEditForm(type,{issending:false, error:error.response.data.error.message})
      }
    }
  }

  onOpenModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }
}

export default withStyles(styles)(Energys)