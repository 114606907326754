import React, {Component} from 'react'
import { 
  withStyles,
} from '@material-ui/core/styles'
import {   
  Dialog, 
  DialogActions,
  DialogContent,
  Icon,
  Slide ,
  Grid,
  Typography} from '@material-ui/core'
import {RoundedButton} from '../Buttons/GeneralButtons'
import {ErrorMessage} from '../DisplayData/DisplayData'
import SimpleLoader from '../Loaders/SimpleLoader'
import {grey, red} from '@material-ui/core/colors'

const styles = (theme) => ({
  container:{
    padding:'16px 0px'
  },
  icon:{
    color:red[700],
    fontSize:54
  },
  title:{
    color:grey[900],
    fontWeight:'500'
  },
  message:{
    color:grey[700],
    fontWeight:'400'
  }
})

class WarningModal extends Component {
  render(){

    const {classes, data, onClose, content, onDelete} = this.props

    const {open, issending, error, id} = data

    let messageContent = null
    if(issending){
      messageContent = <SimpleLoader />
    }else if(error){
      messageContent = <ErrorMessage message={error}/>
    }

    return(
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth='xs'
      >
        <DialogContent>
          <div className={classes.container}>
            <Grid container spacing={2} direction='column' alignItems='center'>
              <Grid item>
                <Grid container direction='column' alignItems='center' spacing={1}>
                  <Grid item>
                    <Icon fontSize='large' className={classes.icon}>cancel</Icon>
                  </Grid>
                  <Grid item>
                    <Typography variant='h6' className={classes.title}>
                      {content.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant='body2' align='center' className={classes.message}>
                      {content.message}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {messageContent}
              </Grid>
            </Grid>
          </div>
          
        </DialogContent>
        <DialogActions>
          <RoundedButton
            size='small'
            label={content.cancelButton}
            color='grey'
            onClick={onClose}
          />
          <RoundedButton
            size='small'
            label={content.deleteButton}
            color='red'
            onClick={() => onDelete(id)}
          />
        </DialogActions>
        


      </Dialog>
    )
  }
}

export default withStyles(styles)(WarningModal)

