import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../components/Layouts/BasicView/BasicView'
//import AgentList from './AgentList/AgentList'
import WarningModal from '../../../components/Modals/WarningModal'


import {request_users, request_delete_user} from './requests'
import {cleanData2Table} from './customFunctions'
import { IoTCard } from '../../../components/Layouts/IoTCard/IoTCard';
import { fakeData } from './fakedata';
import EnergyItem from './components/EnergyItem';
import SensorItem from './components/SensorItem';
import HVACItem from './components/HVACItem';
import EnergyGauge from './components/EnergyGauge';
import EnergyChart from './components/EnergyChart';

class Dashboard extends Component{

  state = {
    isloading:false,
    users:[],
    filter:null,
    tableConfig:{
      filter:{
        user_type:null,
        status:null,
        general:null
      },
      searchText:'',
      page:0,
      rowsPerPage:5,
      totalItems:0
    },
    modals:{
      warning_delete_user:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  render(){

    const {classes, history, language,translations} = this.props
    const {users,  modals, success, filter, tableConfig} = this.state
    const content = contentData[language]

    const data_users = cleanData2Table(users,content.nodata,translations)


    let modalContent = null

    if(modals.warning_delete_user){
      modalContent=(
        <WarningModal 
          data={modals.warning_delete_user}
          content={content.modals.warning_modal}
          onClose={() => this.onCloseModal('warning_delete_user')}
          onDelete={this.onDeleteUser}
        />
      )
    }
    
    return(
      <BasicView
        history={history}
        content={content.layout}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar} 
      >
        {modalContent}
        <Grid container spacing={4}>
          <Grid item xs={12} md={9}>
            <IoTCard title={{text:'Energy', align:'center'}}>
              <Grid container>
                {fakeData.energyCards.map((item,key)=>{
                  return(
                    <Grid item xs={12} md={4} key={key.toString()}>
                      <EnergyItem data={item} isLast={key === fakeData.energyCards.length - 1 }/>
                    </Grid>
                  )
                })}
              </Grid>
            </IoTCard>
          </Grid>
          <Grid item xs={12} md={3}>
            <IoTCard title={{text:'Sensing', align:'center'}}>
              <Grid container spacing={2}>
                {fakeData.sensorCards.map((item,key)=>{
                  return(
                    <Grid item xs={12} key={key.toString()}>
                      <Grid container justify='center'>
                        <Grid item>
                          <SensorItem data={item}/>
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </IoTCard>
          </Grid>
          <Grid item xs={12}>
            <IoTCard title={{text:'Fan & Coil', align:'center'}}>
              <Grid container spacing={2}>
                {fakeData.hvacCards.map((item,key)=>{
                  return(
                    <Grid item xs={6} md={3} key={key.toString()}>
                      <Grid container justify='center'>
                        <Grid item xs={12}>
                          <HVACItem 
                            data={item} 
                            isLast={key === fakeData.hvacCards.length - 1 }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
            </IoTCard>
          </Grid>
          <Grid item xs={12} md={4}>
            <IoTCard title={{text:'Energy Cost', align:'center'}}>
              <EnergyGauge />
            </IoTCard>
          </Grid>
          <Grid item xs={12} md={8}>
            <IoTCard title={{text:'Energy Chart', align:'center'}}>
              <EnergyChart />
            </IoTCard>
          </Grid>
        </Grid>
        
      </BasicView>
      
    )
  }

  componentDidMount(){
    const data = JSON.parse(localStorage.getItem('data')) 
    if(data) this.initModule()
  }

  initModule = async() => {
    /*const {tableConfig} = this.state
    this.setState({isloading:true,})
    const payload = this.getUrlWithFilters2(tableConfig)
    let response = await request_users(payload.url, payload.params)
    let temp = {...this.state.tableConfig}
    temp.totalItems = respons0e.count
    this.setState({users:[...response.users], tableConfig:temp})
    this.setState({isloading:false})*/
  }

  getUrlWithFilters2 = (tableConfig) => {
    const {filter, searchText, page, rowsPerPage} = tableConfig

    let url = '/all'
    let url_empty = '/all'
    let url_general = ''
    let url_type = ''
    let params = {limit:rowsPerPage,offset:page*rowsPerPage}
    if(filter.user_type){
      if(filter.user_type.id !== null && filter.user_type.id !== undefined){
        url_type = '/type/' + filter.user_type.id
      }
    }
    if(filter.general){
      if(filter.general.id !== null && filter.general.id !== undefined){
        if(searchText !== '' && searchText !== null){
          url_general += '/' + filter.general.url
          params = {...params, [filter.general.url]:searchText}
        }
      }
    }

    if(filter.status){
      if(filter.status.id !== null && filter.status.id !== undefined){
        params = {...params, status:filter.status.id}
      }
    }

    if(url_type !== '' || url_general !== ''){
      url_empty = ''
    }

    url = url_empty + url_type + url_general

    return {
      url:url,
      params: {...params}
    }
  }

  onChangeTableConfig = async(data) => {
    const payload = this.getUrlWithFilters2(data)
    let response = await request_users(payload.url, payload.params)

    let temp = {...data}
    if(data.filter.general !== undefined && data.filter.general !== null){
      if(data.filter.general.id !== null){
        temp.isSearch = true
      }else{
        temp.isSearch = false
      }
    }else{
      temp.isSearch = false
    }
    temp.totalItems = response.count
    this.setState({tableConfig:temp, users:[...response.users],})
  }

  onChangeFilter = (item) => {
    this.setState({filter:item.id})
  }

  onPatientSelected = async(id) => {
    const {history} = this.props
    history.push('/dashboard/'+id)
  }

  onAddPatient = async() => {
    const {history} = this.props
    history.push('/dashboard/add')

  }

  onPreDeleteUser = (id) => {
    const type = 'warning_delete_user'
    this.onEditModal(type, {open:true, id:id})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onDeleteUser = async(id) => {
    const type = 'warning_delete_user'
    try {
      this.onEditModal(type, {issending:true})
      let response = await request_delete_user(id)
      this.setState({users:[...response]})
      this.onEditModal(type, {open:false,issending:false, error:null})
      this.onActivateSuccessSnack()
    } catch (error) {
      if(error.response){
        this.onEditForm(type,{issending:false, error:error.response.data.error.message})
      }
    }
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }
}
 
export default withStyles(styles)(Dashboard)