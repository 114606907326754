import React, {Component} from 'react'
import {withRouter} from 'react-router'
import { Grid, Breadcrumbs, Link, Typography, Icon, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import cx from  'classnames'

const styles = (theme) => ({
  title:{
    color:theme.palette.secondary.dark,
  },
  link:{
    '&:hover':{
      cursor:'pointer',
      textDecoration:'none'
    }
  },
  selectedLink:{
    color:theme.palette.primary.main,
    fontWeight:'500'
  },
  iconbutton:{
    padding:8
  }
})

class NavigationTitle extends Component {

  onNavigation = (path) => {
    let temp = path
    const {history} = this.props
    if(path.includes(':id')){
      const {match} = this.props
      const id = match.params.id
      temp = path.replace(':id', id)
    } 
    history.push(temp)
  }

  render(){

    const {classes, title, navigation, returnBtn, onReturn} = this.props;

    let navigationContent = null
    let returnContent = null

    if(navigation){
      navigationContent = navigation.map((item,key)=> {
        return(
          <Link 
            className={cx({
              [classes.link]:true,
              [classes.selectedLink]:key===navigation.length-1,
            })}
            onClick={() => this.onNavigation(item.path)} 
            key={key.toString()}
          >
            {item.name}
          </Link>
        )
      })
    }

    if(returnBtn){
      returnContent = (
        <Grid item>
          <IconButton 
            className={classes.iconbutton} 
            color='primary'
            onClick={onReturn}
          >
            <Icon>arrow_back</Icon>
          </IconButton>
        </Grid>
      )
    }

    return(
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container alignItems='center' spacing={2}>
              {returnContent}
              <Grid item xs>
                <Typography variant='h5' className={classes.title}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Breadcrumbs>
              {navigationContent}
            </Breadcrumbs>
          </Grid>
        </Grid>
      </div>
    )
  }
  
}

export default withRouter(withStyles(styles)(NavigationTitle))