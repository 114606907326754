import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'



import {request_zones, request_add_zone, request_delete_camera, request_cameras, request_add_camera} from './requests'
import { Grid } from '@material-ui/core';
import CCTVList from './CCTVList/CCTVList';
import WarningModal from '../../../../components/Modals/WarningModal';
import AddCameraModal from './Modals/AddCameraModal/AddCameraModal';

class CCTVs extends Component{

  state = {
    isloading:false,
    users:[],
    zones:[],
    cameras:[],
    filter:null,
    tableConfig:{
      filter:{
        user_type:null,
        status:null,
        general:null
      },
      searchText:'',
      page:0,
      rowsPerPage:5,
      totalItems:0
    },
    modals:{
      add_camera:{open:false,issending:false,error:null},
      warning_delete_item:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  render(){

    const {classes, history, language,translations} = this.props
    const {cameras, zones, modals, success, filter, tableConfig, isloading} = this.state
    const content = contentData[language]

    //console.log(cameras)

    let modalContent = null

    if(modals.warning_delete_item){
      modalContent=(
        <WarningModal 
          data={modals.warning_delete_item}
          content={content.modals.warning_delete_item}
          onClose={() => this.onCloseModal('warning_delete_item')}
          onDelete={this.onDeleteCamera}
        />
      )
    }

    if(modals.add_camera.open){
      modalContent = <AddCameraModal 
        data={modals.add_camera}
        onClose={() => this.onCloseModal('add_camera')}
        onSubmit={this.onAddCamera}
      />
    }
    
    return(
      <BasicView
        history={history}
        content={content.layout}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar} 
        isloading={isloading}
      >
        {modalContent}
        <Grid container>
          <Grid item xs={12}>
            <CCTVList 
               filter={filter}
               filters={content.filters}
               master_filters={content.superfilters}
               config={tableConfig}
               header={content.header}
               data={cameras}
               onItemSelected={this.onItemSelected}
               onDeleteItem={this.onPreDeleteItem}
               onAddItem={() => this.onOpenModal('add_camera')}
            />
          </Grid>
        </Grid>
        
        
      </BasicView>
      
    )
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    this.setState({isloading:true})
    try{
      await this.onRequestCameras()
    }catch(e){
      console.log(e)
    }
    this.setState({isloading:false})
  }

  onItemSelected = (id) => {
    console.log(id)
    this.props.history.push(`/cctv/${id}`)
  }

  onPreDeleteItem = (id) => {
    this.setState({selected_camera:id})
    this.onOpenModal('warning_delete_item')
  }

  onPreDeleteZone = (id) => {
    this.setState({selected_zone:id})
    this.onOpenModal('warning_delete_item')
  }

  onDeleteCamera = async() => {
    const {selected_camera} = this.state
    const type = 'warning_delete_item'
    try {
      this.onEditModal(type,{issending:true})
      await request_delete_camera(selected_camera)
      await this.onRequestCameras()
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }

  onAddCamera = async(data) => {
    console.log(data)
    const type = 'add_camera'
    try {
      this.onEditModal(type,{issending:true})
      await request_add_camera(data)
      await this.onRequestCameras()
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }

  onRequestCameras = async() => {
    const {tableConfig} = this.state
    let response
    response = await request_cameras()
    let temp = {...tableConfig}
    temp.totalItems = response.camera_count
    this.setState({tableConfig:temp, cameras:[...response.cameras]})
  }

  onAddZone = async(data) => {
    let data2send = {...data, devices:[]}
    console.log(data2send)
    
    const type = 'add_zone'
    try {
      this.onEditModal(type,{issending:true})
      let response
      await request_add_zone(data2send)

      /** REQUEST ALL AGAIN */
      const {tableConfig} = this.state
      const url = this.getUrlWithFilters2(tableConfig)
      response = await request_zones(url.url, url.params)
      let temp = {...tableConfig}
      temp.totalItems = response.zone_count
      this.setState({tableConfig:temp, zones:[...response.zones],})


      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }



  getUrlWithFilters2 = (tableConfig) => {
    const {page, rowsPerPage} = tableConfig

    let url = '/list'
    let params = {limit:rowsPerPage,offset:page*rowsPerPage}

    return {
      url:url,
      params: {...params}
    }
  }

  onChangeTableConfig = async(data) => {
    const payload = this.getUrlWithFilters2(data)
    let response = await request_zones(payload.url, payload.params)

    let temp = {...data}
    if(data.filter.general !== undefined && data.filter.general !== null){
      if(data.filter.general.id !== null){
        temp.isSearch = true
      }else{
        temp.isSearch = false
      }
    }else{
      temp.isSearch = false
    }
    temp.totalItems = response.zone_count
    this.setState({tableConfig:temp, zones:[...response.zones],})
  }

  onChangeFilter = (item) => {
    this.setState({filter:item.id})
  }

  onPatientSelected = async(id) => {
    const {history} = this.props
    history.push('/zones/'+id)
  }

  onAddPatient = async() => {
    const {history} = this.props
    history.push('/zones/add')

  }

  onPreDeleteUser = (id) => {
    const type = 'warning_delete_item'
    this.onEditModal(type, {open:true, id:id})
  }

  onOpenModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }
}

export default withStyles(styles)(CCTVs)