import React, {Component} from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core';
import ContentText from '../../../../../components/Texts/ContentText';
import { grey } from '@material-ui/core/colors';
import ReactApexChart from 'react-apexcharts';

class EnergyGauge extends Component {

  state = {
    options: {
      chart: {
        offsetY: -10
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: '16px',
              fontWeight:'700',
              offsetY: 120
            },
            value: {
              offsetY: 76,
              fontSize: '22px',
              fontWeight:'600',
              color: grey[700],
              formatter: function (val) {
                return val + "kWH";
              }
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 65, 91]
        },
      },
      stroke: {
        dashArray: 4
      },
      labels: ['Avg. Consumption']
    },
    series: [36],
  }


  render(){

    const {classes} = this.props

    return(
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ContentText text='Avg. Price' medium variant='light' align='center'/>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='h5' align='center' className={classes.text}>
                {'$18,568.31'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.root}>
            <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height="300" />
          </div>
        </Grid>
        
      </Grid>
    )
  }
}

const styles = (theme) => ({
  root:{
    width:300,
    margin:'auto',
    [theme.breakpoints.down('sm')]: {
      width:300
    },
    [theme.breakpoints.only('md')]: {
      width:200
    },
  },
  text:{
    color:grey[700],
    fontWeight:'600'
  }
})

export default withStyles(styles)(EnergyGauge)