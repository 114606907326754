import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography, Avatar } from '@material-ui/core'

import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper'
import {DataItemWrapper, TableItem} from '../../../../../components/DisplayData/DisplayData'
import {RoundedButton} from '../../../../../components/Buttons/GeneralButtons'
import ProfileAvatar from '../../../../../components/Avatars/ProfileAvatar'

import {formData} from './data'
import {contentData} from './content'
import { green, red } from '@material-ui/core/colors';
import moment from 'moment'
import InfoButton from '../../../../../components/Buttons/InfoButton';

const styles = (theme) => ({
  root:{padding:'32px'}
})



class GeneralView extends Component {

  updateForm = (data, user, content, catalogs) => {
    const {translations} = this.props
    let form = {}
    Object.keys(data).forEach((item,key)=> {
      let value = null
      if(user){
        value = user[item]
        if(item === 'gender_id'){
          if(value)
          value = catalogs.user_genders[value-1].label
        }
        if(item === 'user_type_id'){
          if(value)
          value = catalogs.user_types[value-1].label
        }
      }
      const aux = {...data[item], ...content[item], data:value}
      form = {...form, [item]:{...aux}}
    })
    return form
  }

  render(){

    const {classes, catalogs, language, user, onEdit} = this.props
    const content = contentData[language]

    let formA = {...formData.sectionA}

    let contentA = null
    let avatarContent = null

 
    formA = this.updateForm(formA, user, content.sectionA, catalogs)

    contentA = Object.keys(formA).map((item,key)=> {
      if(item !== 'status'){
        return(
          <Grid item xs={12} md={6} key={key.toString()}>
            <DataItemWrapper 
              label={formA[item].label}
              data={formA[item].data}
              error={content.error}
              icon={formA[item].icon}
            />
          </Grid>
        )
      }
      return null
      
    })
    avatarContent = <ProfileAvatar image={user ? user.image : null}/>

    let infoButtonData = []
    const nodata = 'Sin Registro'
    if(user){
      infoButtonData = [
        {
          title:'Detalles de creación',
          content:[
            {label:'Nombre: ', text: user.creator ? user.creator : nodata},
            {label:'Fecha: ', text: user.created_at ?  moment(user.created_at).format("DD/MM/YYYY") : nodata},
          ]
        },
        {
          title:'Detalles de actualización',
          content:[
            {label:'Nombre: ', text: user.updater ? user.updater : nodata},
            {label:'Fecha: ', text: user.updated_at ?  moment(user.updated_at).format("DD/MM/YYYY") : nodata},
          ]
        },
      ]
    }

    return(
      <Grid container spacing={3}>
      <Grid item xs={12}>
      <CardWrapper>
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Grid container spacing={2} direction='column' justify='center' alignItems='center'>
                <Grid item>{avatarContent}</Grid>
                <Grid item>
                  <InfoButton data={infoButtonData}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {contentA}
                    <Grid item xs={12} md={6}>
                      <TableItem 
                        label={formA.status.label}
                        icon='brightness_1'
                        iconSize={12}
                        iconColor={formA.status.data ? green[700] : red[700]}
                        data={formA.status.data ? 'Activa' : 'Inactiva'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justify='flex-end'>
                <Grid item>
                  <RoundedButton label='Editar' size='small' color='secondary' onClick={onEdit}/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CardWrapper>
      </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(GeneralView)