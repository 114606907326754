export const cleanData2Table = (data, nodata ) => {
  const table = data.map(item => {
    
    let temp = {
      id:item._id, 
      item: {...item},
      data:[]
    }

    return temp
  })

  return table
}