import energy from '../../../assets/dashboard/lightning.png'
import airconditioner from '../../../assets/dashboard/airconditioner.png'
import lights from '../../../assets/dashboard/bulb.png'
import contacts from '../../../assets/dashboard/contacts.png'
import temperature from '../../../assets/dashboard/thermometer.png'
import humidity from '../../../assets/dashboard/humidity.png'
import hvac from '../../../assets/dashboard/HVAC.png'



export const fakeData = {
  energyCards:[
    {
      title:'Lights',
      value:0.281,
      unit:'percentage',
      image:lights,
      type:'energy',
      url:'/building'
    },
    {
      title:'Air conditioner',
      value:0.623,
      unit:'percentage',
      image:airconditioner,
      type:'energy',
      url:'/building'
    },
    {
      title:'Contacts',
      value:0.096,
      unit:'percentage',
      image:contacts,
      type:'energy',
      url:'/building'
    },
  ],
  sensorCards:[
    {
      title:'Avg. Temperature',
      value:25.3,
      image:temperature,
      unit:'°C',
      type:'sensor',
      url:'/building'
    },
    {
      title:'Avg. Humidity',
      value:34,
      image:humidity,
      unit:'%',
      type:'sensor',
      url:'/building'
    },
  ],
  hvacCards:[
    {
      title:'Zone A',
      fan:5.0 + (Math.random() * 2 - 1 ),
      compressor:8.0  + (Math.random() * 2 - 1 ),
      image:hvac,
      url:'/building'
    },
    {
      title:'Zone B',
      fan:5.0 + (Math.random() * 2 - 1 ),
      compressor:0 + (Math.random() * 1 ),
      image:hvac,
      url:'/building'
    },
    {
      title:'Zone C',
      fan:5.0 + (Math.random() * 2 - 1 ),
      compressor:8.0  + (Math.random() * 2 - 1 ),
      image:hvac,
      url:'/building'
    },
    {
      title:'Zone D',
      fan:5.0 + (Math.random() * 2 - 1 ),
      compressor:8.0  + (Math.random() * 2 - 1 ),
      image:hvac,
      url:'/building'
    },
  ]
}