export const deviceList = [
  {
    id:1,
    name:'TH-001',
    identifier:'Zone A',
    model:{
      type:'Sensor',
      name:'Temperature/Humidity' // Temperature/Humidity // Presence/Motion // Open/Close
    },
    data:{
      temperature:{
        value:23.5,
        unit:'°C',
      },
      humidity:{
        value:33.6,
        unit:'%RH',
      }
    }
  },
  {
    id:2,
    name:'PR-001',
    identifier:'Zone A',
    model:{
      type:'Sensor',
      name:'Presence/Motion' // Temperature/Humidity // Presence/Motion // Open/Close
    },
    data:{
      presence:{
        value:0,
        unit:'',
      },
    }
  },
  {
    id:3,
    name:'OP-001',
    identifier:'Zona A',
    model:{
      type:'Sensor',
      name:'Open/Close' // Temperature/Humidity // Presence/Motion // Open/Close
    },
    data:{
      opening:{
        value:0,
        unit:'',
      },
    }
  },
  {
    id:4,
    name:'TH-002',
    identifier:'Zone B',
    model:{
      type:'Sensor',
      name:'Temperature/Humidity' // Temperature/Humidity // Presence/Motion // Open/Close
    },
    data:{
      temperature:{
        value:23.5,
        unit:'°C',
      },
      humidity:{
        value:33.6,
        unit:'%RH',
      }
    }
  },
  {
    id:5,
    name:'PR-002',
    identifier:'Zone B',
    model:{
      type:'Sensor',
      name:'Presence/Motion' // Temperature/Humidity // Presence/Motion // Open/Close
    },
    data:{
      presence:{
        value:0,
        unit:'',
      },
    }
  },
  {
    id:6,
    name:'OP-002',
    identifier:'Zona B',
    model:{
      type:'Sensor',
      name:'Open/Close' // Temperature/Humidity // Presence/Motion // Open/Close
    },
    data:{
      opening:{
        value:0,
        unit:'',
      },
    }
  },
  
]

export const initData = [{"id":0,"device":{"id":1,"name":"TH-001","identifier":"Zone A","model":{"type":"Sensor","name":"Temperature/Humidity"},"data":{"temperature":{"value":23.5,"unit":"°C"},"humidity":{"value":33.6,"unit":"%RH"}}},"visible":true,"drawing":{"x":0.43,"y":0.5503703703703704}},{"id":1,"device":{"id":2,"name":"PR-001","identifier":"Zone A","model":{"type":"Sensor","name":"Presence/Motion"},"data":{"presence":{"value":0,"unit":""}}},"visible":true,"drawing":{"x":0.12666666666666668,"y":0.38592592592592595}},{"id":2,"device":{"id":3,"name":"OP-001","identifier":"Zona A","model":{"type":"Sensor","name":"Open/Close"},"data":{"opening":{"value":0,"unit":""}}},"visible":true,"drawing":{"x":0.6872222222222222,"y":0.7511111111111111}},{"id":3,"device":{"id":4,"name":"TH-002","identifier":"Zone B","model":{"type":"Sensor","name":"Temperature/Humidity"},"data":{"temperature":{"value":23.5,"unit":"°C"},"humidity":{"value":33.6,"unit":"%RH"}}},"visible":true,"drawing":{"x":0.4716666666666667,"y":0.19333333333333333}},{"id":4,"device":{"id":5,"name":"PR-002","identifier":"Zone B","model":{"type":"Sensor","name":"Presence/Motion"},"data":{"presence":{"value":0,"unit":""}}},"visible":true,"drawing":{"x":0.26722222222222225,"y":0.22}},{"id":5,"device":{"id":6,"name":"OP-002","identifier":"Zona B","model":{"type":"Sensor","name":"Open/Close"},"data":{"opening":{"value":0,"unit":""}}},"visible":true,"drawing":{"x":0.8083333333333333,"y":0.3451851851851852}}]