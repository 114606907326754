export const contentData = {
  spanish:{
    layout:{
      title:'Camera details',
      navigation:[
        {name:'Cameras',path:'/cctv'},
        {name:'Details',path:'/cctv/:id'}
      ],
      menu:[
        {id:1,label:'General'},
        {id:2,label:'Records'},
      ]
    },
  }
}
