import {mainServer} from '../../../../variables/config'

export const request_users = async (url, params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/user"+url, {params:{...params}})
  return response.data.data
}

export const request_delete_user = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete("/users/"+id)
  let response
  response = await mainServer.instance.get("/users/type/1")
  return response.data.data
}


export const request_welcome = async (url, params) => {
  let response
  response = await mainServer.instance.get("/welcome")
  console.log(response.data.message)
}

export const request_zones = async (url, params) => {
  console.log("/zone"+url)
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  let response = await mainServer.instance.get("/zone"+url, {params:{...params}})
  return response.data.data
}


export const request_add_zone = async (data) => {
  console.log("/zone")
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  let response = await mainServer.instance.post("/zone",data)
  console.log(response.data.data.zone_id)

}

export const request_delete_zone = async (id) => {
  console.log("/zone")
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  let response = await mainServer.instance.delete("/zone/"+id)
  console.log(response.data)

}

