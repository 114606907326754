export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    layout:{
      title:'Alarms',
      navigation:[
        {name:'Alarms',path:'/alarms'}
      ],
      menu2:[
        {id:0, label:'Mis datos'},
        {id:1, label:'Horario'},
      ],
      
    },
    filters:[
      {id:0, label:'Nombre', url:'name'},
      {id:1, label:'Usuario', url:'username'},
      {id:2, label:'E-mail', url:'email'},
      {id:null, label:'Sin Filtro', url:null},
    ],
    superfilters:[],
    statusFilters:[
      {id:0, label:'Activo'},
      {id:1, label:'Inactivo'},
      {id:null, label:'Sin Filtro', url:null},
    ],
    usertypeFilters:[
      {id:1, label:'Admin'},
      {id:2, label:'Roche'},
      {id:3, label:'Mercurio'},
      {id:4, label:'Auditor'},
      {id:null, label:'Sin Filtro', url:null},
    ],

    modals:{
      warning_modal:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    },
    header:['Name','Identifier','# Devices','Avg. Temperature','Avg. Humidity','Avg. Opening','Avg. Presence', 'Actions']
  }
}