import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Icon } from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import GeneralView from './GeneralView/GeneralView'
import moment from 'moment'
import {request_add_camera, request_cameras, request_zone, request_devices, request_add_device, 
  request_delete_device, request_edit_zone, request_upload_image, request_update_zone_device, request_update_zone_camera} from './requests'

import { IoTCard } from '../../../../components/Layouts/IoTCard/IoTCard';
import { RoundedButton } from '../../../../components/Buttons/GeneralButtons';
import AddDeviceModal from './Modals/AddDeviceModal/AddDeviceModal';
import { cleanData2Table, setData2Table } from './customFunctions';
import DeviceList from './DeviceList/DeviceList';
import WarningModal from '../../../../components/Modals/WarningModal';
import { ZoneDetails } from './ZoneDetails';
import EditZoneModal from './Modals/EditZoneModal/EditZoneModal';
import ZoneMap from './ZoneMap/ZoneMap';
import ZoneImageModal from '../../../../components/Modals/ZoneImageModal/ZoneImageModal';
import InfoModal from '../../../../components/Modals/InfoModal';
import { lightsList } from './ZoneMap/fakeData';
import HLSPlayerModal from './ZoneMap/Modals/HLSPlayerModal/HLSPlayerModal';
//import { request_devices } from '../../Devices/Devices/requests';

const points = [
  {x:0.7, y:0.4},
  {x:0.5, y:0.15},
  {x:0.25, y:0.25},
]


class DeviceView extends Component{

  state = {
    isloading:false,
    user:null,
    data:[{data:[]}],
    device:null,
    zone:null,
    selected_device:null,
    selected_camera:null,
    devices:[],
    cameras:[],
    hls:null,
    tableConfig:{
      filter:{
        user_type:null,
        status:null,
        general:null
      },
      searchText:'',
      page:0,
      rowsPerPage:5,
      totalItems:0
    },
    modals:{
      view_camera:{open:false,issending:false,error:null},
      add_device:{open:false,issending:false,error:null},
      edit_zone:{open:false,issending:false,error:null},
      edit_zone_picture:{open:false,issending:false,error:null},
      warning_change_item:{open:false, issending:false, error:null},
      warning_change_visibility:{open:false, issending:false, error:null},
      warning_delete_item:{open:false, issending:false, error:null}
    },
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {match} = this.props
    const {id} = match.params

    this.setState({isloading:true})
    try{
      let response = await request_zone(id)
      //Add fake data
      //let tempZone = this.addFakeLights(response)
      this.setState({zone:{...response}})
      response = await request_cameras()
      this.setState({cameras:[...response]})
      response = await request_devices()
      this.setState({devices:[...response]})
    }catch(e){
      console.log(e)
    }

    this.setState({isloading:false})
    

    
  }

  addFakeLights = (data) => {
    let tempZone = {...data}
    let aux = [...tempZone.devices]
    lightsList.map((item, key) => {
      aux.push({
        device:{...item},
        device_id:key*2,
        order:0,
        visible:1,
        x_coordinate:points[key].x,
        y_coordinate:points[key].y,
        z_coordinate:0,

      })

    })
    tempZone.devices = [...aux]
    return tempZone
  }

  

  onEditUser = async() => {
    const {match} = this.props
    const id = match.params.id
    //history.push('/agents/'+id+'/edit')
  }

  onReturn = () => {
    const {history} = this.props
    history.push('/zones')
  }


  render(){

    const {classes, history, language, catalogs, translations, match} = this.props
    const {user, isloading, devices, zone, modals, tableConfig, cameras, selected_camera} = this.state
    const content = contentData[language]

    let data_devices = []
    let real_devices = []

    if(zone){
      //console.log(zone) //

      data_devices = cleanData2Table(zone.devices,'No data')
      
      //data_devices = setData2Table(zone.devices,'No data')
    }

    let modalcontent = null
    
    //console.log(devices)
    const devices_catalogs = this.getDevicesOptions(devices)
    const camera_catalogs = this.getCamerasOptions(cameras)
    
    if(modals.warning_delete_item.open){
      modalcontent=(
        <WarningModal 
          data={modals.warning_delete_item}
          content={content.modals.warning_modal}
          onClose={() => this.onCloseModal('warning_delete_item')}
          onDelete={this.onDeleteDevice}
        />
      )
    }

    if(modals.warning_change_item.open){
      modalcontent=(
        <InfoModal 
          data={modals.warning_change_item}
          onClose={() => this.onCloseModal('warning_change_item')}
          onSubmit={this.onChangeDevicePosition}
        />
      )
    }

    if(modals.warning_change_visibility.open){
      modalcontent=(
        <InfoModal 
          data={modals.warning_change_visibility}
          onClose={() => this.onCloseModal('warning_change_visibility')}
          onSubmit={this.onChangeVisibility}
        />
      )
    }

    if(modals.add_device.open){
      modalcontent = (
        <AddDeviceModal 
          fullWidth={true}
          addSpace={true}
          catalogs={{
            devices:[...devices_catalogs], 
            cameras:[...camera_catalogs], 
            device_types:[...catalogs.device_types]}}
          data={modals.add_device}
          onClose={() => this.onCloseModal('add_device')}
          onSubmit={this.onAddDevice}
        />
      )
    }

    if(modals.edit_zone.open){
      modalcontent = (
        <EditZoneModal 
          fullWidth={true}
          addSpace={true}
          origin_data={zone}
          data={modals.edit_zone}
          onClose={() => this.onCloseModal('edit_zone')}
          onSubmit={this.onEditZone}
        />
      )
    }

    if(modals.edit_zone_picture.open){
      modalcontent = (
        <ZoneImageModal
          data={modals.edit_zone_picture}
          language={language}
          onClose={() => this.onCloseModal('edit_zone_picture')}
          onSubmit={this.onSubmitPicture}
          onDelete={this.onDeletePicture}
        />
      )
    }


    if(modals.view_camera.open){
      modalcontent = (
        <HLSPlayerModal 
          fullWidth={true}
          addSpace={true}
          camera={selected_camera}
          data={modals.view_camera}
          onClose={() => this.onCloseModal('view_camera')}
          onSubmit={this.onEditZone}
        />
      )
    }
    
    return(
      <BasicView
        isloading={isloading}
        history={history}
        selected={0}
        onChange={this.onChangeView}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
      >
        {modalcontent}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ZoneDetails 
              data={zone} 
              onEdit={() => this.onOpenModal('edit_zone')}/>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <ZoneMap 
                  zone={zone}
                  onAddDevice={() => this.onOpenModal('add_device')}
                  onChangeVisibility={this.onPreChangeVisibility}
                  onChangeDevicePosition={this.onPreChangePosition}
                  onOpenHLSViewer={this.onOpenHLSViewer}
                  onChangeImage={() => this.onOpenModal('edit_zone_picture')}
                  onDelete={this.onPreDeleteDevice}
                  onViewDevice={this.onViewDevice}
                />
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        
        
      </BasicView>
      
    )
  }

  onOpenHLSViewer = async(camera) => {
    console.log(camera)
    //window.open(this.makeHref("https://b-604520a7.kinesisvideo.us-east-1.amazonaws.com/hls/v1/getHLSMasterPlaylist.m3u8?SessionToken=CiBwud5u7G1WIwFRzqst8NBOMi9AYYWmuBMWMspvOD2FGBIQ8pizf6QbE4O9wcafPx0skxoZvmqP9UwuhApRwJX8G4IfZKfdrSEgbYJdVyIg1HoybyUjCH0ttR4U9iwYO6A17R4H10V1W6LLqZa7T2M~"))
    this.setState({selected_camera:{...camera}})
    this.onOpenModal('view_camera')
  }

  onViewDevice = (device) => {
    //console.log(device)
    const {id} = this.props.match.params
    this.props.history.push(`/zones/${id}/device/${device._id}`)
  }

  onChangeVisibility = async() => {
    const {id} = this.props.match.params
    const {data} = this.state
    //console.log(data)

    const type = 'warning_change_visibility'
    let data2send = {
      visible: data.visible ? 0 : 1
    }
    try {
      this.onEditModal(type,{issending:true})
      let response = await request_update_zone_device(id, data.device_id, data2send)
      
      //let tempZone = this.addFakeLights(response)
      this.setState({zone:{...response}})

      //this.setState({zone:{...response}})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }

  }

  onPreChangeVisibility = (data) => {
    this.setState({
      data:{...data}
    })
    this.onOpenModal('warning_change_visibility')
  }

  onPreChangePosition = (device_id, data, type) => {
    //console.log('on change visiasjdkfljasldf')
    this.setState({
      device_id:device_id,
      data:{...data},
      device_type:type
    })
    //console.log('on change visiasjdkfljasldf')
    this.onOpenModal('warning_change_item')
  }

  onChangeDevicePosition = async() => {
    const {id} = this.props.match.params
    const {device_id, data, device_type} = this.state

    const type = 'warning_change_item'
    try {
      this.onEditModal(type,{issending:true})
      let response
      if(device_type === 'device'){
        response = await request_update_zone_device(id, device_id, data)
        //let tempZone = this.addFakeLights(response)
        this.setState({zone:{...response}})
      }else{
        response = await request_update_zone_camera(id, device_id, data)
        //let tempZone = this.addFakeLights(response)
        this.setState({zone:{...response}})
      }


      
      //this.setState({zone:{...response}})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }

  }

  onSubmitPicture = async(name, file) => {
    const {id} = this.props.match.params
    const type = 'edit_zone_picture'
    let formData = new FormData()
    formData.append('image', file[0], file[0].name)
    try {
      this.onEditModal(type, {issending:true})
      let response = await request_upload_image(id,formData)

      //let tempZone = this.addFakeLights(response)
      this.setState({zone:{...response}})
      //this.setState({zone:{...response}})
      this.onCloseModal(type)
    } catch (error) {
      console.log(error)
    }

  }

  onEditZone = async(data) => {
    //console.log(data)
    const {match} = this.props
    const {id} = match.params
    
    const type = 'edit_zone'
    try {
      this.onEditModal(type,{issending:true})
      let response
      response = await request_edit_zone(id,data)
      //console.log(response)
      //let tempZone = this.addFakeLights(response)
      this.setState({zone:{...response}})
      //await this.setState({zone:{...response}})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }

  onPreDeleteDevice = (device) => {
    this.setState({selected_device:device})
    this.onOpenModal('warning_delete_item')
  }

  onDeleteDevice = async() => {
    //console.log('borrrarrrr!!!')
    const {match} = this.props
    const {id} = match.params
    const {selected_device} = this.state
    //console.log(selected_device)
    
    const type = 'warning_delete_item'
    try {
      this.onEditModal(type,{issending:true})
      let response
      response = await request_delete_device(id,selected_device._id)
      //console.log(response)
      //let tempZone = this.addFakeLights(response)
      this.setState({zone:{...response}})
      //await this.setState({zone:{...response}})
      this.onCloseModal(type)
    } catch (error) {
      //console.log(error)
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }

  onPatientSelected = (id) => {

  }

  onAddDevice = async(data) => {
    const {match} = this.props
    const {id} = match.params

    const device_id = data.device_id
    const type_id = data.device_type_id
    console.log(data)
    
    const type = 'add_device'
    try {
      this.onEditModal(type,{issending:true})
      let response
      if(type_id < 5){
        response = await request_add_device(id,device_id)
      }else{
        response = await request_add_camera(id,device_id)
      }
      ////
      //console.log(response)
      //let tempZone = this.addFakeLights(response)
      this.setState({zone:{...response}})
      //await this.setState({zone:{...response}})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }

  
  getCamerasOptions = (data) => {
    let temp = []
    data.forEach(item => {
      if(item.name){
        temp.push({
          id: item._id,
          value: item._id,
          label: item.name,
          model: item.model
        })
      }

      
    })
    return temp
  }

  getDevicesOptions = (data) => {
    let temp = []
    data.forEach(item => {
      if(item.name){
        temp.push({
          id: item._id,
          value: item._id,
          label: item.name,
          model: item.model
        })
      }

      
    })
    return temp
  }

  onOpenModal = (section, filedata) => {
    //console.log(section)
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }
}

export default withStyles(styles)(DeviceView)