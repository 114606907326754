import React, {Component} from 'react'
import { withStyles } from '@material-ui/styles';
import CardWrapper from '../../../../../../../components/Cards/CardWrapper/CardWrapper';
import { AppBar, Tabs, Tab, Icon, Grid } from '@material-ui/core';
import MenuBar from './MenuBar';
import TableDevices from './TableDevices';
import {deviceList} from './fakeData.jsx'

class DeviceList extends Component {

  state = {
    selectedTab:'ALL',
    zones:[]
  }

  render(){

    const {classes, zones, onChangeVisibility, onSelected, selectedTab, cameras} = this.props
    

    //const filterZones = this.onFilterData(zones, selectedTab)

    return(
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <MenuBar onSelected={onSelected} selected={selectedTab}/>
        </Grid>
        <Grid item xs={12}>
          <CardWrapper>
            <div className={classes.root}>
              <div className={classes.table}>
                <TableDevices data={zones} onChangeVisibility={onChangeVisibility} selectedTab={selectedTab} cameras={cameras}/>
              </div>
            </div>
          </CardWrapper>
        </Grid>
      </Grid>
      
    )
  }

  componentDidMount(){
    let temp = deviceList.map((item,key) => {
      return {
        id:key,
        device: {...item},
        visible: false,
        drawing:{
          x:0,
          y:0,
        }
      }
    })

    this.setState({zones:[...temp]})
  }

  onChangeVisibility = (data) => {
    const {zones} = this.state
    let temp = [...zones]
    temp[data.id].visible = !temp[data.id].visible
    this.setState({zones:[...temp]}) 
  }

  onFilterData = (data, selector) => {
    let new_data = []

    let tab = 'ALL'
    if(selector !== 'ALL'){
      switch(selector){
        case 'MOTION':
          tab = 'Presence/Motion'
          break;
        case 'TH':
          tab = 'Temperature/Humidity'
          break;
        case 'OPEN/CLOSE':
          tab = 'Open/Close'
          break;
        case 'ENERGY':
          break;
        case 'LUMMINARIES':
          tab = 'Lumminaries'
          break;
        default:
          break;
      }
    }else{
      return data
    }

    data.forEach(item => {
      if(item.device.model.name === tab){
        new_data.push(item)
      }
    })

    return new_data
  }

  onSelected = (id) => {
    this.setState({selectedTab:id})
  }

  onChange = (event, value) => {
    this.setState({selectedTab:value})
  }


}

const styles = theme => ({
  root:{
    padding:16,
    boxSizing:'border-box',
    
  },
  table:{
    overflowY:'auto',
    height:'343px',
    [theme.breakpoints.only('lg')]: {
      height:'418px',
    },
    [theme.breakpoints.only('xl')]: {
      height:'643px',
    },
  }
})

export default withStyles(styles)(DeviceList)