import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import {request_camera, request_edit_camera, request_move_camera, request_video_fragment, request_camera_link} from './requests'
import CameraDetailsCard from './components/CameraDetailsCard';
import CameraVideoCard from './components/CameraVideoCard';
import EditCameraModal from './modals/EditCameraModal/EditCameraModal';
import CameraRecordSettings from './components/CameraRecordSettings';
import moment from 'moment'

class CCTVView extends Component{

  state = {
    isloading:false,
    selected:1,
    camera:null,
    isloading_video:false,
    modals:{
      edit_camera:{open:false,issending:false,error:null},
      warning_delete_item:{open:false, issending:false, error:null, id:null}
    },
  }

  


  render(){

    const {classes, history, language, catalogs, translations, match} = this.props
    const {user, isloading, camera, url, modals, selected, isloading_video} = this.state
    const content = contentData[language]

    let contentView = null

    //const url=''
    let modalContent = null

    if(modals.edit_camera){
      modalContent= <EditCameraModal 
        origin_data={camera}
        data={modals.edit_camera}
        onClose={() => this.onCloseModal('edit_camera')}
        onSubmit={this.onEditCamera}
      />
    }

    switch(selected){
      case 1:// General
        contentView = (
          <Grid container spacing={2}>
            <Grid item>
              <CameraDetailsCard camera={camera} onEdit={() => this.onOpenModal('edit_camera')}/>
            </Grid>
            <Grid item xs>
              <CameraVideoCard url={url} />
            </Grid>
          </Grid>
        )
        break;
      default:
          let isvalid = false
          if(url){
            if(url.url){
              isvalid = true
            }
          }

        contentView = (
          <Grid container spacing={2}>
            <Grid item>
              <CameraRecordSettings onSubmit={this.onRequestVideo}/>
            </Grid>
            <Grid item xs>
              {isvalid && !isloading_video ? (
                <CameraVideoCard url={url} />
              ) : null}
            </Grid>
          </Grid>
        )
        break;
    }

    
    return(
      <BasicView
        selected={selected}
        isloading={isloading}
        history={history}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
        onChange={this.onChangeTab}
      > 
        {modalContent}
        {contentView}
        
      </BasicView>
      
    )
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {match} = this.props
    const {id} = match.params

    this.setState({isloading:true})
    try{
      let response = await request_camera(id)
      this.setState({camera:{...response}})
      response = await request_camera_link(id)
      this.setState({url:{...response}})
    }catch(e){
      console.log(e)
    }

    this.setState({isloading:false})
    

    
  }

  onRequestVideo = async(data) => {
    //console.log(data)
    const {match} = this.props
    const {id} = match.params
    let start_date = moment(`${data.start_date} ${data.start_hour}`)
    let end_date = moment(`${data.end_date} ${data.end_hour}`)

    let params = {
      expiration:600,
      start:start_date.unix(),
      end:end_date.unix()
    }

    this.setState({isloading_video:true})
    try{
      let response = await request_video_fragment(id, params)
      this.setState({url:{...response}})
    }catch(e){
      console.log(e)
    }
    this.setState({isloading_video:false})
  }

  

  onEditCamera = async(data) => {
    const {match} = this.props
    const type = 'edit_camera'
    try {
      this.onEditModal(type,{issending:true})
      let response = await request_edit_camera(match.params.id,data)
      this.setState({camera:response})
      this.onCloseModal(type)
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }

  

  onEditUser = async() => {
    const {match} = this.props
    const id = match.params.id
    //history.push('/agents/'+id+'/edit')
  }

  onChangeTab = async(value) => {
    const {match} = this.props
    const {id} = match.params
    this.setState({selected:value})
    this.setState({url:null})
    if(value === 1){
      this.setState({isloading:true})
      try{
        let response = await request_camera_link(id)
        this.setState({url:{...response}})
      }catch(e){
        console.log(e)
      }
      this.setState({isloading:false})
      
    }
    
  }

  onReturn = () => {
    const {history} = this.props
    history.push('/cctv')
  }

  onOpenModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }
}

export default withStyles(styles)(CCTVView)