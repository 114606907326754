export const content = {
  title:'Device details',
  nodata:'No data',
  error:'No data',
  button:'Save',
  cancelButton:'Cancel',
  form:{
    identifier:{
      label:'Identifier',
      placeholder:'Type a identifier',
      helper:'Invalid identifier',
    },
    name:{
      label:'Name',
      placeholder:'Type a name',
      helper:'Invalid name',
    },
  },
}