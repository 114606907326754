import React from 'react'
import {Grid, Typography, Checkbox } from '@material-ui/core'
import {grey} from '@material-ui/core/colors'

const styles = {
  text:{
    fontWeight:'500',
    color:grey[500]
  }
}

const ItemSelection = (props) => {

  const {text, status, id, onChange, disabled, } = props

  return(
    <Grid container spacing={0} alignItems="center">
      <Grid item>
        <Typography variant='body2' style={styles.text}>
          {text}
        </Typography>
      </Grid>
      <Grid item>
        <Checkbox 
          disabled={disabled}
          checked={status}
          onChange={(event, status) => onChange(id,status)}
        />
      </Grid>
    </Grid>
  )
}

export default ItemSelection
