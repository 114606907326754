import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.jsx'
import { Switch, Route, Redirect } from "react-router-dom"
import SideBAr from '../../components/SideBar/SideBar.jsx'
import TopBar from '../../components/TopBar/TopBar.jsx'
import adminRoutes from '../../routes/dashboardAdmin'
import { withRouter } from 'react-router-dom'
import {
  withWidth
} from '@material-ui/core';
import cx from  'classnames'
import {catalogs as catalogContent}  from '../../variables/catalogs'
import {translations as translationContent}  from '../../variables/translations'
import moment from 'moment-timezone'


import {request_reauth, request_get_user_info, request_get_catalogs} from './request'

class Dashboard extends Component{
 
  state={
    isDrawerOpen: true,
    isreauth:false,
    section: "HOME",
    user: null,
  }

  componentDidMount(){
    this.initModule()
    
  }

  initModule = async() => {
    this.setState({
      user:{
        first_name:'Cesar',
        last_name:'Gonzalez',
        user_type:'Administrador'
      }
    })
    /*try {
      this.setState({isloading:true})
      let response
      response = await request_get_user_info()
      console.log(response)
      //await request_get_catalogs()
      this.setState({user:{...response}})
      this.setState({isloading:false})
    } catch (error) {
      console.log(error)
      this.setState({isloading:false})
    }*/
  }

  onChangeView = (url) => {
    const {history, width} = this.props;
    history.push(url)
    if(width === 'xs' || width == 'sm'){
      this.isDrawerCloset()
    }
  }

  isDrawerCloset = () => {
    const temp = this.state.isDrawerOpen;
    this.setState({isDrawerOpen: !temp})
    console.log("regresar");
  } 

  isDrawerOpen = () => {
    const temp = this.state.isDrawerOpen
    this.setState({isDrawerOpen:!temp})
  }
  handlerExit = () => {
    localStorage.clear();
    this.props.history.push("/signin");
  }
  handlerHome = () => {
    this.props.history.push("/");
    this.setState({section: "HOME"})
    console.log("home")
  }

  onChangeParentUser = (data) => {
    this.setState({user:{...data}})
  }

  render(){

    const { classes, history } = this.props;
    const {isDrawerOpen, section, user} = this.state;

    let catalogs = catalogContent['spanish']
    const timezones = moment.tz.names().map(item => ({value:item, label:item}))
    catalogs.timezones = [...timezones]
    const translations = translationContent['spanish']
    let contentRedirect = null
    let temp = localStorage.getItem('data');

    let dashboardRoutes = {sections:[]}

    let userLevel = 4

    return(

      <div className={classes.root} onMouseMove={this.mouseMoveEvent}>
        {contentRedirect}
      <TopBar 
        open={isDrawerOpen} 
        user={user}
        isdraweropen={this.isDrawerOpen} 
        onClose={this.isDrawerCloset} 
        closet={this.handlerExit} 
        section={section}
        history={history}
      />
      <SideBAr 
        history={history} 
        user={user}
        routes={adminRoutes.sections}
        onChange={this.onChangeView}
        open={isDrawerOpen} 
        onClose={this.isDrawerCloset}
      />
      <main className={cx({
        [classes.content]:true,
        [classes.contentOpen]:isDrawerOpen
      })}>
        <Switch>
          {adminRoutes.sections.map((route,idx) => {
            if(route.redirect)
              return <Redirect to={route.to} key={idx.toString()}/>
            return <Route 
              path={route.path} 
              exact={route.exact}
              render={(props) => <route.component 
                {...props} 
                language={'spanish'} 
                parentuser={user} 
                catalogs={catalogs}
                user_level={userLevel}
                translations={translations}
                onChangeParentUser={this.onChangeParentUser}
              />} 
              key={idx.toString()}
            />
          })
          }
        </Switch>
      </main>
    </div>
    );
  }
};


export default withRouter((withStyles(styles)(withWidth()(Dashboard))));