export const form_data = {
  period:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'period',
      type:'number',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'none',
    }
  },
  temperature_delta:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'temperature_delta',
      type:'number',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'none',
    }
  },
  humidity_delta:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'humidity_delta',
      type:'number',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'none',
    }
  },
  max_silenced_reports:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'max_silenced_reports',
      type:'number',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'none',
    }
  },
  
}