import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SimpleCard from '../../../../components_v1/Structure/Cards/SimpleCard';
import LoadingContainer from '../../../../components_v1/Structure/Layouts/LoadingContainer';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import temperatureIcon from '../../../../assets/solerpalau/thermometer.png'
import vibrationIcon from '../../../../assets/solerpalau/vibration.png'
import signalIcon from '../../../../assets/solerpalau/signal.png'
import timeIcon from '../../../../assets/solerpalau/timereport.png'
import moment from 'moment-timezone'
const CurrentSensorData = ({loading, temperature, vibration, signal, last_seen}) => {

    let time = moment(last_seen)
    time = time.isValid() ? `${time.format('DD MMM, HH:mm')}h` : 'N/D'

    return ( 
        <LoadingContainer loading={loading}>
            <div>
                <SimpleCard>
                    <Grid container>
                        <Grid item xs>
                            <SensorItem title='Temperaure' icon={temperatureIcon} 
                            value={temperature ? temperature : '0'} unit='°C'/>
                        </Grid>
                        <Grid item xs>
                            <SensorItem title='Vibration' icon={vibrationIcon} 
                            value={vibration ? vibration : '0'} unit='mm/s'/>
                        </Grid>
                        <Grid item xs>
                            <SensorItem title='Signal' icon={signalIcon} 
                            value={signal ? signal : '0'} unit='%'/>
                        </Grid>
                        <Grid item xs>
                            <SensorItem title='Last report' icon={timeIcon} 
                            value={time ? time : '00-00-00'} unit=''/>
                        </Grid>
                    </Grid>
                </SimpleCard>
            </div>
        </LoadingContainer>
     );
}
 
export default CurrentSensorData;

const SensorItem = ({title, value, unit, icon}) => {
    return(
        <div style={{paddingTop:8}}>
            <Grid container spacing={0} direction='column' alignItems='center'>
                <Grid item><img src={icon} alt='' width={48}/></Grid>
                <Grid item><DisplayText color='textSecondary'>{title}</DisplayText></Grid>
                <Grid item>
                    <Grid container>
                        {/*<Grid item xs={12}>
                            <DisplayText variant='body2'>{title}</DisplayText>
                        </Grid>*/}
                        <Grid item xs={12}>
                            <DisplayText variant='body1'>{`${value} ${unit}`}</DisplayText>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}