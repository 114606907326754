import {mainServer} from '../../../../../../variables/config'

export const request_record = async(id, startdate,enddate) => {
  let response;
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  response = await mainServer.instance.get(`/device/${id}/record?start=${startdate}&end=${enddate}&limit=1000&offset=0`);
  return response.data.data
}

export const request_device = async(id) => {
  let response;
  mainServer.instance.defaults.headers.common['Authorization'] =
   'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  response = await mainServer.instance.get(`/device/${id}`);
  return response.data.data.device
}

export const request_edit_device_details = async(id, data) => {
  let response;
  mainServer.instance.defaults.headers.common['Authorization'] =
   'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  await mainServer.instance.patch(`/device/${id}`, data);
  response = await mainServer.instance.get(`/device/${id}`);
  return response.data.data.device
}

export const request_edit_device_settings = async(id, data) => {
  let response;
  mainServer.instance.defaults.headers.common['Authorization'] =
   'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  await mainServer.instance.post(`/device/${id}/controller/digital`, data);
  response = await mainServer.instance.get(`/device/${id}`);
  return response.data.data.device
}


//2000-01-01T00:00:00Z
//2020-01-01T00:00:00Z