import { Grid, Icon } from '@material-ui/core';
import { blueGrey, green, red } from '@material-ui/core/colors';
import React, { useState, useEffect } from 'react';
import SimpleCard from '../../../../components_v1/Structure/Cards/SimpleCard';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import temperatureIcon from '../../../../assets/solerpalau/thermometer.png'
import vibrationIcon from '../../../../assets/solerpalau/vibration.png'


const name = ['Motor Bearing SP1', 'Motor Bearing SP2', 'Motor Bearing SP3', 'Motor Bearing SP4', 'Motor Bearing SP5']



const ControllersView = ({device, onGo2Page}) => {
    console.log(Math.random()-0.5)

    let data = []
    name.forEach(item => {
        data.push({
            id:'',
            title:item,
            status:1,
            temperature:device ? device.temperature + Math.random()-0.5 : 0.00,
            vibration:device ? device.vibration +Math.random()-0.5 : 0.00
        })
    })

    data[3].status = 0

    const onDeviceSelected = (id) => {

    }

    return(
        <div>
            <Grid container>
                <Grid item xs={12}>
                    <DisplayText color='textSecondary' variant='subtitle1'>Digital/Analog Controller's General Status</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {data.map((item,key)=>{
                            return(
                                <Grid item xs key={key.toString()}>
                                    <BearingCardView {...item} onDeviceSelected={onGo2Page}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
                
            </Grid>
        </div>
    )
}

export default ControllersView

const BearingCardView = ({id, title, status, temperature, vibration, onDeviceSelected}) => {
    return(
        <div>
            <SimpleCard padding='small' onClick={() => onDeviceSelected(id)}>
                <Grid container>
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle1' style={{color:blueGrey[700], fontSize:16, fontWeight:500}}>{title}</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item><Icon style={{fontSize:12, color: status === 1 ? green[700] : red[700]}}>brightness_1</Icon></Grid>
                            <Grid item><DisplayText color='textSecondary' variant='body2' >{status === 1 ? 'Online' : 'Offline'}</DisplayText></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justify='center' spacing={2}>
                            <Grid item xs={12} md={6}>
                                <SensorItem icon={temperatureIcon} value={temperature ? parseFloat(temperature).toFixed(1) : 0.0} unit='°C' title='Temperature'/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SensorItem icon={vibrationIcon} value={vibration ? parseFloat(vibration).toFixed(1) : 0.0} unit='mm/s' title='Vibration'/>
                            </Grid>
                            
                        </Grid>
                    </Grid>
                   
                </Grid>
            </SimpleCard>
        </div>
    )
}

const SensorItem = ({title, value, unit, icon}) => {
    return(
        <div style={{paddingTop:8}}>
            <Grid container spacing={0} direction='column' alignItems='center'>
                <Grid item><img src={icon} alt='' width={48}/></Grid>
                <Grid item>
                    <Grid container>
                        {/*<Grid item xs={12}>
                            <DisplayText variant='body2'>{title}</DisplayText>
                        </Grid>*/}
                        <Grid item xs={12}>
                            <DisplayText variant='body1'>{`${value} ${unit}`}</DisplayText>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}