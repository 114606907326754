import React, { useState, useEffect } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import BasicView from '../../../components_v1/Structure/Layouts/BasicView';
import {request_device} from './requests'
import moment from 'moment'
import { ntcPoints } from '../../../variables/sensors/ntcData';
import { onGetCurrentPoint, onGetResistancePoint, onGetTemperaturePoint } from '../../../shared/utility';
// ID 5ffa9981b816cf0019ca2978
import deviceIcon from '../../../assets/solerpalau/single-machine.png'
import ChartsContent from './components/ChartsContent';
import CurrentSensorData from './components/CurrentSensorData';
import DetailInfoCard from './components/DetailInfoCard';
import RoundedButton from '../../../components_v1/Actions/RoundedButton';
import ReportModal from './modals/ReportModal';

const deviceID = '5ffa9981b816cf0019ca2978'

const BearingControlView = props => {

    const [device, setDevice] = useState({
        id:null,
        temperature:null,
        vibration:null,
        last_report:null,
        status:1,
        model:null,
        technology:null,
        eui:null,
        power:null,
    })
    const [loading, setLoading] = useState(false)
    const [reportModal, setReportModal] = useState(false)
    const [count, setCount] = useState(0)
    const [sending, setSending] = useState(false)

    useEffect(() => {
        const initModule = async() => {try {
            setSending(true)
            const _device = await request_device(deviceID)
            let _aux = {...device}

            const timestamp = moment()
            if(_device){
                const rTemp = onGetResistancePoint(_device.data.universal_input_3.value/100)
                const temp = onGetTemperaturePoint(rTemp)
                const currentPoint = onGetCurrentPoint(_device.data.universal_input_1.value*100)
                _aux.id = _device._id
                _aux.temperature = parseFloat(temp).toFixed(2)
                _aux.vibration = parseFloat(currentPoint).toFixed(2)
                _aux.status = timestamp.diff(moment(_device.data.last_seen.value)) < 15*60*1000
                _aux.last_report = _device.data.last_seen.value
                _aux.signal = _device.data.signal.value
                _aux.modelType = _device.model.type
                _aux.modelName =_device.model.name
                _aux.technology = _device.technology
                _aux.eui = _device.eui
                _aux.power = _device.power
            }
            setDevice(_aux)
        } catch (error) {
            console.log(error)
        }
            setSending(false)
        }
        initModule()
    }, [count])

    useEffect(() => {
        const interval = setInterval(() => {
            let _count = count + 1
            setCount(_count)
          }, 15000);
          return () => clearInterval(interval);
    }, [])

    const onGo2Page = () => {
        console.log(deviceID)
    }


    return(
        <div>
            <BasicView title='Motor bearing details' setReturn>
                <div>
                    <ReportModal deviceID={deviceID} open={reportModal} onClose={() => setReportModal(false)}/>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Grid container justify='flex-end'>
                                <Grid item>
                                    <RoundedButton onClick={() => setReportModal(true)}>Download report</RoundedButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            
                            <Grid container spacing={3}>
                                {sending ? 
                                    <Grid item xs={12}><LinearProgress /></Grid> : null}
                                <Grid item xs={12}>
                                    <CurrentSensorData loading={loading} {...device}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justify='center'>
                                        <Grid item xs={6}>
                                            <img src={deviceIcon} alt='' width='100%'/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <DetailInfoCard loading={loading} {...device}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <ChartsContent deviceID={deviceID}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </Grid>
                </div>
            </BasicView>
        </div>
    )
}

export default BearingControlView