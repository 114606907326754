import React, {Component} from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core';
import ContentText from '../../../../../components/Texts/ContentText';
import { grey } from '@material-ui/core/colors';
import ReactApexChart from 'react-apexcharts';

class EnergyConsuption extends Component {

  state = {
    options: {

      plotOptions: {
        bar: {
            horizontal: true,
        }
      },
      dataLabels: {
          enabled: false
      },
      
      xaxis: {
          categories: ['HVACs', 'Lightning', 'Refrigerators', 'Contacts', 'Pumps','Others',],
      },
    },
    series: [{
      name:'Energy',
      data: [350, 500, 690, 1100, 1200, 1380].reverse()
    }],
  }


  render(){

    const {classes} = this.props

    return(
      <div>
        <Grid container>
          <Grid item xs={12}>
            </Grid>
          <Grid item xs={12}>
            <div className={classes.root}>
              <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height="468" />
            </div>
          </Grid>
          
        </Grid>
      </div>
      
    )
  }
}

const styles = (theme) => ({
  root:{
    width:500,
    [theme.breakpoints.only('lg')]: {
      width:320
    },
    [theme.breakpoints.only('md')]: {
      width:250
    },
  },
  text:{
    color:grey[700],
    fontWeight:'600'
  }
})

export default withStyles(styles)(EnergyConsuption)