export const content = {
  title:'Edit camera',
  nodata:'-',
  error:'-',
  button:'Save',
  cancelButton:'Cancel',
  form:{
    identifier:{
      label:'Identifier',
      placeholder:'Type a identifier',
      helper:'Invalid identifier'
    },
    name:{
      label:'Name',
      placeholder:'Type a name',
      helper:'Invalid name'
    },
    user:{
      label:'Usuario',
      placeholder:'Type a user',
      helper:'Invalid user'
    },
    password:{
      label:'Password',
      placeholder:'Type a password',
      helper:'Invalid password'
    },
    ip:{
      label:'IP address',
      placeholder:'Type a IP address',
      helper:'Invalid IP address'
    },
  },
}