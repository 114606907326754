import React, {Component} from 'react'
import { IconButton, Icon, Grid } from '@material-ui/core';
import PopperWrapper from '../Poppers/PopperWrapper';
import Caption from '../Texts/Caption';

/*

infoButtonData = [
        {
          title:'Producto aprobado',
          content:[
            {label:'Nombre: ', text: data.approver},
            {label:'Fecha: ', text: data.approved_date},
          ]
        },
        {
          title:'Cambio manual',
          content:[
            {label:'Nombre: ', text: data.manual_changer},
          ]
        }
      ]

*/

class InfoButton extends Component {

  state = {
    anchorEl: null,
    open: false
  }

  render(){

    const {data, onClick, size} = this.props
    const {anchorEl, open} = this.state

    let smallStyle = null

    if(size){
      if(size === 'small'){
        smallStyle = {
          fontSize:18
        }
      }
    }


    return(
      <div>
        <PopperWrapper
          anchorEl={anchorEl}
          open={open}
          onClickAway={this.onCloseMenu}
        >
          <div style={{padding:8, width:'200px'}}>
            <Grid container spacing={2}>
              {data.map((item,key)=>{
                return(
                  <Grid item xs={12}>
                    <Grid container key={key.toString()}>
                      <Grid item xs={12}>
                        <Caption medium text={item.title} variant='bold'/>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          {item.content.map((el,id)=>{
                            return(
                              <Grid item xs={12}>
                                <Grid container key={id.toString()} spacing={1} wrap='nowrap'>
                                  <Grid item>
                                    <Caption medium text={el.label}  variant='light'/>
                                  </Grid>
                                  <Grid item>
                                    <Caption medium text={el.text} />
                                  </Grid>
                                </Grid>
                              </Grid>
                            )
                          })}
                        </Grid>
                       
                      </Grid>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </div>
        </PopperWrapper>
        <IconButton 
          size='small' 
          color='primary' 
          style={{padding:0}}
          onClick={this.onOpenMenu}
        >
          <Icon style={smallStyle}>info</Icon>
        </IconButton>
      </div>
    )
  }

  onCloseMenu = () => {
    this.setState({open: false,anchorEl: null});
  };
 

  onOpenMenu = event => {
    const { currentTarget } = event;
    this.setState({anchorEl: currentTarget,open: true});
  }
}

export default InfoButton