export const form_data = {
  device_type_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'device_type_id',
      type:'select',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'none',
    }
  },
  device_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'device_id',
      type:'select',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'none',
    }
  },
  
}