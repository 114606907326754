export const contentData = {
  spanish:{
    layout:{
      title:'Zone Details',
      navigation:[
        {name:'Zones',path:'/zones'},
        {name:'Details',path:'/zones/:id'}
      ]
    },
    superfilters:[],
    header:['', 'Name','ID','Type','Power','Status','Data','Action'],
    modals:{
      warning_modal:{
        title:'Are your sure?',
        message:'¿Do you really want to delete this register? This process cannot be undone',
        cancelButton:'Cancel',
        deleteButton:'Delete'
      }
    },
  }
}
