export const form_data = {
  identifier:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'identifier',
      type:'text',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'none',
    }
  },
  name:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'name',
      type:'text',
      fullWidth: true,
      md:12
    },
    rules:{
      type:'none',
    }
  },
  
}