import React, { Component } from 'react';
import { withStyles, Grid, Icon, Avatar } from '@material-ui/core';
import { IoTCard } from '../../../../../components/Layouts/IoTCard/IoTCard';
import ContentText from '../../../../../components/Texts/ContentText';
import Subtitle from '../../../../../components/Texts/Subtitle';
import Title from '../../../../../components/Texts/Title';
import Caption from '../../../../../components/Texts/Caption';
import { palette } from '../../../../../variables/config';
import { lightBlue } from '@material-ui/core/colors';
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';

class EnergyGroup extends Component {
  render(){

    const {classes, data} = this.props

    const totalCurrent = data.data[0].current + data.data[1].current + data.data[2].current
    const totalPower = totalCurrent*120/1000
    return(
      <div>
        <IoTCard title={{text:data.title, align:'center'}}>
          <Grid container spacing={2}>
            {data.data.map((item,key)=>{
              const power = item.current*120/1000

              return(
                <Grid item xs={12} md={6} key={key.toString()}>
                  <Grid item xs={12}>
                    <ContentText text={item.title} align='center' variant='bold' medium/>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={12}>
                        <DataRepresentation 
                          title={'Consumo de corriente'}
                          value={item.current}
                          unit={'amps'}
                          letter='A'
                        /> 
                      </Grid>
                      <Grid item xs={12}>
                        <DataRepresentation 
                          title={'Potencia'}
                          value={power}
                          unit={'kWh'}
                          letter='W'
                        /> 
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                
              )
            })}
            <Grid item xs={12} md={6}>
              <Grid container>
                <Grid item xs={12}>
                  <ContentText text={'Total'} align='center' medium/>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <DataRepresentation 
                        title={'Consumo de corriente'}
                        value={totalCurrent}
                        unit={'amps'}
                        letter='A'
                      /> 
                    </Grid>
                    <Grid item xs={12}>
                      <DataRepresentation 
                        title={'Potencia'}
                        value={totalPower}
                        unit={'kWh'}
                        letter='W'
                      /> 
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>  
            </Grid>
            <Grid item xs={12}>
              <Grid container justify='flex-end'>
                <Grid item>
                  <RoundedButton label='Detalles' color='secondary' size='small'/>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </IoTCard>
      </div>
    )
  }
}

const styles = theme => ({
  root:{}
})

export default withStyles(styles)(EnergyGroup)

const DataRepresentation = props => {

  const {title, value, unit, letter} = props

  return(
    <Grid container wrap='nowrap' spacing={1} alignItems='center'>
      <Grid item>
        <div style={{
          position:'relative',
          background:palette.primary.main,
          borderRadius:'50%',
          width:24,
          height:24
        }}>
          <div style={{position:'absolute', bottom:-4, right:-4}}>
            <Avatar style={{fontSize:12, padding:0, width:14, height:14, background:lightBlue[600]}}>{letter}</Avatar>
          </div>
          <Icon fontSize='small' style={{color:'white', fontSize:16, paddingTop:4, paddingLeft:4}}>flash_on</Icon>
        </div>
      </Grid>
      <Grid item xs>
        <Grid container>
          <Grid item xs={12}>
            <Caption text={title} medium variant='light'/>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems='baseline' spacing={1}>
              <Grid item>
                <Title text={value.toFixed(2)} variant='bold' medium color={palette.primary.main}/>
              </Grid>
              <Grid item>
                <ContentText text={unit} medium/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>   
  )
}