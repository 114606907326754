import {mainServer} from '../../../../variables/config'

export const request_user = async (id,startdate,enddate) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  console.log(logData)
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/"+id);
  return response.data.data.user
}

export const request_record = async(id, startdate,enddate) => {
  let response;
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  response = await mainServer.instance.get(`/device/${id}/record?start=${startdate}&end=${enddate}&limit=1000&offset=0`);
  return response.data.data
}

export const request_video_fragment = async(id, params) => {
  let response;
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  response = await mainServer.instance.get(`/camera/${id}/fragmentedhls`, {params:{...params}})
  return response.data.data
}



export const request_camera = async(id) => {
  let response;
  mainServer.instance.defaults.headers.common['Authorization'] =
   'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  response = await mainServer.instance.get(`/camera/${id}`);
  return response.data.data.camera
}


export const request_edit_camera = async(id, data) => {
  let response;
  mainServer.instance.defaults.headers.common['Authorization'] =
   'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
   await mainServer.instance.patch(`/camera/${id}`, data);
  response = await mainServer.instance.get(`/camera/${id}`);
  return response.data.data.camera
}

export const request_camera_link = async(id) => {
  let response;
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  response = await mainServer.instance.get(`/camera/${id}/hls?expiration=1800`)
  console.log(response.data.data)
  return response.data.data
}



export const request_move_camera = async(id, direction) => {
  let response;
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  response = await mainServer.instance.post(`/camera/${id}/ptz/${direction}`, {value:1000})
  console.log(response.data)
  //console.log(response.data.data)
  //return response.data.data
}

//2000-01-01T00:00:00Z
//2020-01-01T00:00:00Z