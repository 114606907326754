import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  Grid,
  ClickAwayListener,
  Popper,
  Fade,
  Paper,
  MenuList,
  MenuItem,
  Typography,
  Icon,
  withWidth
} from '@material-ui/core';
import People from '@material-ui/icons/Person';
import ExitToApp from '@material-ui/icons/ExitToApp';


class UserPopper extends Component{
  render(){

    const {open, anchorEl, onClickAway, onClickLogout, onItemSelected, classes} = this.props;

    return(
        <Popper open={open} anchorEl={anchorEl} placement={'bottom-end'} transition className={classes.popper}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={onClickAway} >
                  <MenuList >
                  < MenuItem onClick={() => onItemSelected('/profile')}>
                      <Grid container alignItems='center' justify='flex-start' spacing={1}>
                        <Grid item>
                          <Icon className={classes.iconUserMenu}>
                            <People/>
                          </Icon>
                        </Grid>
                        <Grid item>
                          <Typography variant='body2'>
                            Profile
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                    <MenuItem onClick={onClickLogout}>
                      <Grid container alignItems='center' justify='flex-start' spacing={1}>
                        <Grid item>
                          <Icon className={classes.iconUserMenu}>
                            <ExitToApp/>
                          </Icon>
                        </Grid>
                        <Grid item>
                          <Typography variant='body2'>
                            Log out
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </MenuList>

                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
    );
  }
};

export default withStyles(styles)(withWidth()(UserPopper));
