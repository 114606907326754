import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import MapView from './Components/MapView/MapView';
import { RoundedButton } from '../../../../components/Buttons/GeneralButtons';
import DeviceList from './Components/DeviceList/DeviceList';

import {deviceList, initData} from './fakeData.jsx'

class LabView extends Component{

  state = {
    isloading:false,
    zones:[],
    selectedTab:'ALL',
    editable:false,
    modals:{
      add_zone:{open:false,issending:false,error:null},
      warning_delete_item:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  render(){

    const {classes, history, language} = this.props
    const {success, isloading, editable, zones, selectedTab} = this.state
    const content = contentData[language]

    let modalcontent = null

    //onsole.log(JSON.stringify(zones))

    const filterZones = this.onFilterData(zones, selectedTab)
    
    return(
      <BasicView
        history={history}
        content={content.layout}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar} 
        isloading={isloading}
      >
        {modalcontent}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container justify='flex-end'>
              <Grid item>
                <RoundedButton  label='Add device' size='small'/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container wrap='nowrap' spacing={1}>
              <Grid item>
                <MapView 
                  zones={filterZones}
                  editable={editable} 
                  onChangeZones={this.onChangeZones}
                  onChangeEditable={this.onChangeEditable}/>
              </Grid>
              <Grid item xs>
                <DeviceList selectedTab={selectedTab} zones={filterZones} onChangeVisibility={this.onChangeVisibility} onSelected={this.onSelected}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        
      </BasicView>
      
    )
  }
  

  componentDidMount(){
    this.initZones()
    this.initModule()
  }

  initZones = () => {
    let temp = deviceList.map((item,key) => {
      return {
        id:key,
        device: {...item},
        visible: true,
        drawing:{
          x:0,
          y:0,
        }
      }
    })

    this.setState({zones:[...initData]})
  }

  onChangeZones = (data) => {
    this.setState({zones:[...data]})
  }

  initModule = async() => {
  }

  onChangeVisibility = (data) => {
    const {zones} = this.state
    let temp = [...zones]
    temp[data.id].visible = !temp[data.id].visible
    this.setState({zones:[...temp]}) 
  }

  onFilterData = (data, selector) => {
    let new_data = []

    let tab = 'ALL'
    if(selector !== 'ALL'){
      switch(selector){
        case 'MOTION':
          tab = 'Presence/Motion'
          break;
        case 'TH':
          tab = 'Temperature/Humidity'
          break;
        case 'OPEN/CLOSE':
          tab = 'Open/Close'
          break;
        case 'ENERGY':
          break;
        default:
          break;
      }
    }else{
      return data
    }

    data.forEach(item => {
      if(item.device.model.name === tab){
        new_data.push(item)
      }
    })

    return new_data
  }

  onSelected = (id) => {
    this.setState({selectedTab:id})
  }

  onChangeEditable = () => {
    this.setState(prevState => {
      this.setState({editable:!prevState.editable})
    })
  }

  
}

export default withStyles(styles)(LabView)