import React, { Component } from 'react';
import { Provider } from 'react-redux';
import PublicRouters from './routes/router.jsx';
import {store, history} from './redux/store.jsx';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#0847a7' }, // Purple and green play nicely together. #091635
    secondary: { main: '#4466f2' }, // This is just green.A700 as hex. #00acc1
    custom: {main: '#640178'} //2196F3
  },
  typography: {
    useNextVariants: true,
    fontFamily:[
      'Poppins',
    ].join(','),
  },
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
          <PublicRouters history={history}/>
      </MuiThemeProvider>
    );
  }
}

export default App;
