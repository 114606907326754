import { Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../components_v1/Actions/ActionModalBar';
import InputText from '../../../../components_v1/Forms/InputText';
//import LineInputDate from '../../../../components_v1/Forms/LineInputDate';
import SimpleModal from '../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetCurrentPoint, onGetFormData, onGetResistancePoint, onGetTemperaturePoint, onSetErrorsToForm, s2ab } from '../../../../shared/utility';
import moment from 'moment-timezone'
import { request_records } from '../requests';
import FileSaver from 'file-saver'
import XLSX from 'xlsx'


const ReportModal = ({open, onClose, deviceID}) => {

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(' ')

    const onInputChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async() => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        console.log(data2send)

        const start_date = moment(data2send.start_date)
        const end_date = moment(data2send.end_date)

        if(end_date.diff(start_date) <= 0){
            setError('La fecha final debe ser mayor a la fecha inicial')
            return
        }
        setError('')

        try {
            setLoading(true)
            const params = {start:start_date.utc().format(),end:end_date.utc().format(), limit:1000, offset:0}
            const _records = await request_records(deviceID, params)
            //console.log(_records)
            const excelRecords = cleanData2Excel(_records)
            console.log(excelRecords)

            const sheets = [
                {name:'Chumacera', data:excelRecords},
            ]
    
            let wb = XLSX.utils.book_new();
            wb.Props = {
                Title: "Veea Trionix Analytics",
                Subject: "General Bearing Data",
                Author: "Trionix Technologies",
                CreatedDate: new Date()
            };
    
            for(let i=0; i<sheets.length; i++){
                wb.SheetNames.push(sheets[i].name);
                const temp = XLSX.utils.aoa_to_sheet(sheets[i].data)
                wb.Sheets[sheets[i].name] = temp;
            }
    
            let wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
            FileSaver.saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}),'report.xlsx');
            
        } catch (error) {
            console.log(error)
            setError('Hubo un error al descargar los datos')
        }
        setLoading(false)
        onClose()
    }

    const cleanData2Excel = (data) => {
        let data2send = [['Fecha','Hora','Temperatura (°C)','Vibración (mA)','Nivel de señal (%)']]
        if(!data) return data2send
        data.forEach(item => {
            const currentTime = moment(item.timestamp.value)
            const rTemp = onGetResistancePoint(item.universal_input_3.value/100)
            const temp = onGetTemperaturePoint(rTemp)
            const currentPoint = onGetCurrentPoint(item.universal_input_1.value*100)

            data2send.push([
                currentTime.format('YYYY-MM-DD'),
                currentTime.format('HH:mm:ss'),
                temp, 
                currentPoint,
                item.signal.value
            ])
        })
        return data2send

    }

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DisplayText>Select a date range to download</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} >
                                {<InputText 
                                    data={form.start_date}
                                onChange={onInputChange}/>}
                            </Grid> 
                            <Grid item xs={12} >
                                {<InputText 
                                    data={form.end_date}
                                onChange={onInputChange}/>}
                            </Grid> 

                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionModalBar onSubmit={onSubmit} btnLabel='Descargar' loading={loading} error={error}/>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    );
}
 
export default ReportModal;

const formData = {
    start_date:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'start_date',
          type:'datetime-local',
          fullWidth: true,
          label:'Start date',
          placeholder:'Ingrese la fecha...',
          helper:'Invalid date'
        },
        rules:{type:'date'}
    },
    end_date:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'end_date',
          type:'datetime-local',
          fullWidth: true,
          label:'End date ',
          placeholder:'Ingrese la fecha...',
          helper:'Invalid date'
        },
        rules:{type:'date'}
    },
}

