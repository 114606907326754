import {mainServer} from '../../../../variables/config'

export const request_user = async (id,startdate,enddate) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  console.log(logData)
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/"+id);
  return response.data.data.user
}

export const request_record = async(id, startdate,enddate) => {
  let response;
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  response = await mainServer.instance.get(`/device/${id}/record?start=${startdate}&end=${enddate}&limit=1000&offset=0`);
  return response.data.data
}



export const request_device = async(id) => {
  let response;
  mainServer.instance.defaults.headers.common['Authorization'] =
   'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  response = await mainServer.instance.get(`/device/${id}`);
  return response.data.data.device
}

export const request_zone = async(id) => {
  let response;
  mainServer.instance.defaults.headers.common['Authorization'] =
   'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  response = await mainServer.instance.get(`/zone/${id}`);
  return response.data.data.zone
}

export const request_devices = async () => {
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  let response = await mainServer.instance.get("/device/list")
  return response.data.data.devices
}


export const request_cameras = async () => {
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  let response = await mainServer.instance.get("/camera/list")
  return response.data.data.cameras
}

export const request_edit_zone = async (id, data) => {
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  await mainServer.instance.patch(`/zone/${id}`, data);
  let response = await mainServer.instance.get(`/zone/${id}`);
  return response.data.data.zone
}




export const request_add_device = async (id, device_id) => {
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  await mainServer.instance.post(`/zone/${id}/device/${device_id}`)
  let response = await mainServer.instance.get(`/zone/${id}`);
  return response.data.data.zone
}


export const request_add_camera = async (id, device_id) => {
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  await mainServer.instance.post(`/zone/${id}/camera/${device_id}`)
  let response = await mainServer.instance.get(`/zone/${id}`);
  return response.data.data.zone
}

export const request_delete_device = async (id, device_id) => {
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  await mainServer.instance.delete(`/zone/${id}/device/${device_id}`)
  let response = await mainServer.instance.get(`/zone/${id}`);
  return response.data.data.zone
}

export const request_upload_image = async(id, data) => {
  console.log(data)
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  await mainServer.instance.post(`/zone/${id}/drawing`, data, {headers: {
    'Content-Type': 'multipart/form-data'
  }})
  let response = await mainServer.instance.get(`/zone/${id}`);
  return response.data.data.zone
}


export const request_update_zone_device = async (id, deviceid, data) => {
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  await mainServer.instance.patch(`/zone/${id}/device/${deviceid}`, data);
  let response = await mainServer.instance.get(`/zone/${id}`);
  return response.data.data.zone
}

export const request_update_zone_camera = async (id, deviceid, data) => {
  mainServer.instance.defaults.headers.common['Authorization'] = 'Basic ' + btoa(mainServer.credentials.user + ':' + mainServer.credentials.password);
  await mainServer.instance.patch(`/zone/${id}/camera/${deviceid}`, data);
  let response = await mainServer.instance.get(`/zone/${id}`);
  return response.data.data.zone
}



//2000-01-01T00:00:00Z
//2020-01-01T00:00:00Z