export const fakedata = {
  energy_table:{
    header:['Device', 'Last Report', 'Last Value', 'Status'],
    table:[
      {id:0, data:['Zona 1', '05:32hrs', '14kWh', 'online']},
      {id:1, data:['Zona 2', '05:25hrs', '16kWh', 'offline']},
      {id:2, data:['Zona 3', '05:42hrs', '5kWh', 'offline']},
      {id:3, data:['Zona 4', '06:02hrs', '4.4kWh', 'online']},
      {id:4, data:['Zona 5', '04:52hrs', '21kWh', 'online']},
      {id:5, data:['Zona 6', '07:01hrs', '12kWh', 'online']},
    ]
  },
  energy_groups:[
    {id:1, title:'Alumbrado normal', data:[
      {id:1, title:'Fase A', current:6.2},
      {id:2, title:'Fase B', current:8.4},
      {id:3, title:'Fase C', current:4.2},
    ]},
    {id:2, title:'Alumbrado inteligente', data:[
      {id:1, title:'Fase A', current:6.2},
      {id:2, title:'Fase B', current:8.4},
      {id:3, title:'Fase C', current:4.2},
    ]},
    {id:3, title:'Contactos', data:[
      {id:1, title:'Fase A', current:6.2},
      {id:2, title:'Fase B', current:6.4},
      {id:3, title:'Fase C', current:6.2},
    ]},
    {id:4, title:'Aire acondicionado', data:[
      {id:1, title:'Fase A', current:14.2},
      {id:2, title:'Fase B', current:15.4},
      {id:3, title:'Fase C', current:12.2},
    ]},
    {id:5, title:'Bombeo agua potable', data:[
      {id:1, title:'Fase A', current:21.2},
      {id:2, title:'Fase B', current:21.4},
      {id:3, title:'Fase C', current:21.2},
    ]},
    {id:6, title:'Bombeo sistema para riego', data:[
      {id:1, title:'Fase A', current:19.2},
      {id:2, title:'Fase B', current:19.4},
      {id:3, title:'Fase C', current:19.2},
    ]},
    {id:7, title:'Preparación elevador', data:[
      {id:1, title:'Fase A', current:25.2},
      {id:2, title:'Fase B', current:25.4},
      {id:3, title:'Fase C', current:25.2},
    ]}

  ]
}