import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import EditProfileInfo from './EditProfileInfo/EditProfileInfo'
import ProfilePictureModal from '../../../../components/Modals/ProfilePictureModal/ProfilePictureModal'

import {request_user, request_edit_user, request_deleteFile, request_uploadFile} from './requests'

class EditUserProfile extends Component{

  state = {
    isloading:false,
    issending:false,
    error:null,
    user:null,
    modals:{
      imagemodal:{open:false,issending:false,error:null},
      warning_delete_file:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'¡Acción exitosa!'},
  }

  componentDidMount(){
    this.initModule()
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id];
    temp.open = false;
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state;
    let success_temp = {...success};
    success_temp.open = true;
    this.setState({success:{...success_temp}})
  }

  initModule = async() => {
    const {match} = this.props
    const id = match.params.id
    this.setState({isloading:true})
    let response = await request_user()
    this.setState({user:{...response}})
    this.setState({isloading:false})
  }

  onReturn = () => {
    const {history} = this.props
    history.goBack()
  }

  onSubmitUser = async(data) => {
    try {
      this.setState({issending:true})
      let response = await request_edit_user(data)
      this.props.onChangeParentUser(response)
      this.setState({user:{...response}})
      this.setState({issending:false, error:null})
      this.props.history.push('/profile')
    } catch (error) {
      this.setState({issending:false})
      if(error.response){
        this.setState({error:error.response.data.error.message})
      }
      
    }
  }

  render(){

    const {classes, history, language, catalogs,translations, onChangeParentUser} = this.props;
    const {isloading, issending, error, user, success, modals} = this.state
    const content = contentData[language]


    let modalcontent = null

    if(modals.imagemodal.open){
      modalcontent = (
        <ProfilePictureModal
          data={modals.imagemodal}
          language={language}
          onClose={() => this.onCloseModal('imagemodal')}
          onSubmit={this.onSubmitPicture}
          onDelete={this.onDeletePicture}
        />
      )
    }
    
    return(
      <BasicView
        isloading={isloading}
        history={history}
        selected={0}
        onChange={this.onChangeView}
        content={content.layout}
        returnBtn={true}
        onReturn={this.onReturn}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar}
      >
        {modalcontent}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <EditProfileInfo
              language={language}
              catalogs={catalogs}
              user={user}
              issending={issending}
              error={error}
              translations={translations}
              onSubmit={this.onSubmitUser}
              onChangeImage={()=> this.onOpenFileModal('imagemodal')}
            />
          </Grid>
        </Grid>
      </BasicView>
      
    )
  }

  onSubmitPicture = async(type, file) => {
    try {
      this.onEditModal('imagemodal',{issending:true})
      const aux = file[0].name.split('.')
      const ext = {type:aux[aux.length-1]}
      let response = await request_uploadFile(file[0], ext);
      this.setState({user:{...response}})
      this.props.onChangeParentUser(response)
      this.onCloseModal('imagemodal')
      this.props.history.push('/profile')
    } catch (error) {
      this.onEditModal('imagemodal',{issending:false})
      if(error.response){
        this.onEditForm('imagemodal',{issending:false, error:error.response.data.error.message})
      }
    }
  }

  onDeletePicture = async() => {
    try {
      this.onEditModal('imagemodal',{issending:true})
      let response = await request_deleteFile();
      this.setState({user:{...response}})
      this.props.onChangeParentUser(response)
      this.onCloseModal('imagemodal')
      this.props.history.push('/profile')
    } catch (error) {
      if(error.response){
        this.onEditForm('imagemodal',{issending:false, error:error.response.data.error.message})
      }
    }
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section]={...tempModals[section],...data}
    this.setState({modals:{...tempModals}})
  }

  onOpenFileModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }
}

export default withStyles(styles)(EditUserProfile)