import React from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import {blueGrey, grey} from '@material-ui/core/colors';


const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    padding:'0px 10px',

  },
  noOptionsMessage: {
    fontSize: 14,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 14,
  },
  placeholder: {
    position: 'absolute',
    fontSize: 14,
    paddingLeft:'2px',
    color:grey[400]
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    fontSize: 14,
    maxHeight:'200px'
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
});

function NoOptionsMessage(props) {
  return (
    <Typography
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
        fontSize:14
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      noWrap
      variant='caption'
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography noWrap className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

const components = {
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

class InputSimpleSelect extends React.Component{
  state = {
    single: null,
    multi: null,
  }


  handleChange = (item) => {
    const {data, onChange} = this.props;
    let temp = {...data};
    temp.value = item.value;
    temp.isValid = true;
    temp.isVisited = true;
    onChange(temp);
  };

  render() {
    const { classes, theme, data, wrap } = this.props;

    const selectStyles = {
      control: styles => ({
        ...styles,
        backgroundColor: "white",
        fontSize:'1em',
        fontFamily:'Montserrat',
        padding:'5px 2px',
        borderRadius:'40px',
        borderColor: blueGrey[300],
        '&:focus': {
          borderWidth:'1px',
          borderColor: blueGrey[700],
        },
        '&:hover': {
          borderWidth:'1px',
          borderColor: blueGrey[500],
        },
      }),
      menuList: styles => ({
        ...styles,
        maxHeight:'200px',
      })
    };

    let options;
    let value;
    let isClearable;
    let id;
    let label = '';
    let placeholder = '';

    if(data){
      options = data.options;
      id = data.config.id;
      isClearable = data.isClearable;
      options.forEach((item, key) => {
        if(item.value === data.value){
          value = key;
        }
      })
      if(data.config.label){
        label = data.config.label
      }
      if(data.config.placeholder){
        placeholder = data.config.placeholder
      }
    }

    let gridLabel = 4;
    let gridContent = 8;
    let textPosition = 'right';
    let spacingItems = 16;

    if(wrap){
      if(wrap === 'vertical'){
        gridLabel = 12;
        gridContent = 12;
        textPosition = 'left';
        spacingItems = 0;
      }
    }

    return (
      <Grid container spacing={spacingItems} alignItems='center'>
        <Grid item xs={12} md={gridLabel}>
          <Typography variant='subtitle2' align={textPosition} style={{fontSize:'0.9em', fontWeight:'600', color: grey[700], padding:'8px 8px'}}>
            {label}
          </Typography>
        </Grid>
        <Grid item xs={12} md={gridContent}>
          <div style={{marginTop:'0px', marginBottom:'4px'}}>
            <Select
              classes={classes}
              styles={selectStyles}
              options={options}
              components={components}
              value={value !== undefined ? options[value] : null}
              onChange={this.handleChange}
              placeholder={placeholder}
              isClearable={isClearable}
            />
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(InputSimpleSelect);
