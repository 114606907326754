import {mainServer} from '../../../../variables/config'
import axios from 'axios'

export const request_user = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  console.log(logData)
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/me");
  return response.data.data.user
}

export const request_edit_user = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.patch("/user/me", data)
  let response
  response = await mainServer.instance.get("/user/me");
  return response.data.data.user
}

export const request_uploadFile = async(file,ext) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  const url = '/user/me/image'
  let response = await mainServer.instance.post(url, ext)
  console.log(response.data)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.upload_url, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  response = await mainServer.instance.get("/user/me");
  return response.data.data.user
}


export const request_deleteFile = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  const url = '/user/me/image'
  await mainServer.instance.delete(url)
  let response;
  response = await mainServer.instance.get("/user/me");
  return response.data.data.user
}
