import { makeStyles } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import React, { useState, useEffect } from 'react';
import backgroundImage from '../../../../assets/solerpalau/chumacerasZone.jpg'
import MachineButton from './MachineButton';


const useStyles = makeStyles(theme => ({
    root:{
        position:'relative',
        width:'100%',
        paddingTop:'56.25%',
        background:'white',
        borderRadius:12,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    image:{
        position:'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%',
        borderRadius:12,

    },
    title:{
        background:'white',
        zIndex:2,
        position:'absolute',
        top:32, left:32,
        padding:16,
        borderRadius:12,
        fontFamily:theme.typography.fontFamily,
        textTransform:'uppercase',
        fontWeight:500,
        color:blueGrey[900],
        fontSize:24,
        //border:`1px solid ${theme.palette.primary.dark}`
    },
    buttonContainer:{
        position:'absolute',
        zIndex:5,
        top:0,
        left:0
    }
}))

/*const devices = [
    {id:1, image:}
]*/
let devices = [
    {id:1, label:'Motor Bearing SP1', position:{top:'50%', left:'10%'}, data:null, status:1},
    {id:2, label:'Motor Bearing SP2', position:{top:'40%', left:'27%'}, data:null, status:1},
    {id:3, label:'Motor Bearing SP3', position:{top:'20%', left:'65%'}, data:null, status:1},
    {id:4, label:'Motor Bearing SP4', position:{top:'50%', left:'50%'}, data:null, status:1},
    {id:5, label:'Motor Bearing SP5', position:{top:'60%', left:'70%'}, data:null, status:1},
]

devices[3].status = 0


const ZoneCard = ({onSelectedDevice}) => {

    const classes = useStyles()

    return(
        <div  className={classes.root}>
            {/*<div className={classes.title}>Zona de chumaceras</div>*/}
            <img src={backgroundImage} alt=''  className={classes.image}/>
            {devices.map(item => {
                return(
                    <div className={classes.buttonContainer} style={item.position} onClick={onSelectedDevice}><MachineButton data={item.data} label={item.label} status={item.status}/></div>
                )
            })}
        </div>
    )
}

export default ZoneCard