import React from 'react'
import CardWrapper from '../../../../../components/Cards/CardWrapper/CardWrapper';
import { makeStyles } from '@material-ui/styles';
import ContentText from '../../../../../components/Texts/ContentText';
import { palette } from '../../../../../variables/config';
import { Grid } from '@material-ui/core';
import { DataItemWrapper } from '../../../../../components/DisplayData/DisplayData';
import VerticalDataWrapper from '../../../../../components/Texts/VerticalDataWrapper';
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';


const useStyles = makeStyles(theme => ({
  root:{
    padding:16,
    width:300
  }
}))


const CameraDetailsCard = props => {

  const classes = useStyles()
  const {camera, onEdit} = props
  
  let camera_form = updateForm(content.form, camera)

  return(
    <CardWrapper>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ContentText text={content.title} medium color={palette.primary.main}/>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {Object.keys(camera_form).map((item,key)=>{
                return(
                  <Grid item xs={12} key={key.toString()}>
                    <VerticalDataWrapper 
                      label={camera_form[item].label} 
                      text={camera_form[item].value}/>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify='flex-end'>
              <Grid item>
                <RoundedButton label='Edit' size='small' onClick={onEdit}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </CardWrapper>
  )
}

export default CameraDetailsCard

const updateForm = (form, data) => {
  let temp = {...form}
  if(data){
    Object.keys(form).map(item => {
      temp[item].value = data[item] ? data[item] : '-' 
    })
  }

  return temp
  
}

const content = {
  title:"Camera's information",
  form:{
    name:{
      label:'Name',
    },
    user:{
      label:'Username',
    },
    password:{
      label:'Password',
    },
    brand:{
      label:'Brand',
    },
    brand:{
      label:'Brand',
    },
    model:{
      label:'Model',
    },
    ip:{
      label:'Dirección IP',
    },
    
  }
}