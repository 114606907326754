import React, {Component} from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core';
import ContentText from '../../../../../components/Texts/ContentText';
import { grey } from '@material-ui/core/colors';
import ReactApexChart from 'react-apexcharts';

class EnergyGauge extends Component {

  state = {
    options: {
      chart: {
        offsetY: -10
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: '16px',
              fontWeight:'700',
              offsetY: -10
            },
            value: {
              offsetY: 10,
              fontSize: '22px',
              fontWeight:'600',
              color: grey[700],
              formatter: function (val) {
                return (val*0.4).toFixed(0) + " A";
              }
            }
          }
        }
      },
      fill: {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 65, 91]
        },
      },
      stroke: {
        dashArray: 4
      },
      labels: ['Current']
    },
    series: [60],
  }


  render(){

    const {classes} = this.props

    return(
          <div className={classes.root}>
            <ReactApexChart options={this.state.options} series={this.state.series} type="radialBar" height="300" />
          </div>
    )
  }
}

const styles = (theme) => ({
  root:{
  },
  text:{
    color:grey[700],
    fontWeight:'600'
  }
})

export default withStyles(styles)(EnergyGauge)