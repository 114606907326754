import React from 'react'
import CardWrapper from '../../Cards/CardWrapper/CardWrapper';
import Subtitle from '../../Texts/Subtitle';
import { Grid, IconButton, Icon } from '@material-ui/core';
import { palette } from '../../../variables/config';

export const IoTCard = (props) => {

  const {title, isedit, onEdit} = props

  let align = 'left'
  let title_text = ''
  let color = palette.primary.main

  if(title){
    align = title.align ? title.align : align
    title_text = title.text ? title.text : title_text 
    color = title.color ? title.color : color
  }

  return(
    <CardWrapper>
      <div style={{position:'relative'}}>
        {isedit ? (
          <div style={{
            position:'absolute',
            right:0,
            padding:12,

          }}>
            <IconButton size='small' onClick={onEdit}>
              <Icon fontSize='small'>edit</Icon>
            </IconButton>
          </div>
        ) : null}
        
        <div style={{padding:16}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Subtitle text={title_text} align={align} color={color} medium/>
            </Grid>
            <Grid item xs={12}>
              {props.children}
            </Grid>
          </Grid>
        </div>
      </div>
      
    </CardWrapper>
  )
}