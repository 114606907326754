import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import { Grid, IconButton, Icon, Button, Switch, Slider } from '@material-ui/core';
import { RoundedButton } from '../../../../../../../components/Buttons/GeneralButtons';
import Caption from '../../../../../../../components/Texts/Caption';
import moment from 'moment'
import { green, red } from '@material-ui/core/colors';

class DevicePopper extends Component {
  render(){

    const {classes, device, onDelete, onViewDevice} = this.props


    let contentView = null

    if(device){
      console.log(device)

      const date = moment(device.data.last_seen.value).format('DD-MM-YYYY')
      const hour = moment(device.data.last_seen.value).format('HH:mm:ss')
      let dataText = ''

      switch (device.model.name) {
        case 'Temperature/Humidity':
          dataText = `${device.data.temperature.value} ${device.data.temperature.unit}`
          contentView = (
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Caption text='Temperature' medium variant='light'/>
                  </Grid>
                  <Grid item xs={12}>
                    <Caption text={dataText} medium/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Caption text='Last Report' medium variant='light'/>
                  </Grid>
                  <Grid item xs={12}>
                    <Caption text={`${date} ${hour}`} medium/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
          break;
        case 'Open/Close':
          dataText = `${device.data.opening.value ? 'Opened' : 'Closed'}`

          contentView = (
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Caption text='Status' medium variant='light'/>
                  </Grid>
                  <Grid item xs={12}>
                    <Caption text={dataText} medium/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Caption text='Last Report' medium variant='light'/>
                  </Grid>
                  <Grid item xs={12}>
                    <Caption text={`${date} ${hour}`} medium/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
          break;
        case 'Presence/Motion':
            dataText = `${device.data.presence.value ? 'Motion' : 'No motion'}`

            contentView = (
              <Grid container>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Caption text='Status' medium variant='light'/>
                    </Grid>
                    <Grid item xs={12}>
                      <Caption text={dataText} medium/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Caption text='Last Report' medium variant='light'/>
                    </Grid>
                    <Grid item xs={12}>
                      <Caption text={`${date} ${hour}`} medium/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
          break;
        case 'Lights Switch':
          dataText = `${device.data.value.value ? 'ON' : 'OFF'}`

          contentView = (
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Caption text='Status' medium variant='light'/>
                  </Grid>
                  <Grid item xs={12}>
                    <LightsSwitch />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Caption text='Last Report' medium variant='light'/>
                  </Grid>
                  <Grid item xs={12}>
                    <Caption text={`${date} ${hour}`} medium/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
          break
        case 'Lights Dimming':
          dataText = `${device.data.value.value ? 'ON' : 'OFF'}`

          contentView = (
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Caption text='Level' medium variant='light'/>
                  </Grid>
                  <Grid item xs={12}>
                    <LightsDimmer value={device.data.value.value*100}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Caption text='Last Report' medium variant='light'/>
                  </Grid>
                  <Grid item xs={12}>
                    <Caption text={`${date} ${hour}`} medium/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
          break
        case 'Digital':
          
          const input_array = [
            device.data.digital_input_1 ? device.data.digital_input_1.value : 0,
            device.data.digital_input_2 ? device.data.digital_input_2.value : 0,
            device.data.digital_input_3 ? device.data.digital_input_3.value : 0,
            device.data.digital_input_4 ? device.data.digital_input_4.value : 0, 
          ]
    
          const output_array = [
            device.data.digital_output_1 ? device.data.digital_output_1.value : 0,
            device.data.digital_output_2 ? device.data.digital_output_2.value : 0,
            device.data.digital_output_3 ? device.data.digital_output_3.value : 0,
            device.data.digital_output_4 ? device.data.digital_output_4.value : 0, 
          ]

          contentView = (
            <Grid container>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <DigitalSignalsWrapper title='Inputs' data={input_array}/>
                  </Grid>
                  <Grid item xs={12}>
                    <DigitalSignalsWrapper title='Outputs' data={output_array}/>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Caption text='Last Report' medium variant='light'/>
                  </Grid>
                  <Grid item xs={12}>
                    <Caption text={`${date} ${hour}`} medium/>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
          break;
      
        default:
          break;
      }
    }

    return(
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Caption text='Device Info' medium />
              </Grid>
              <Grid item xs={12}>
                {contentView}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <IconButton size='small' onClick={() => onDelete(device)}>
                  <Icon>delete</Icon>
                </IconButton>
              </Grid>
              <Grid item xs/>
              <Grid item>
                <Button className={classes.button} onClick={() => onViewDevice(device)}>
                  Details
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = theme => ({
  root:{
    width:200,
    padding:8
  },
  button:{
    padding:0,
    paddingTop:2,
    paddingBottom:2,
    fontSize:12,
    color:'white',
    borderRadius:32,
    textTransform:'none',
    background:theme.palette.secondary.main,
    '&:hover':{
      background:theme.palette.secondary.dark,
    }
  }
})

export default withStyles(styles)(DevicePopper)

const DigitalSignalsWrapper = (props) => {

  const {title, data} = props

  return(
    <Grid container>
      <Grid item xs={12}>
        <Caption text={title ? title : ''} medium variant='light'/>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1} wrap='nowrap'>
          {data.map((item,key)=>{
            return(
              <Grid item key={key.toString()}>
                <Icon style={{color:item ? green[700] : red[700], fontSize:12}}>brightness_1</Icon>
              </Grid>
            )
          })}
        </Grid>
      </Grid>

    </Grid>
  )
}


class LightsSwitch extends Component {

  state = {
    status:true
  }

  render(){

    const {status} = this.state

    return(
      <Grid container wrap='nowrap'>
        <Grid item>
          <Switch checked={status} size='small' onChange={this.onChange}/>
        </Grid>
        <Grid item>
          <Caption medium text={status ? 'ON' : 'OFF'}/>
        </Grid>
      </Grid>
    )
  }

  onChange = (event) => {
    this.setState({status:event.target.checked})
  }
}

class LightsDimmer extends Component {

  state = {
    status:true, 
    level:null
  }

  render(){

    const {status, level} = this.state
    const {value} = this.props

    return(
      <div style={{
        margin:'0px 8px',
        width:150
      }}>
        <PrettoSlider 
          onChange={this.onChangeSlider} 
          value={level}
          valueLabelDisplay="auto" 
          aria-label="pretto slider" 
          defaultValue={value ? value : 30}
        />
      </div>
    )
  }

  onChangeSlider = (event, value) => {
    //console.log(value)
    this.setState({level:value})
  }

  onChange = (event) => {
    this.setState({status:event.target.checked})
  }
}

const pretto_styles = (theme) => ({
  root: {
    color: theme.palette.secondary.main,
    height: 8,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -4,
    marginLeft: -8,
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})

const PrettoSlider = withStyles(pretto_styles)(Slider);