import { ntcPoints } from "../variables/sensors/ntcData";
import moment from 'moment-timezone'

const vi = 3; const r1 = 10000; const adcCounts = 4095; const ri=112.5

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const onGetTemperaturePoint = (value) => {
    if(value === null || value === undefined) return null
    if(value > ntcPoints[0].resistance) return null
    if(value < ntcPoints[ntcPoints.length-1].resistance) return null

    console.log('Calcular')
    // Find ranges
    let sidx = 0
    let isSet = false   
    ntcPoints.forEach((item, key) => {
        if(value > item.resistance && !isSet){
            sidx = key
            isSet = true
        } 
    })

    const pi = ntcPoints[sidx-1]
    const pf = ntcPoints[sidx]

    const m = (pf.temperature - pi.temperature)/(pf.resistance - pi.resistance)
    const b = pf.temperature - m*pf.resistance
    const y = m*value + b
    return y
}

export const onGetResistancePoint = (value) => {
    if(value === null || value === undefined) return null
    const vx = (value / adcCounts)*vi
    const r2 = (vx*r1)/(vi -vx)
    return r2
}

export const onGetCurrentPoint = (value) => {
    if(value === null || value === undefined) return null
    const vx = (value/adcCounts)*vi
    //console.log(`${vx*1000}mV`)
    const current = vx/ri
    return 1.5625*current*1000 - 6.25
}

export const onGetRecords = (records) => {
    let result = {
        temperature:[],
        rangeT:{min:Infinity, max:-Infinity},
        vibration:[],
        rangeV:{min:Infinity, max:-Infinity},
    }

    if(!records) return result

    records.forEach(item => {
        const currentTime = moment(item.timestamp.value)
        const rTemp = onGetResistancePoint(item.universal_input_3.value/100)
        const temp = onGetTemperaturePoint(rTemp)
        const currentPoint = onGetCurrentPoint(item.universal_input_1.value*100)
        if(temp < result.rangeT.min) result.rangeT.min = temp
        if(temp > result.rangeT.max) result.rangeT.max = temp

        result.temperature.push({
            time:currentTime.format('DD MMM, HH:mm'),
            temperature:parseFloat(temp).toFixed(2),
        })
        result.vibration.push({
            time:currentTime.format('DD MMM, HH:mm'),
            vibration:parseFloat(currentPoint).toFixed(2),
        })

    })

    //console.log(result)
    return result

}

export const isFormValid = (form) => {
    let errors = []
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isRequired && !form[item].isValid) errors.push(item)
        })
    }
    return errors
} 

export const onSetErrorsToForm = (form, errors) => {
    let temp = {...form}
    if(errors && errors.length) errors.forEach(item => temp[item].isVisited = true)
    return temp
}

export const onGetFormData = (form) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isValid) temp = {...temp, [item]:form[item].value}
        })
    }
    return temp
}

export const onGetSelectedFormData = (form, origin) => {
    let temp = {}
    if(form){
        Object.keys(form).forEach(item => {
            if(form[item].isValid && form[item].value !== origin[item]) temp = {...temp, [item]:form[item].value}
        })
    }
    return temp
}

export const onInitForm = (form, origin) => {
    let response = {...form}
    if(!origin) return response
    Object.keys(form).forEach(item => {
        const value = origin[item]
        if(value) response[item] = {...response[item], value:value, isVisited:true, isValid:true}
    })
    return response
}

export const s2ab = (s) =>{ 
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;    
  }


  