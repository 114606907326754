import React, {useState, useEffect} from 'react';
import { Grid, LinearProgress, makeStyles } from '@material-ui/core';
import SimpleCard from '../../../../components_v1/Structure/Cards/SimpleCard';
import moment from 'moment-timezone'
import { request_records } from '../requests';
import { onGetRecords } from '../../../../shared/utility';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer
  } from 'recharts';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import { blue, blueGrey, purple, teal } from '@material-ui/core/colors';
import LoadingContainer from '../../../../components_v1/Structure/Layouts/LoadingContainer';
const useStyle = makeStyles(theme => ({
    root:{

    }
}))

const ChartsContent = ({deviceID}) => {

    const [temperatures, setTemperatures] = useState([])
    const [vibrations, setVibrations] = useState([])
    const [loading, setLoading] = useState(false)
    const [count, setCount] = useState(0)
    const [sending, setSending] = useState(false)

    useEffect(() => {
        const initModule = async() => {try {
            setSending(true)
            const end_date = moment().add(1,'h').utc()
            const start_date = moment().subtract(8,'h').utc()
            //console.log(`Start:${start_date.format()} End:${end_date.format()}`)
            const params = {start:start_date.format(),end:end_date.format()}
            const _records = await request_records(deviceID, params)
            //console.log(_response)
            const result = onGetRecords(_records)
            console.log(result)
            setTemperatures(result.temperature)
            setVibrations(result.vibration)
        } catch (error) {
                console.log(error)
        }
            setSending(false)   
        }
        initModule()
    }, [count])

    useEffect(() => {
        const interval = setInterval(() => {
            let _count = count + 1
            setCount(_count)
          }, 15000);
          return () => clearInterval(interval);
    }, [])


    //console.log(temperatures)

    return ( 
        <div>
            <LoadingContainer loading={loading}>
                <Grid container spacing={3}>
                    {sending ? 
                        <Grid item xs={12}><LinearProgress /></Grid> : null}
                    <Grid item xs={12}>
                        <SimpleCard padding='small'>
                            <Grid container>
                                <Grid item xs={12}>
                                    <DisplayText variant='body1' align='center' color='primary' style={{paddingTop:16, fontWeight:400, color:blueGrey[900],paddingBottom:32, width:'100%', fontWeight:500}}>
                                        Temperature Monitoring
                                    </DisplayText>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{width:'100%', height:300}}>
                                        <ResponsiveContainer>
                                            <AreaChart data={temperatures}
                                                margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                            
                                            <XAxis label={{value:'Time(s)', fontWeight:500}}  dataKey="time" tick={{fontSize:0}} interval={50} angle={0}/>
                                            <YAxis label={{ value: 'Temperature (°C)', angle: -90, position: 'insideLeft', fontWeight:500}} domain={[5, 30]} tick={{fontSize:14, fontWeight:500}}/>
                                            <Tooltip/>
                                            <Area type='monotone' dataKey='temperature' stroke={teal[700]} fill={teal[700]}  />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Grid>
                            </Grid>
                        </SimpleCard>
 
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleCard padding='small'>
                            <Grid container>
                                <Grid item xs={12}>
                                    <DisplayText variant='body1' align='center' color='primary' style={{paddingTop:16, fontWeight:400, color:blueGrey[900],paddingBottom:32, width:'100%', fontWeight:500}}>
                                        Vibration monitoring (X axis)
                                    </DisplayText>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{width:'100%', height:300}}>
                                        <ResponsiveContainer>
                                            <AreaChart data={vibrations}
                                                margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                            <XAxis label={{value:'Time(s)', fontWeight:500}} dataKey="time" tick={{fontSize:0}} interval={50} angle={0}/>
                                            <YAxis label={{ value: 'Vibration (mm/s)', angle: -90, position: 'insideLeft', fontWeight:500}} domain={[0, 35]} tick={{fontSize:14, fontWeight:500}}/>
                                            <Tooltip/>
                                            <Area type='monotone' dataKey='vibration' stroke={purple[700]} fill={purple[700]} />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Grid>
                            </Grid>
                        </SimpleCard>
                    </Grid>
                    <Grid item xs={12}>
                        <SimpleCard padding='small'>
                            <Grid container>
                                <Grid item xs={12}>
                                    <DisplayText variant='body1' align='center' color='primary' style={{paddingTop:16, fontWeight:400, color:blueGrey[900],paddingBottom:32, width:'100%', fontWeight:500}}>
                                        Vibration monitoring (Y axis)
                                    </DisplayText>
                                </Grid>
                                <Grid item xs={12}>
                                    <div style={{width:'100%', height:300}}>
                                        <ResponsiveContainer>
                                            <AreaChart data={vibrations}
                                                margin={{top: 0, right: 0, left: 0, bottom: 0}}>
                                            <XAxis label={{value:'Time(s)', fontWeight:500}} dataKey="time" tick={{fontSize:0}} interval={50} angle={0}/>
                                            <YAxis label={{ value: 'Vibration (mm/s)', angle: -90, position: 'insideLeft', fontWeight:500}} domain={[0, 35]} tick={{fontSize:14, fontWeight:500}}/>
                                            <Tooltip/>
                                            <Area type='monotone' dataKey='vibration' stroke={purple[700]} fill={purple[700]} />
                                            </AreaChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Grid>
                            </Grid>
                        </SimpleCard>
                    </Grid>
                </Grid>
            </LoadingContainer>
            
            
        </div>
     );
}
 
export default ChartsContent;